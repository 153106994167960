import { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'

import useTrayConfiguration from 'views/TrayManagementLayout/TrayManagement/TrayManagement.logic'
import useEditTrayModal from '../EditTrayModal/EditTrayModal.logic'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

import { ItemResponse } from 'common/types'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { CheckCircle } from '@mui/icons-material'
import useAddTrayDetailsLogic from '../../../views/TrayManagementLayout/AddTray/AddTrayDetails/AddTrayDetails.logic'

export const useChooseTrayLocationModal = ({
  onClose,
  setTrays,
}: {
  onClose: () => void
  setTrays?: React.Dispatch<React.SetStateAction<ItemResponse[]>>
}) => {
  const { selectedLocationId, handleSelectedLocationChange } =
    useAddTrayDetailsLogic()
  const { locationsWithParents, findLocation } = useTrayConfiguration()
  const {
    trayData,
    setTrayData,
    trayScrews,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarState,
    setSnackbarIcon,
  } = useSPD()
  const { setProductData } = useSPDScan()
  const { handleUpdateTrayItem, updating, success, setSuccess } =
    useEditTrayModal({
      trayData: trayData?.trayItem as ItemResponse,
      trayScrews,
    })

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const handleSPDDataLocationChange = (e: SelectChangeEvent<number>) => {
    setTrayData((prevTrayData) => {
      if (prevTrayData) {
        const updatedTrayItem = {
          ...prevTrayData?.trayItem,
          locationId: Number(e.target.value),
        }
        return { ...prevTrayData, trayItem: updatedTrayItem }
      }
      return prevTrayData
    })

    handleSelectedLocationChange(e)
  }

  const handleConfirm = async () => {
    if (isSaving) return

    try {
      setIsSaving(true)

      setTrays &&
        setTrays((prevTrays) =>
          prevTrays.map((tray) => {
            if (tray.id === trayData?.trayItem.id) {
              return {
                ...tray,
                locationId: selectedLocationId,
              }
            }

            return tray
          })
        )

      await handleUpdateTrayItem()
    } catch (error) {
      console.error('Failed to save new inventory location for tray.', error)
    }
  }

  useEffect(() => {
    if (success) {
      setSuccess(false)
      setSnackbarMessage('Successfully updated tray storage location')
      setSnackbarState('success')
      setSnackbarIcon(<CheckCircle />)
      setSnackbarOpen(true)
      setTrayData(null)
      setProductData([])
      setIsSaving(false)
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return {
    selectedLocationId,
    handleSelectedLocationChange,
    locationsWithParents,
    findLocation,
    trayData,
    setTrayData,
    trayScrews,
    handleUpdateTrayItem,
    updating,
    success,
    handleSPDDataLocationChange,
    handleConfirm,
    isSaving,
  }
}
