import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'

import './LoadingImage.scss'

interface LoadingImageProps {
  src: string
  alt: string
  width: string | number | { xs: string; md: string; lg: string }
  height: string | number | { xs: string; md: string; lg: string }
  borderRadius?: string | number
}

const LoadingImage = ({
  src,
  alt,
  width,
  height,
  borderRadius = '5px',
}: LoadingImageProps) => {
  const [isImgLoading, setIsImgLoading] = useState(false)

  return (
    <Box
      className="loading-img_container"
      sx={{
        width,
        height,
        borderRadius,
      }}
      onLoad={() => setIsImgLoading(false)}
    >
      <img className="img" src={src} alt={alt} />

      {isImgLoading && (
        <div className="spinner-container">
          <CircularProgress className="icon" />
        </div>
      )}
    </Box>
  )
}

export default LoadingImage
