import Header from 'components/molecules/Header/Header'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { useScrubTechniciansLogic } from './ScrubTechnicians.logic'
import { Box, Button, TableRowProps, TextField } from '@mui/material'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { LoadingButton } from 'components/mui'
import { Delete, ReadMore } from '@mui/icons-material'
import { useIsMobile } from 'lib/utils/mediaQueries'
import './ScrubTechnicians.scss'
import { FC } from 'react'

const ScrubTechnicians: FC = () => {
  const {
    scrubTechnicians,
    searchLabel,
    isAlertOpen,
    searchString,
    filteredScrubTechnicians,
    isLoadingScrubTecnicians,
    errorGettingScrubTechniciansData,
    scrubTechniciansHeadCells,
    isDeletingScrubTechnician,
    handleFocus,
    handleSearch,
    openAlertDialog,
    closeAlertDialog,
    navigateToScrubTechnician,
    handleCreateScrubTechnician,
    handleDeleteScrubTechnician,
  } = useScrubTechniciansLogic()

  const scrubTechniciansRow = filteredScrubTechnicians
    ?.map((scrubTechnician) => {
      if (!scrubTechnician._id) return null

      const formattedLastName = scrubTechnician.lastName
        ? scrubTechnician.lastName
            .split(' ')
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ')
        : '-'

      const formattedFirstName = scrubTechnician.firstName
        ? scrubTechnician.firstName
            .split(' ')
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ')
        : '-'

      const row: {
        lastName: string
        firstName: string
        speciality: String
        email: string
        actions?: JSX.Element
      } = {
        lastName: formattedLastName,
        firstName: formattedFirstName,
        speciality: scrubTechnician.speciality,
        email: scrubTechnician.email,
      }
      row.actions = (
        <div className="actions-referring-physicians">
          <Button
            onMouseUp={navigateToScrubTechnician({
              edit: true,
              _id: scrubTechnician._id,
              lastName: scrubTechnician.lastName,
              firstName: scrubTechnician.firstName,
              email: scrubTechnician.email,
              confirmEmail: scrubTechnician.email,
              speciality: scrubTechnician.speciality,
            })}
          >
            <ReadMore />
          </Button>
          <Button onMouseUp={openAlertDialog(scrubTechnician._id)}>
            <Delete color="error" />
          </Button>
        </div>
      )

      return row
    })
    .filter(Boolean) as TableRowProps[]

  scrubTechniciansHeadCells.push({
    id: 'actions',
    label: 'Actions',
    numeric: false,
    sx: {
      textAlign: 'right',
    },
  })

  return (
    <div className="referring-physicians-main-container">
      <Header
        isMobile={useIsMobile()}
        parent="Reports"
        title="Scrub Technicians"
      />

      <div className="content">
        {scrubTechnicians.length > 0 || isLoadingScrubTecnicians ? (
          <>
            <div className="input-container">
              <div className="search-add">
                <TextField
                  className="input"
                  label={searchLabel}
                  value={searchString}
                  onChange={handleSearch}
                  onFocus={handleFocus(true)}
                  onBlur={handleFocus(false)}
                />
                <LoadingButton
                  className="add-physician-button"
                  variant="outlined"
                  size="large"
                  onClick={handleCreateScrubTechnician}
                >
                  Add Scrub Technician
                </LoadingButton>
              </div>
            </div>

            <Box sx={{ px: 2 }}>
              <DataTable
                tableRows={scrubTechniciansRow}
                isLoading={isLoadingScrubTecnicians}
                isErrored={!!errorGettingScrubTechniciansData}
                tableHeaders={scrubTechniciansHeadCells}
                errorMessage="Error getting scrub technicians..."
              />
            </Box>
          </>
        ) : (
          <div className="no-data">
            <h3>No scrub technicians found</h3>
            <div>
              <LoadingButton
                variant="contained"
                size="large"
                onClick={handleCreateScrubTechnician}
              >
                Add Scrub Technician
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
      <AlertDialog
        zIndex={1000}
        mode="warning"
        title="Delete Confirmation"
        primaryButtonText="Delete"
        isOpen={isAlertOpen.isOpen}
        description={isAlertOpen.description}
        secondaryButtonAction={closeAlertDialog}
        isPrimaryLoading={isDeletingScrubTechnician}
        primaryButtonAction={handleDeleteScrubTechnician}
      />
    </div>
  )
}

export default ScrubTechnicians
