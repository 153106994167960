import { UndocumentedScan } from 'common/types'
import { UndocumentedScanCard } from 'components/UndocumentedScanCard'
import { useBatchDispositionModalLogic } from 'components/BatchDispositionModal/BatchDispositionModal.logic'
import { AssociatedDispositionModalProps } from './AssociatedProductsModal.types'
import { CustomModal } from 'components/molecules/Modal/Modal'

export const AssociatedDispositionModal = ({
  associatedModalOpen,
  setAssociatedModal,
  associatedProducts,
  surgeryId,
  toggleSelection,
  scanStateMap,
  onSave,
  isSubmitting,
  setScanStateMap,
  handleAssociatedSubmitInitial,
}: AssociatedDispositionModalProps) => {
  // --------------------  Utils --------------------
  const { handleAssociatedSubmit } = useBatchDispositionModalLogic({
    assetType: 'non-biological',
    onSave,
    isAssociatedProduct: true,
    setAssociatedModal,
    handleAssociatedSubmitInitial,
  })

  // --------------------  Handles --------------------
  const handleModalOnClose = () => {
    setAssociatedModal(false)
    associatedProducts.forEach((scan: UndocumentedScan) => {
      setScanStateMap((prevState) => ({
        ...prevState,
        [scan._id]: {
          isSelected: false,
          isAssociatedProduct: false,
        },
      }))
    })
  }

  const filteredAssociatedProducts = associatedProducts.filter((scan) => {
    return scanStateMap[scan._id]?.isAssociatedProduct
  })

  return (
    <CustomModal
      open={associatedModalOpen}
      dataTestId="associated-disposition"
      secondaryButtonAction={handleModalOnClose}
      header={'Associated Products Identified'}
      isLoadingState={isSubmitting}
      primaryButtonDisabled={false}
      handleOnSubmit={handleAssociatedSubmit}
      subheader={
        "One or more of the captured items may be an Associated Product. Which of the following would you like to document as 'utilized' at this time?"
      }
      primaryButtonText={'Utilize Products'}
    >
      {filteredAssociatedProducts?.map((scan: UndocumentedScan) => {
        const isSelected = scanStateMap[scan._id]?.isSelected
        const isExpireAccepted = scanStateMap[scan._id]?.isExpireAccepted

        return (
          <UndocumentedScanCard
            key={scan._id}
            dataTestId={scan._id + 'associated'}
            surgeryId={surgeryId}
            onClick={() => toggleSelection(scan._id)}
            scan={scan}
            isExpireAccepted={isExpireAccepted}
            cardStyles={{
              my: 1,
              display: 'block',
              borderColor: isSelected ? 'success.main' : 'grey.300',
              bgcolor: isSelected ? 'success.lightest' : 'white',
            }}
            renderDelete={false}
            isManualAddition={scan.isManualAddition}
          />
        )
      })}
    </CustomModal>
  )
}
