import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { ReactNode } from 'react'
import { MaterialDesignContent } from 'notistack'
import styled from 'styled-components'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: 'white',
    color: 'green',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: 'white',
    color: 'red',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: 'white',
    color: 'blue',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: 'white',
    color: 'orange',
  },
}))

interface ToastProviderProps extends SnackbarProviderProps {
  children: ReactNode
}

const ToastProvider: React.FC<ToastProviderProps> = ({
  children,
  ...props
}) => (
  <SnackbarProvider
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    autoHideDuration={3000}
    maxSnack={3}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
    }}
    {...props}
  >
    {children}
  </SnackbarProvider>
)

export default ToastProvider
