import './AddTray.scss'
import { Alert, Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { useTrayStepContext } from './AddTray.logic'
import { useAddTrayContext } from './AddTray.context'

const AddTray = () => {
  const {
    snackbarOpen,
    snackbarIcon,
    snackbarMessage,
    snackbarState,
    handleSnackbarClose,
  } = useAddTrayContext()
  const {
    stepNumber,
    steps,
    currentStep,
    goToStep,
    highestStepReached,
    handleDiscardAndExit,
  } = useTrayStepContext()

  return (
    <>
      <div className="step-layout">
        <div className="step-layout_header">
          <IconButton
            size="large"
            color="primary"
            onClick={handleDiscardAndExit}
            className="discard-exit-button"
          >
            <CloseIcon />
          </IconButton>
          <Stepper activeStep={stepNumber} className="stepper">
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < stepNumber}
                onClick={() => goToStep(index)}
                disabled={index > highestStepReached}
              >
                <StepLabel
                  classes={{
                    root:
                      index > highestStepReached
                        ? 'step-root--disabled'
                        : 'step-root',
                    label:
                      index > highestStepReached
                        ? 'step-label--disabled'
                        : 'step-label',
                    labelContainer:
                      index > highestStepReached
                        ? 'step-label-container--disabled'
                        : 'step-label-container',
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="step-view">
          <div className="step-container">
            <currentStep.Component />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AddTray
