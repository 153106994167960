// Components
import ManualAddForm from '../ManualAddForm/ManualAddForm'

// Logic
import { useManualAddAssetFormLogic } from './ManualAddAssetForm.logic'

// Utils
import { GS1UDI } from 'lib/utils/udi'

// Types
import { ManualAddAssetFormProps } from './ManualAddAssetForm.types'
import { ManualInputFormData } from 'common/types'
import { ParsedUDI } from 'lib/services/api/gudid/types'

// Other
import dayjs from 'lib/dayjs'
import AlertDialog from 'components/AlertDialog/AlertDialog'

export const quickParseGS1ToFormData = (
  data: GS1UDI
): Partial<ManualInputFormData> => {
  const parseDate = (dateString: string): string | undefined => {
    try {
      return dayjs(dateString, 'YYMMDD').toISOString()
    } catch {
      return undefined
    }
  }

  const expirationDate = data['17'] ? parseDate(data['17']) : undefined
  const manufacturingDate = data['11'] ? parseDate(data['11']) : undefined

  return {
    deviceId: data['01'],
    lotBatch: data['10'],
    expirationDate,
    manufacturingDate,
    serialNumber: data['21'],
  }
}

export const parsedUDIToFormData = (
  data: ParsedUDI
): Partial<ManualInputFormData> => ({
  deviceId: data.di ?? undefined,
  lotBatch: data.lotNumber ?? undefined,
  expirationDate: data.expirationDate ?? null,
  manufacturingDate: data.manufacturingDate ?? undefined,
  serialNumber: data.serialNumber ?? undefined,
})

export function ManualAddAssetForm(props: ManualAddAssetFormProps) {
  const {
    formErrors,
    isExpiredAlert,
    assignedField,
    manualAssetInputFormErrors,
    assetFormData: manualAssetInputFormData,
    handleSubmit,
    resetScanner,
    handleClearForm,
    handleAssignCode,
    handleValidation,
    setIsExpiredAlert,
    handleChangeFormData,
    handleChangeFormErrorField,
    setManualAssetInputFormData,
    setIsSearchByUdi,
  } = useManualAddAssetFormLogic(props)

  const {
    assetCode,
    isScannedCode,
    isTrayScannerOpen,
    registerTrayScanHandler,
    setIsTrayScannerOpen,
  } = props

  return (
    <>
      <ManualAddForm
        formErrors={formErrors}
        setManualAssetInputFormData={setManualAssetInputFormData}
        isScannedCode={isScannedCode}
        assetCode={assetCode}
        assetFormData={manualAssetInputFormData}
        manualAssetInputFormErrors={manualAssetInputFormErrors}
        handleChangeFormData={handleChangeFormData}
        handleChangeFormErrorField={handleChangeFormErrorField}
        handleSubmit={handleSubmit}
        isDisabled={false}
        handleClearForm={handleClearForm}
        assignedField={assignedField}
        handleAssignCode={handleAssignCode}
        isTrayScannerOpen={isTrayScannerOpen}
        registerTrayScanHandler={registerTrayScanHandler}
        setIsTrayScannerOpen={setIsTrayScannerOpen}
        onSearchWithTypeChange={(e) => setIsSearchByUdi(e)}
      />
      <AlertDialog
        title="Product Expired"
        description="This product has expired and cannot be added to the procedure."
        isOpen={isExpiredAlert ?? false}
        secondaryButtonAction={resetScanner}
        secondaryButtonText="Cancel"
        primaryButtonAction={() => {
          setIsExpiredAlert(false)
          handleValidation()
        }}
        primaryButtonText="Add Anyways"
        dismissable={false}
      />
    </>
  )
}
