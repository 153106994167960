import { ImplantReportType, ImplantReportStatus } from 'common/types'
import { pdfToBase64Image } from 'common/utils'
import { useGetAssetMediaFiles } from 'lib/apollo/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsMobile } from 'lib/utils/useIsMobile'

export const useViewImplantReportModalLogic = (
  implantReportStatus: ImplantReportStatus[]
) => {
  const isMobile = useIsMobile()

  const [currentReportIndex, setCurrentReportIndex] = useState(0)
  const [currentReportState, setCurrentReportState] =
    useState<ImplantReportStatus | null>(null)

  const currentReport = useMemo(() => {
    return implantReportStatus && implantReportStatus.length > 0
      ? implantReportStatus[currentReportIndex]
      : {
          type: ImplantReportType.OTHER,
          referringPhysicianLastName:
            '' as ImplantReportStatus['referringPhysicianLastName'],
          referringPhysicianEmail:
            '' as ImplantReportStatus['referringPhysicianEmail'],
          createdAt: new Date() as ImplantReportStatus['createdAt'],
          implantReport:
            '<p>No reports available.</p>' as ImplantReportStatus['implantReport'],
          attachments: [] as ImplantReportStatus['attachments'],
          isSent: false as ImplantReportStatus['isSent'],
        }
  }, [implantReportStatus, currentReportIndex])

  const { data } = useGetAssetMediaFiles(
    currentReport.attachments?.map(({ bucketKey }) => bucketKey) ?? []
  )

  const arrayBufferToBase64 = (buffer: ArrayBuffer): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        const blob = new Blob([buffer], { type: 'application/pdf' })
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64 = (reader.result as string).split(',')[1]
          resolve(base64)
        }
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(blob)
      } catch (error) {
        reject('Error converting ArrayBuffer to base64: ' + error)
      }
    })
  }

  const fetchPdfThumbnail = useCallback(
    async (pdfUrl: string, f: number): Promise<string> => {
      const convertPdfUrlToBase64 = async (url: string): Promise<string> => {
        try {
          const response = await fetch(url)
          const arrayBuffer = await response.arrayBuffer()
          const base64 = await arrayBufferToBase64(arrayBuffer)
          return base64
        } catch (error) {
          console.error('Error fetching PDF from URL:', error)
          return ''
        }
      }

      try {
        const base64Pdf = await convertPdfUrlToBase64(pdfUrl)
        const base64Image = await pdfToBase64Image(base64Pdf)
        return base64Image
      } catch (error) {
        console.error('Error generating PDF thumbnail:', error)
        return ''
      }
    },
    []
  )

  const handleNextReport = () => {
    if (implantReportStatus && implantReportStatus.length > 0) {
      setCurrentReportState((prev) =>
        prev ? { ...prev, attachments: [] } : null
      )
      setCurrentReportIndex(
        (prevIndex) => (prevIndex + 1) % implantReportStatus.length
      )
    }
  }

  const handlePreviousReport = () => {
    if (implantReportStatus && implantReportStatus.length > 0) {
      setCurrentReportState((prev) =>
        prev ? { ...prev, attachments: [] } : null
      )
      setCurrentReportIndex((prevIndex) =>
        prevIndex === 0 ? implantReportStatus.length - 1 : prevIndex - 1
      )
    }
  }

  const getAttachmentLink = useCallback(
    (bucketKey: string) => {
      return (
        data?.getMediaFiles.find((file) => file.filename === bucketKey)
          ?.content || ''
      )
    },
    [data?.getMediaFiles]
  )

  useEffect(() => {
    const fetchThumbnails = async () => {
      if (currentReport && currentReport.attachments) {
        const updatedAttachments = await Promise.all(
          currentReport.attachments.map(async (attachment) => {
            if (attachment.filename.toLowerCase().endsWith('.pdf')) {
              const pdfUrl = getAttachmentLink(attachment.bucketKey)
              const pdfThumbnail = await fetchPdfThumbnail(pdfUrl, 1) // or any page number f
              return { ...attachment, pdfThumbnail }
            } else {
              return attachment
            }
          })
        )
        setCurrentReportState((prev) => {
          if (!prev)
            return { ...currentReport, attachments: updatedAttachments }
          return {
            ...prev,
            attachments: updatedAttachments,
          }
        })
      }
    }

    if (data?.getMediaFiles) fetchThumbnails()
  }, [currentReport, fetchPdfThumbnail, getAttachmentLink, data?.getMediaFiles])

  const handleAttchementClick = (bucketKey: string) => () => {
    const fileLink = data?.getMediaFiles.find(
      (file) => file.filename === bucketKey
    )?.content
    window.open(fileLink, '_blank', 'noopener,noreferrer')
  }

  return {
    isMobile,
    currentReport,
    currentReportState,
    currentReportIndex,
    handleNextReport,
    getAttachmentLink,
    handlePreviousReport,
    handleAttchementClick,
  }
}
