import { useAuth0 } from 'app/Auth'
import axios from 'axios'
import { useMutation } from 'react-query'
import { LaborCostActionResponse, LaborCostParams } from '../types'

export const useBulkUpsertLaborCost = () => {
  const { getAccessToken } = useAuth0()
  const LABOR_COST_URL = process.env.REACT_APP_REPORTS_URL

  return useMutation<LaborCostActionResponse, Error, LaborCostParams[]>(
    async (data: LaborCostParams[]) => {
      const token = await getAccessToken()
      try {
        const response = await axios.post(
          `${LABOR_COST_URL}api/v1/labor_cost/bulk_upsert`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data
      } catch (error) {
        throw error
      }
    }
  )
}
