import './AssignStep.scss'
import { Button } from '@mui/material'
import { LoadingButton } from 'components/mui'
import { useTrayStepContext } from '../AddTray.logic'
import TrayCaseDetails from 'components/organisms/TrayCaseDetails/TrayCaseDetails'
import { useAddTrayContext } from '../AddTray.context'

const AssignStep = () => {
  const { nextStep, prevStep } = useTrayStepContext()
  const { traySurgeryId } = useAddTrayContext()

  return (
    <>
      <div className="assign-step">
        <TrayCaseDetails />
      </div>
      <div className="buttons_container">
        <Button variant="outlined" onClick={prevStep} fullWidth>
          Back
        </Button>
        <LoadingButton variant="contained" onClick={nextStep} fullWidth>
          {traySurgeryId ? 'Next' : 'Skip'}
        </LoadingButton>
      </div>
    </>
  )
}

export default AssignStep
