import FloatingBar from 'components/FloatingBar/FloatingBar'
import './SPDLayout.scss'
import { useSPD } from './SPD/SPD.context'
import { SPDScanProvider } from './SPD/Scan/Scan.context'
import { AddTrayProvider } from 'views/TrayManagementLayout/AddTray/AddTray.context'
import { InventorySheetsProvider } from 'views/TrayManagementLayout/AddTray/InventorySheets/InventorySheets.context'

interface SPDLayoutProps {
  children: JSX.Element
}

const SPDLayout = ({ children }: SPDLayoutProps) => {
  const { navbarHeight } = useSPD()

  return (
    <SPDScanProvider>
      <InventorySheetsProvider>
        <AddTrayProvider>
          <div className="spd-layout">
            <FloatingBar />
            <div
              className="container"
              style={{
                paddingTop: navbarHeight,
                minHeight: `calc(100vh - ${navbarHeight}px)`,
              }}
            >
              {children}
            </div>
          </div>
        </AddTrayProvider>
      </InventorySheetsProvider>
    </SPDScanProvider>
  )
}

export default SPDLayout
