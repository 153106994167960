import { ItemResponse } from 'common/types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'

export interface EditTrayModalProps {
  open: boolean
  onClose: () => void
  trayData: ItemResponse
}

export interface TrayStatusDetails {
  rejectionReason?: string
  isRejected?: boolean
  rejectedBy?: string
  rejectedAt?: string
  isApproved?: boolean
  approvedBy?: string
  approvedAt?: string
  lastUpdatedBy?: string
  lastUpdatedAt?: string
}

export type TrayStatus =
  | 'approved'
  | 'rejected'
  | 'canceled'
  | 'readyForPickup'
  | 'caseComplete'
  | 'assigned'
  | 'readyToFill'
  | 'droppedOff'
  | 'unAssigned'
  | 'checkedOut'
  | 'loaded'
  | undefined

export type TrayType =
  | 'stryker screw caddy'
  | 'stryker upper face'
  | 'stryker middle face'
  | 'zimmer curved rods'
  | 'zimmer adjustable transverse connector'
  | 'zimmer standard closure tops'
  | 'instrument'
  | undefined

export const trayStatusDisplayMap: Partial<
  Record<Exclude<TrayStatus, undefined>, string>
> = {
  approved: 'Approved',
  rejected: 'Rejected',
  canceled: 'Canceled',
  readyForPickup: 'Ready For Pickup',
  caseComplete: 'Case Complete',
  assigned: 'Assigned',
  readyToFill: 'Ready To Fill',
  droppedOff: 'Dropped Off',
  unAssigned: 'Unassigned',
  checkedOut: 'Checked Out',
  loaded: 'Loaded/ Ready To Assign',
}

export interface UseEditTrayModalProps {
  trayData: ItemResponse
  trayScrews?: BetterIDTrayScrew[]
}
