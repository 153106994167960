import classNames from 'classnames'

import EditCount from '../EditCount/EditCount'

import MalleablePlate24 from 'assets/icons/svgs/malleable-plate-24-hole.svg'
import MalleablePlate18 from 'assets/icons/svgs/malleable-plate-18-hole.svg'
import MalleablePlate7 from 'assets/icons/svgs/malleable-plate-7-hole.svg'
import MalleablePlate2x2 from 'assets/icons/svgs/malleable-plate-2x2-hole.svg'
import MalleablePlate6x2 from 'assets/icons/svgs/malleable-plate-6x2-hole.svg'
import Curved10Hole from 'assets/icons/svgs/10-hole-curved.svg'
import StandardPlate7 from 'assets/icons/svgs/standard-plate-7-hole.svg'
import StandardPlate2x2 from 'assets/icons/svgs/standard-plate-2x2-hole.svg'
import StandardPlate6x2 from 'assets/icons/svgs/standard-plate-6x2-hole.svg'
import Standard7DoubleY from 'assets/icons/svgs/standard-7-hole-double-y.svg'
import StandardPlate24 from 'assets/icons/svgs/standard-plate-24-hole.svg'
import StandardPlate8 from 'assets/icons/svgs/standard-plate-8-hole.svg'
import StandardOrbital4 from 'assets/icons/svgs/standard-orbital-4-hole.svg'
import StandardPlate8Deg90Left from 'assets/icons/svgs/standard-plate-8-hole-90-degree-left.svg'
import StandardPlate8Deg90Right from 'assets/icons/svgs/standard-plate-8-hole-90-degree-right.svg'
import StandardCurved12 from 'assets/icons/svgs/standard-12-hole-curved.svg'
import DrillBit12 from 'assets/icons/svgs/drill-bit-12-mm.svg'
import DrillBit6 from 'assets/icons/svgs/drill-bit-6-mm.svg'
import DrillBit4 from 'assets/icons/svgs/drill-bit-4-mm.svg'

import { TrayPlatesAreaProps } from './TrayPlatesArea.types'
import './TrayPlatesArea.scss'
import getIsMobileOrTablet from 'lib/utils/getIsMobileOrTablet'

const TrayPlatesArea = ({
  handlePlateCountChange,
  getInitialPlateCount,
  platesSize = 'default',
  disableIncrease = false,
}: TrayPlatesAreaProps) => {
  return (
    <div
      className={classNames('tray-plates-area-organism_container', {
        small: platesSize === 'small',
        'is-mobile': getIsMobileOrTablet(),
      })}
    >
      <div className="plate_container">
        <img src={MalleablePlate24} alt="Malleable Plate, 24 holes" />
        <EditCount
          initialCount={getInitialPlateCount('malleablePlate24')}
          disableIncrease={disableIncrease}
          onChange={(newCount) =>
            handlePlateCountChange('malleablePlate24', newCount)
          }
        />
      </div>

      <div className="plate-row_wrapper">
        <div className="plate_container">
          <img src={MalleablePlate7} alt="Malleable Plate, 7 holes" />
          <EditCount
            initialCount={getInitialPlateCount('malleablePlate7')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('malleablePlate7', newCount)
            }
          />
        </div>

        <div className="plate_container">
          <img src={MalleablePlate18} alt="Malleable Plate, 18 holes" />
          <EditCount
            initialCount={getInitialPlateCount('malleablePlate18')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('malleablePlate18', newCount)
            }
          />
        </div>
      </div>

      <div className="plate-row_wrapper">
        <div className="plate_container">
          <img src={MalleablePlate2x2} alt="Malleable Plate, 2x2 holes" />
          <EditCount
            initialCount={getInitialPlateCount('malleablePlate2x2')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('malleablePlate2x2', newCount)
            }
          />
        </div>

        <div className="plate_container">
          <img src={Curved10Hole} alt="Curved Plate, 10 holes" />
          <EditCount
            initialCount={getInitialPlateCount('curved10Hole')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('curved10Hole', newCount)
            }
          />
        </div>

        <div className="plate_container">
          <img src={MalleablePlate6x2} alt="Malleable Plate, 6x2 holes" />
          <EditCount
            initialCount={getInitialPlateCount('malleablePlate6x2')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('malleablePlate6x2', newCount)
            }
          />
        </div>
      </div>

      <div className="plate-row_wrapper">
        <div className="plate_container">
          <img src={StandardPlate7} alt="Standard Plate, 7 holes" />
          <EditCount
            initialCount={getInitialPlateCount('standardPlate7')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('standardPlate7', newCount)
            }
          />
        </div>

        <div className="plate_container">
          <img src={StandardPlate2x2} alt="Standard Plate, 2x2 holes" />
          <EditCount
            initialCount={getInitialPlateCount('standardPlate2x2')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('standardPlate2x2', newCount)
            }
          />
        </div>

        <div className="plate_container">
          <img src={StandardPlate6x2} alt="Standard Plate, 6x2 holes" />
          <EditCount
            initialCount={getInitialPlateCount('standardPlate6x2')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('standardPlate6x2', newCount)
            }
          />
        </div>
      </div>

      <div className="plate-row_wrapper">
        <div className="plate_container">
          <img src={Standard7DoubleY} alt="Standard, 7 hole Double Y" />
          <EditCount
            initialCount={getInitialPlateCount('standard7DoubleY')}
            disableIncrease={disableIncrease}
            onChange={(newCount) =>
              handlePlateCountChange('standard7DoubleY', newCount)
            }
          />
        </div>

        <div className="plate-column_wrapper">
          <div className="plate_container">
            <img src={StandardPlate24} alt="Standard Plate, 24 holes" />
            <EditCount
              initialCount={getInitialPlateCount('standardPlate24')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('standardPlate24', newCount)
              }
            />
          </div>

          <div className="plate-row_wrapper">
            <div className="plate_container">
              <img src={StandardPlate8} alt="Standard Plate, 8 holes" />
              <EditCount
                initialCount={getInitialPlateCount('standardPlate8')}
                disableIncrease={disableIncrease}
                onChange={(newCount) =>
                  handlePlateCountChange('standardPlate8', newCount)
                }
              />
            </div>

            <div className="plate_container">
              <img src={StandardOrbital4} alt="Standard Orbital, 4 holes" />
              <EditCount
                initialCount={getInitialPlateCount('standardOrbital4')}
                disableIncrease={disableIncrease}
                onChange={(newCount) =>
                  handlePlateCountChange('standardOrbital4', newCount)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="plate-row_wrapper">
        <div className="plate-row_wrapper">
          <div className="plate_container">
            <img
              src={StandardPlate8Deg90Left}
              alt="Standard Plate, 8 holes, 90 degrees left"
            />
            <EditCount
              initialCount={getInitialPlateCount('standardPlate8Deg90Left')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('standardPlate8Deg90Left', newCount)
              }
            />
          </div>
          <div className="plate_container">
            <img
              src={StandardPlate8Deg90Right}
              alt="Standard Plate, 8 holes, 90 degrees right"
            />
            <EditCount
              initialCount={getInitialPlateCount('standardPlate8Deg90Right')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('standardPlate8Deg90Right', newCount)
              }
            />
          </div>
        </div>

        <div className="plate-column_wrapper">
          <div className="plate_container">
            <img src={StandardCurved12} alt="Standard, 12 holes, Curved" />
            <EditCount
              initialCount={getInitialPlateCount('standardCurved12')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('standardCurved12', newCount)
              }
            />
          </div>

          <div className="plate_container">
            <img src={DrillBit12} alt="Standard Drill Bit, 12mm" />
            <EditCount
              initialCount={getInitialPlateCount('drillBit12')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('drillBit12', newCount)
              }
            />
          </div>

          <div className="plate_container">
            <img src={DrillBit6} alt="Standard Drill Bit, 6mm" />
            <EditCount
              initialCount={getInitialPlateCount('drillBit6')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('drillBit6', newCount)
              }
            />
          </div>

          <div className="plate_container">
            <img src={DrillBit4} alt="Standard Drill Bit, 4mm" />
            <EditCount
              initialCount={getInitialPlateCount('drillBit4')}
              disableIncrease={disableIncrease}
              onChange={(newCount) =>
                handlePlateCountChange('drillBit4', newCount)
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrayPlatesArea
