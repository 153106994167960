import { Box } from '@mui/material'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { FormEvent, useEffect } from 'react'
import './ButtonSet.scss'

export interface ButtonSetProps {
  isLoadingState: boolean
  secondaryButtonAction?: () => void
  primaryButtonDisabled?: boolean
  handleOnSubmit?: (
    e?: React.MouseEvent<HTMLButtonElement>
  ) =>
    | void
    | ((trayImage?: string) => void)
    | ((e?: FormEvent<HTMLFormElement | HTMLButtonElement>) => void)
  primaryButtonText?: string
  dataTestId?: string
  secondaryButtonText?: string
  condenseButtons?: boolean
  secondaryButtonDisabled?: boolean
  wrapText?: boolean
  closeButtonOnly?: boolean
}

export const ButtonSet = ({
  isLoadingState,
  secondaryButtonAction,
  primaryButtonDisabled,
  handleOnSubmit,
  primaryButtonText,
  dataTestId,
  secondaryButtonText = 'Close',
  condenseButtons = false,
  secondaryButtonDisabled,
  wrapText = true,
  closeButtonOnly = false,
}: ButtonSetProps) => {
  return (
    <Box
      className={`${condenseButtons ? 'condensed-button-box' : 'button-box'}`}
    >
      <LoadingButton
        data-testid={`${dataTestId}-close-button`}
        className={`${condenseButtons ? 'condensed-button' : ''}`}
        variant="outlined"
        disabled={secondaryButtonDisabled}
        onClick={secondaryButtonAction}
        wrapText={wrapText}
      >
        {secondaryButtonText}
      </LoadingButton>
      {!closeButtonOnly && (
        <LoadingButton
          data-testid={`${dataTestId}-submit-button`}
          className={`${condenseButtons ? 'condensed-button' : ''}`}
          disabled={primaryButtonDisabled}
          loading={isLoadingState}
          variant="contained"
          onClick={(e) => handleOnSubmit && handleOnSubmit(e)}
          wrapText={wrapText}
        >
          {primaryButtonText}
        </LoadingButton>
      )}
    </Box>
  )
}
