import './TrayId.scss'
import { IconButton, InputAdornment, TextField, Paper } from '@mui/material'
import { Cached, QrCode } from '@mui/icons-material'
import { LoadingButton } from 'components/mui'
import { generateTrayId } from 'lib/utils/generateTrayId'
import SPDCortexScanner from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner'
import useTrayIdLogic from './TrayId.logic'

const TrayId = () => {
  const {
    trayBarcode,
    handleTrayBarcodeChange,
    showScanner,
    handleShowScannerButtonClick,
    handleCortexScan,
    handleNext,
    orgPrefix,
  } = useTrayIdLogic()

  return (
    <>
      <Paper className="tray-id-step step-item">
        <div className="tray-id-step_content">
          <TextField
            autoComplete="off"
            placeholder="Enter Tray ID (required)"
            fullWidth
            type="text"
            value={trayBarcode}
            onChange={handleTrayBarcodeChange}
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === 'Enter' && trayBarcode) {
                handleNext()
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        handleTrayBarcodeChange(
                          generateTrayId({
                            prefix: orgPrefix?.toUpperCase(),
                          })
                        )
                      }}
                      color="primary"
                    >
                      <Cached />
                    </IconButton>
                  </InputAdornment>
                  <InputAdornment position="end">
                    <LoadingButton
                      className="show-scanner_button"
                      onClick={handleShowScannerButtonClick}
                    >
                      <QrCode />
                    </LoadingButton>
                  </InputAdornment>
                </>
              ),
            }}
          />

          {showScanner && (
            <div className="scanner-container">
              <SPDCortexScanner
                canScan={showScanner}
                onScan={handleCortexScan}
              />
            </div>
          )}
        </div>
      </Paper>
      <div className="buttons_container">
        <div /> {/* Empty div for spacing */}
        <LoadingButton
          disabled={!trayBarcode}
          onClick={handleNext}
          variant="contained"
        >
          Next
        </LoadingButton>
      </div>
    </>
  )
}

export default TrayId
