import { TrayPlate } from 'views/SPDLayout/SPD/SPD.types'

export const updateTrayPlates = (
  trayPlates: TrayPlate[],
  plateName: TrayPlate['plateName'],
  newCount: number,
  deviceId: string
): TrayPlate[] => {
  const updatedPlates = [...trayPlates]

  const existingPlateIndex = updatedPlates.findIndex(
    (trayPlate) => trayPlate.plateName === plateName
  )

  if (existingPlateIndex !== -1) {
    if (newCount <= 0) {
      return updatedPlates.filter(
        (trayPlate) => trayPlate.plateName !== plateName
      )
    } else {
      updatedPlates[existingPlateIndex] = {
        ...updatedPlates[existingPlateIndex],
        plateCount: newCount,
      }
      return updatedPlates
    }
  } else {
    if (newCount > 0) {
      return updatedPlates.concat({
        deviceId,
        plateName,
        plateCount: newCount,
      })
    }
    return updatedPlates
  }
}

export const getInitialRodCount = (
  trayPlates: TrayPlate[],
  plateName: string
): number => {
  const existingPlateIndex = trayPlates.findIndex(
    (trayPlate) => trayPlate.plateName === plateName
  )

  if (existingPlateIndex !== -1) {
    return trayPlates[existingPlateIndex].plateCount
  }

  return 0
}

export const shouldPlateIncreaseBeDisabled = (
  trayPlates: TrayPlate[],
  plateKey: string,
  PAR_LEVEL: number
) => {
  return !!trayPlates.find(
    (trayPlate) =>
      trayPlate.plateName === plateKey && trayPlate.plateCount === PAR_LEVEL
  )
}
