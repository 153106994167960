import { FC } from 'react'
import { LoadingButton } from 'components/mui'
import { useNewScrubTechnicianLogic } from './NewScrubTechnician.logic'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { EScrubTechniciansSpeciality } from 'common/types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import Header from 'components/molecules/Header/Header'
import TextInput from 'components/molecules/TextInput/TextInput'
import './NewScrubTechnician.scss'

const NewScrubTechnician: FC = () => {
  const {
    formData,
    isLoading,
    formErrors,
    headerTitle,
    navigate,
    handleFormSubmit,
    handleChangeUpdateScrubTechnicianDetails,
  } = useNewScrubTechnicianLogic()

  return (
    <div className="new-scrub-technician-main-container">
      <Header
        isMobile={useIsMobile()}
        hasBack
        onBackClick={() => navigate(-1)}
        parent="Reports"
        title={headerTitle}
      />
      <form onSubmit={handleFormSubmit} className="content">
        <div className="input-container">
          <TextInput
            disabled={isLoading}
            id="firstName"
            name="firstName"
            label="Scrub Technician First Name*"
            variant="outlined"
            value={formData.firstName}
            fullWidth
            handleChangeFormData={handleChangeUpdateScrubTechnicianDetails}
            helperText={formErrors?.firstName}
            error={!!formErrors?.firstName}
          />

          <TextInput
            id="lastName"
            disabled={isLoading}
            label="Scrub Technician Last Name*"
            name="lastName"
            variant="outlined"
            value={formData.lastName}
            fullWidth
            handleChangeFormData={handleChangeUpdateScrubTechnicianDetails}
            helperText={formErrors?.lastName}
            error={!!formErrors?.lastName}
          />

          <TextInput
            disabled={isLoading}
            id="email"
            name="email"
            label="Scrub Technician Email*"
            variant="outlined"
            value={formData.email.toLowerCase()}
            handleChangeFormData={handleChangeUpdateScrubTechnicianDetails}
            fullWidth
            validateEmail
            helperText={formErrors?.email}
            error={!!formErrors?.email}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />

          <TextInput
            disabled={isLoading}
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Scrub Technician Email*"
            variant="outlined"
            value={formData.confirmEmail.toLowerCase()}
            handleChangeFormData={handleChangeUpdateScrubTechnicianDetails}
            fullWidth
            validateEmail
            helperText={formErrors?.confirmEmail}
            error={!!formErrors?.confirmEmail}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />

          <FormControl
            fullWidth
            className="select-speciality-wrapper"
            error={!!formErrors?.speciality}
          >
            <InputLabel id="scrub-technician-label">
              Scrub Technician Speciality*
            </InputLabel>
            <Select
              disabled={isLoading}
              name="speciality"
              variant="outlined"
              value={formData.speciality}
              labelId="scrub-technician-label"
              onChange={handleChangeUpdateScrubTechnicianDetails}
            >
              {Object.values(EScrubTechniciansSpeciality).map((speciality) => (
                <MenuItem key={speciality} value={speciality}>
                  {speciality}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.speciality && (
              <FormHelperText>{formErrors.speciality}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="submit-button-container">
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            className="submit-button"
            loading={isLoading}
          >
            SUBMIT
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

export default NewScrubTechnician
