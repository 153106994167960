import { Typography } from '@mui/material'
import ClosureTopsScrew from 'assets/icons/svgs/closure-tops-screw.svg'
import EditCount from '../EditCount/EditCount'

import './ZimmerStandardClosureTopsTrayMap.scss'
import useZimmerStandardClosureTopsTrayMapLogic from './ZimmerStandardClosureTops.logic'
import { ZimmerStandardClosureTopsPlateKey } from './ZimmerStandardClosureTops.types'
import { ZimmerRodTrayMapProps } from '../ZimmerRodTrayMap/ZimmerRodTrayMap.types'

const ZimmerStandardClosureTopsTrayMap = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
}: ZimmerRodTrayMapProps) => {
  const {
    getInitialRodCount,
    shouldPlateIncreaseBeDisabled,
    handlePlateCountChange,
  } = useZimmerStandardClosureTopsTrayMapLogic({
    isInOR,
    setSelectedPlate,
    setModalOpen,
  })

  return (
    <div className="zimmer-closure-tops-tray-map_organism">
      <div className="screws_container">
        <div className="column">
          {Array(7)
            .fill(null)
            .map((_, index) => (
              <div key={index} className="screw_container">
                <img src={ClosureTopsScrew} alt="Zimmer Curved Rod" />
                <EditCount
                  initialCount={getInitialRodCount(
                    `closureTops${
                      index + 1
                    }` as ZimmerStandardClosureTopsPlateKey
                  )}
                  disableIncrease={
                    isInOR ||
                    shouldPlateIncreaseBeDisabled(
                      `closureTops${
                        index + 1
                      }` as ZimmerStandardClosureTopsPlateKey
                    )
                  }
                  onChange={(newCount) =>
                    handlePlateCountChange(
                      `closureTops${
                        index + 1
                      }` as ZimmerStandardClosureTopsPlateKey,
                      newCount
                    )
                  }
                />
              </div>
            ))}
        </div>
        <div className="column">
          {Array(7)
            .fill(null)
            .map((_, index) => (
              <div key={index} className="screw_container">
                <img src={ClosureTopsScrew} alt="Zimmer Curved Rod" />
                <EditCount
                  initialCount={getInitialRodCount(
                    `closureTops${
                      index + 8
                    }` as ZimmerStandardClosureTopsPlateKey
                  )}
                  disableIncrease={
                    isInOR ||
                    shouldPlateIncreaseBeDisabled(
                      `closureTops${
                        index + 8
                      }` as ZimmerStandardClosureTopsPlateKey
                    )
                  }
                  onChange={(newCount) =>
                    handlePlateCountChange(
                      `closureTops${
                        index + 8
                      }` as ZimmerStandardClosureTopsPlateKey,
                      newCount
                    )
                  }
                />
              </div>
            ))}
        </div>
      </div>

      <div className="ellipses_container">
        <div className="column">
          {Array(8).fill(<div className="ellipse" />)}
        </div>
        <div className="column">
          {Array(9).fill(<div className="ellipse" />)}
        </div>
        <div className="column">
          {Array(8).fill(<div className="ellipse" />)}
        </div>
      </div>

      <Typography variant="subtitle2" className="tray_title">
        Standard Closure Tops
      </Typography>
    </div>
  )
}

export default ZimmerStandardClosureTopsTrayMap
