/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

// Components
import { ButtonBase, Typography } from '@mui/material'
import { CameraAlt, Delete } from '@mui/icons-material'
import CaptureCamera from './CaptureCamera/CaptureCamera'

// Logic
import { IScanningResult, useLogic } from './MultiScanner.logic'

// Style
import './MultiScanner.scss'

// Other
import { uniqueId } from 'lodash'

interface ScanningResult {
  barcodeData: string
  symbologyName: string
}

const MultiScanner = ({
  onChange,
}: {
  onChange: (codes: IScanningResult[]) => void
}) => {
  const {
    open,
    image,
    isTablet,
    scanningResult,
    stopDecoding,
    handleRemove,
    handleSetImage,
    handleOpenCaptureModal,
    handleCloseCaptureModal,
  } = useLogic()

  useEffect(() => {
    if (scanningResult) onChange(scanningResult)
  }, [scanningResult])

  const groupedResults: { [key: string]: ScanningResult[] } = {}
  scanningResult.forEach((item) => {
    if (!groupedResults[item.symbologyName]) {
      groupedResults[item.symbologyName] = []
    }
    groupedResults[item.symbologyName].push(item)
  })

  useEffect(() => {
    if (open) {
      stopDecoding()
    }
  }, [open])

  return (
    <div className={`multi-scanner-container ${isTablet ? 'is-tablet' : ''}`}>
      {image ? (
        <div className="result-container">
          <div className="image">
            <img src={image} alt="test" />
          </div>
          <div className="codes">
            {Object.entries(groupedResults).map(([symbologyName, codes]) => (
              <div key={symbologyName} className="code-container">
                <Typography variant="h6">{symbologyName}</Typography>
                {codes.map((code, index) => (
                  <div key={index} className="code">
                    <Typography variant="h4">{code.barcodeData}</Typography>
                    <ButtonBase
                      onClick={() => handleRemove(index)}
                      className="remove"
                    >
                      <Delete />
                    </ButtonBase>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="upload-container">
          <ButtonBase className="icon" onClick={handleOpenCaptureModal}>
            <CameraAlt />
          </ButtonBase>
          <Typography>Capture</Typography>
        </div>
      )}

      <CaptureCamera
        open={open}
        onClose={handleCloseCaptureModal}
        onSave={handleSetImage}
        key={uniqueId()}
      />
    </div>
  )
}

export default MultiScanner
