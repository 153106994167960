import { LoadingButton } from 'components/mui'
import TrayMap from '../TrayMap/TrayMap'
import ConfirmTrayImageModal from 'components/organisms/ConfirmTrayImageModal/ConfirmTrayImageModal'
import './LoadSPDTrayMap.scss'
import { useState } from 'react'
import InventoryProvider from 'lib/apollo/inventory-config'
import { useSPD } from '../SPD.context'

const LoadSPDTrayMap = ({
  onNext,
}: {
  onNext: (stepNumber?: number) => void
}) => {
  const { onlyUseManualWorkflow, activeStep } = useSPD()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const handleDoneClick = () => {
    setIsConfirmModalOpen(true)
  }

  const handleConfirmImage = () => {
    setIsConfirmModalOpen(false)
  }

  return (
    <>
      <div
        className="load-spd-tray-map_page"
        style={{ marginTop: onlyUseManualWorkflow ? '40px' : '0px' }}
      >
        <TrayMap />

        <div className="buttons_container">
          {((onlyUseManualWorkflow && activeStep === 2) ||
            !onlyUseManualWorkflow) && (
            <LoadingButton onClick={() => onNext(0)}>
              Add Another Product
            </LoadingButton>
          )}
          {((onlyUseManualWorkflow && activeStep === 2) ||
            !onlyUseManualWorkflow) && (
            <LoadingButton onClick={handleDoneClick}>
              Done With This Tray
            </LoadingButton>
          )}
        </div>
      </div>

      <InventoryProvider>
        <ConfirmTrayImageModal
          open={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleConfirmImage}
        />
      </InventoryProvider>
    </>
  )
}

export default LoadSPDTrayMap
