import { HeadCell } from './LaborCost.types'

export const laborCostTableHeaders: HeadCell[] = [
  {
    id: 'procedureType',
    label: 'PROCEDURE TYPE',
    numeric: true,
    sx: {
      maxWidth: '50px',
      minWidth: '90px',
    },
  },
  {
    id: 'rnCost',
    label: 'RN COST/HOUR',
    numeric: true,
    sx: {
      maxWidth: '55px',
      minWidth: '80px',
    },
  },
  {
    id: 'scrubTechCost',
    label: 'SCRUB TECH COST/HOUR',
    numeric: true,
    sx: {
      maxWidth: '140px',
    },
  },
  {
    id: 'updatedAt',
    label: 'UPDATED AT',
    numeric: true,
    sx: {
      maxWidth: '40px',
    },
  },
  {
    id: 'actions',
    label: 'ACTIONS',
    numeric: true,
  },
]

export const uploadedLaborCostTableHeaders: HeadCell[] = [
  {
    id: 'procedureType',
    label: 'Procedure Type',
    numeric: true,
  },
  {
    id: 'rnCost',
    label: 'RN Cost/hour',
    numeric: true,
  },
  {
    id: 'scrubTechCost',
    label: 'Scrub Tech Cost/hour',
    numeric: true,
  },
]
