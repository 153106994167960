/* eslint-disable */
import { Link, useNavigate } from 'react-router-dom'
import { ArrowBack, BorderClear, QrCode2 } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'

import TrayProduct from './TrayProduct/TrayProduct'
import { LoadingButton } from 'components/mui'

import { useTrayProducts } from './TrayProduct/TrayProducts.logic'

import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import './TrayProducts.scss'
import { UsagePlateWithDescription } from './TrayProducts.types'

const TrayProducts = () => {
  const navigate = useNavigate()
  const {
    implantedScrews,
    wastedScrews,
    handleSubmit,
    isSearching,
    surgeryUsagePlates,
    renderScrewData,
    renderUsagePlates,
  } = useTrayProducts()

  return (
    <div className="dtm-tray-products_wrapper">
      <div className="header-buttons_container">
        <Button
          variant="outlined"
          size="small"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#5A43E9',
            border: 'none',
            backgroundColor: 'white',
          }}
          to=".."
          component={Link}
        >
          <ArrowBack sx={{ mr: 0.5 }} /> BACK
        </Button>

        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#5A43E9',
            border: 'none',
            backgroundColor: 'white',
          }}
          onClick={() => navigate(`../tray-visualization`)}
        >
          <BorderClear sx={{ mr: 0.5 }} /> View/ Edit Tray Map
        </button>
      </div>

      <Typography variant="h1" my={1}>
        Surgery Products
      </Typography>

      <div className="screw-data_container">
        <Typography variant="h2" my={1}>
          Selected Hardware
        </Typography>
        {renderScrewData(
          implantedScrews,
          'There were no implanted screws in this operation'
        )}
        {renderUsagePlates(
          (usagePlate) =>
            usagePlate.plateName.includes('closure') && !usagePlate.wasted,
          'There were no implanted screws in this operation'
        )}

        <Typography variant="h2" my={1}>
          Wasted Screws
        </Typography>
        {renderScrewData(
          wastedScrews,
          'There were no wasted screws in this operation'
        )}
        {renderUsagePlates(
          (usagePlate) =>
            usagePlate.plateName.includes('closure') && usagePlate.wasted,
          'There were no wasted screws in this operation'
        )}

        <Typography variant="h2" my={1}>
          Plates
        </Typography>
        {renderUsagePlates(
          (usagePlate) => !usagePlate.plateName.includes('closure'),
          'There were no plates in this operation'
        )}
      </div>

      <LoadingButton
        disabled={
          isSearching ||
          (implantedScrews.length === 0 &&
            wastedScrews.length === 0 &&
            surgeryUsagePlates.length === 0)
        }
        loading={isSearching}
        onClick={handleSubmit}
        className="confirm-button"
      >
        Confirm Hardware Selection
      </LoadingButton>
    </div>
  )
}

export default TrayProducts
