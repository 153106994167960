import {
  ImplantReportInputs,
  ImplantReportStatus,
  ImplantReportType,
  TUploadFileObject,
} from 'common/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSendImplantReportEmailMutation } from 'lib/apollo/hooks'
import {
  ImplantReportInputValue,
  ImplantReportSenderModalLogicProps,
} from '../ImplantReportSender.types'
import { z } from 'zod'
import { enqueueSnackbar } from 'notistack'
import { fileToBase64WithThumbnail } from 'lib/utils/fileToBase64'
import { pdfToBase64Image } from 'common/utils'

export const useImplantReportSenderModalLogic = ({
  assetIds,
  surgeryId,
  implantReportStatus,
  implantReportInputs,
  setImplantReportInputs,
  unformatEnumValue,
  setErrorMessage,
  formatEnumValue,
  setInputModalOpen,
}: ImplantReportSenderModalLogicProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  // Hooks
  const [sendImplantReport, sendImplantReportMutation] =
    useSendImplantReportEmailMutation()

  // States
  const [reportAlreadySentModal, setReportAlreadySentModal] =
    useState<boolean>(false)
  const [extractionValues, setExtractionValues] = useState<
    Array<string | number>
  >([''])
  const [attachments, setAttachments] = useState<TUploadFileObject[]>([])

  // Constants
  const reportAlreadySent = (option: keyof typeof ImplantReportType) => {
    const enumType = unformatEnumValue(option)
    return (implantReportStatus as ImplantReportStatus[]).some(
      (status) => status.type === enumType && status.isSent
    )
  }

  // Handlers
  const handleChangeFormData = <Key extends keyof ImplantReportInputs>(
    reportType: Key,
    value: ImplantReportInputValue<Key>
  ) => {
    setValidationErrors(null)
    setImplantReportInputs((prevState) => ({
      ...prevState,
      reportInputs: {
        ...prevState.reportInputs,
        [reportType]: value,
      },
    }))
  }

  const handleAddExtractionInput = () => {
    setExtractionValues((prev) => [...prev, ''])
  }
  const handleRemoveExtractionInput = (index: number) => {
    if (extractionValues.length === 1) return

    setExtractionValues((prev) => {
      const newValues = prev.filter((_, i) => i !== index)

      setImplantReportInputs((prevInputs) => ({
        ...prevInputs,
        reportInputs: {
          ...prevInputs.reportInputs,
          extractionValues: newValues,
        },
      }))

      return newValues
    })
  }

  const handleChangeExtractionInput = (index: number, value: string) => {
    setValidationErrors(null)
    if (value.length <= 4) {
      const newValues = [...extractionValues]
      newValues[index] = value
      setExtractionValues(newValues)
      handleChangeFormData('extractionValues', newValues)
    }
  }

  const implantReportSchema = z.object({
    extractionValues: z
      .array(z.string().min(1, 'Extraction site should not be empty'))
      .optional()
      .superRefine((extractionValues, context) => {
        if (implantReportInputs.reportType === 'EXTRACTION') {
          if (!extractionValues || extractionValues.length === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Extraction site should not be empty',
            })
          }
        }
      }),
    additionalComments: z
      .string()
      .optional()
      .superRefine((additionalComments, context) => {
        if (implantReportInputs.reportType === 'OTHER') {
          if (!additionalComments || additionalComments.length === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Additional comments should not be empty',
            })
          }
        }
      }),
    nextApptDate: z
      .date()
      .optional()
      .superRefine((nextApptDate, context) => {
        if (
          implantReportInputs.reportType === 'FIRST_STAGE' ||
          implantReportInputs.reportType === 'SINGLE'
        ) {
          if (!nextApptDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be empty',
            })
          }
        }
      }),
    nextApptInMonths: z
      .number()
      .optional()
      .superRefine((nextApptInMonths, context) => {
        if (
          implantReportInputs.reportType === 'FIRST_STAGE' ||
          implantReportInputs.reportType === 'SINGLE'
        ) {
          if (!nextApptInMonths && nextApptInMonths !== 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be empty',
            })
          }
          if (nextApptInMonths === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be zero',
            })
          }
        }
      }),
    secondStageAbutmentType: z
      .string()
      .optional()
      .superRefine((secondStageAbutmentType, context) => {
        if (implantReportInputs.reportType === 'FIRST_STAGE') {
          if (
            !secondStageAbutmentType ||
            secondStageAbutmentType.length === 0
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Second stage abutment type should not be empty',
            })
          }
        }
      }),
  })

  type TValidationError = {
    extractionValues?: string[] | undefined
    additionalComments?: string[] | undefined
    nextApptDate?: string[] | undefined
    nextApptInMonths?: string[] | undefined
    secondStageAbutmentType?: string[] | undefined
  }

  const [validationErrors, setValidationErrors] =
    useState<TValidationError | null>(null)

  const validateAndSubmit = () => {
    const validationResult = implantReportSchema.safeParse(
      implantReportInputs.reportInputs
    )

    if (!validationResult.success) {
      const errors = validationResult.error.formErrors.fieldErrors

      const mappedErrors = {
        ...errors,
        extractionValues: extractionValues.map((value, index) => {
          const v = value as string
          return v.trim() === '' && errors.extractionValues
            ? errors.extractionValues[0]
            : undefined
        }),
      }

      setValidationErrors(mappedErrors as TValidationError)
      return
    }

    const reportType =
      implantReportInputs.reportType as keyof typeof ImplantReportType
    if (reportAlreadySent(reportType)) {
      setReportAlreadySentModal(true)
      return
    }
    submitReport()
  }

  // solves async state update on submitOverride flag issue
  useEffect(() => {
    if (implantReportInputs.submitOverride) {
      submitReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implantReportInputs.submitOverride])

  const handleOnClose = () => {
    setInputModalOpen(false)
    setImplantReportInputs({
      surgeryId,
      reportType: undefined,
      referringPhysicianEmail: implantReportInputs.referringPhysicianEmail,
      referringPhysicianLastName:
        implantReportInputs.referringPhysicianLastName,
      reportInputs: {
        assetIds: assetIds,
      },
      submitOverride: false,
    })
    setExtractionValues([''])
  }

  const submitReport = async () => {
    try {
      if (
        attachments.reduce((acc, curr) => acc + curr.size, 0) >=
        4 * 1024 * 1024
      ) {
        enqueueSnackbar('Attachments size exceeds 4MB', {
          variant: 'error',
        })
        setReportAlreadySentModal(false)
        return
      }

      const attachmentsToSend = attachments.map((attachment) => {
        const { size, thumbnail, ...rest } = attachment
        return rest
      })

      const reportVariables = {
        ...implantReportInputs,
        reportInputs: {
          ...implantReportInputs.reportInputs,
          extractionValues:
            implantReportInputs.reportInputs.extractionValues?.filter(
              (item) => item !== ''
            ),
          attachments: attachmentsToSend,
        },
      }

      const result = await sendImplantReport({
        variables: {
          implantReportEmailInputs: [reportVariables],
        },
      })

      if (result.data?.sendImplantReportEmail?.success) {
        enqueueSnackbar(
          `${formatEnumValue(
            implantReportInputs?.[
              'reportType'
            ] as keyof typeof ImplantReportType
          )} Report Sent Successfully`,
          {
            variant: 'success',
          }
        )
        setInputModalOpen(false)
        setReportAlreadySentModal(false)
        setImplantReportInputs((prevState) => ({
          ...prevState,
          submitOverride: false,
        }))
      } else {
        enqueueSnackbar(
          `${formatEnumValue(
            implantReportInputs?.[
              'reportType'
            ] as keyof typeof ImplantReportType
          )} Report Failed To Send. Please check error details if this is the correct report template.`,
          {
            variant: 'error',
          }
        )
        setReportAlreadySentModal(false)
        setErrorMessage(result.data?.sendImplantReportEmail?.message)
      }
    } catch (error) {
      enqueueSnackbar(
        `${formatEnumValue(
          implantReportInputs?.['reportType'] as keyof typeof ImplantReportType
        )} Report Failed To Send. Please check error details if this is the correct report template.`,
        {
          variant: 'error',
        }
      )
    }
  }

  const handleAttach = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      fileInputRef.current.click()
    }
  }, [])

  const handleAttachementFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files || null

    if (!selectedFiles || selectedFiles.length === 0) {
      console.error('No file selected.')
      return
    }

    try {
      const newAttachments = await Promise.all(
        Array.from(selectedFiles).map(async (file: File) => {
          const { base64, thumbnail } = await fileToBase64WithThumbnail(file)
          if (base64 && thumbnail) {
            return {
              filename: file.name,
              mimetype: file.type,
              encoding: 'base64',
              content: base64,
              size: file.size,
              thumbnail:
                file.name.split('.').pop()?.toLocaleLowerCase() === 'pdf'
                  ? await pdfToBase64Image(base64)
                  : thumbnail,
            }
          }
        })
      )

      setAttachments((prev) => [
        ...prev,
        ...newAttachments.filter(
          (attachment): attachment is TUploadFileObject =>
            attachment !== undefined
        ),
      ])
    } catch (error) {
      console.error('Error processing files:', error)
    }
  }

  const handleRemoveAttachment = (index: number) => () => {
    setAttachments((prev) => prev.filter((_, i) => i !== index))
  }

  return {
    attachments,
    fileInputRef,
    validationErrors,
    extractionValues,
    reportAlreadySentModal,
    sendImplantReportMutation,
    handleAttach,
    submitReport,
    handleOnClose,
    validateAndSubmit,
    handleChangeFormData,
    handleRemoveAttachment,
    handleAddExtractionInput,
    setReportAlreadySentModal,
    handleChangeExtractionInput,
    handleRemoveExtractionInput,
    handleAttachementFileChange,
  }
}
