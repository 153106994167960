import { useEffect, useState } from 'react'
import { Error } from '@mui/icons-material'

import { matchProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import { useSPDScan } from '../Scan/Scan.context'
import { useSPD } from '../SPD.context'

import { BetterIDTrayScrew, TrayPlate } from '../SPD.types'
import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'

import screwCaddy from './screwCaddy.json'
import upperFace from './upperFace.json'
import middleFace from './middleFace.json'
import {
  PlateCounts,
  PlateKey,
  TrayMapProps,
  plateUDIMap,
} from './TrayMap.types'
import { ZimmerCurvedRodsPlateKey } from 'components/organisms/ZimmerRodTrayMap/ZimmerRodTrayMap.types'
import { ZimmerTransverseConnectorsPlateKey } from 'components/organisms/ZimmerTransverseConnectorsTrayMap/ZimmerTransverseConnectors.types'
import { ZimmerStandardClosureTopsPlateKey } from 'components/organisms/ZimmerStandardClosureTopsTrayMap/ZimmerStandardClosureTops.types'

const useTrayMapLogic = ({
  trayType = 'stryker screw caddy',
  loadWithUDIs = true,
  mapContent,
}: TrayMapProps = {}) => {
  const {
    trayScrews,
    setTrayScrews,
    trayPlates,
    setTrayPlates,
    selectedScrew,
    setSelectedScrew,
    selectedScrews,
    setSelectedScrews,
    setScrewLoadModalOpen,
    setSnackbarMessage,
    setSnackbarIcon,
    setSnackbarOpen,
    setSnackbarState,
    isLoadingWithUDIs,
    setIsLoadingWithUDIs,
    setActiveStep,
    onlyUseManualWorkflow,
  } = useSPD()
  const {
    setScannedCode,
    setManualCode,
    productData,
    setProductData,
    isAdditionalDeviceCountModalOpen,
    setIsAdditionalDeviceCountModalOpen,
    isLoadMultipleBoxVisible,
    setIsLoadMultipleBoxVisible,
  } = useSPDScan()

  const [confirmChangesModalOpen, setConfirmChangesModalOpen] = useState(false)
  const [isConfirmScrewRemovalModalOpen, setIsConfirmScrewRemovalModalOpen] =
    useState(false)
  const [plateCounts, setPlateCounts] = useState<PlateCounts>({})

  useEffect(() => {
    if (!loadWithUDIs) {
      setIsLoadingWithUDIs(false)
    }
  }, [loadWithUDIs])

  useEffect(() => {
    if (mapContent) {
      setTrayScrews(mapContent.screws || [])
      setTrayPlates(mapContent.plates || [])
    }
  }, [mapContent?.screws?.length, mapContent?.plates?.length])

  const getSelectedJSONFile = (trayType: TrayType) => {
    switch (trayType?.toLowerCase()) {
      case 'stryker screw caddy':
        return screwCaddy
      case 'stryker middle face':
        return middleFace
      case 'stryker upper face':
        return upperFace
      default:
        return screwCaddy
    }
  }

  const isScrewLoaded = (label: string, row: number, column: number) => {
    return trayScrews.some(
      (screw: any) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const findScrew = (label: string, row: number, column: number) => {
    return trayScrews.find(
      (screw: any) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const trayJSONStructure = getSelectedJSONFile(trayType)
  const screwAreas = trayJSONStructure.regions[0].areas

  const handleScrewSelection = (
    label: string,
    rowIndex: number,
    column: number,
    x: number
  ) => {
    const newSelectedScrew: BetterIDTrayScrew = {
      label,
      row: rowIndex,
      column,
      x,
    }

    if (isLoadingWithUDIs) {
      handleLoadingWithUDIs(newSelectedScrew)
    } else {
      handleNotLoadingWithUDIs(newSelectedScrew)
    }
  }

  const handleLoadingWithUDIs = (newSelectedScrew: BetterIDTrayScrew) => {
    if (isLoadMultipleBoxVisible) {
      if (isScrewAlreadySelected(newSelectedScrew)) {
        removeSelectedScrew(newSelectedScrew)
        return
      }

      if (hasExceededScrewLimit()) {
        showSnackbarError("You've already exceeded your screw limit")
        return
      }

      addSelectedScrew(newSelectedScrew)
      return
    }

    setSelectedScrew(newSelectedScrew)

    if (
      isScrewLoaded(
        newSelectedScrew.label,
        newSelectedScrew.row,
        newSelectedScrew.x
      )
    ) {
      setIsConfirmScrewRemovalModalOpen(true)
    } else {
      setScrewLoadModalOpen(true)
    }
  }

  const handleNotLoadingWithUDIs = (newSelectedScrew: BetterIDTrayScrew) => {
    if (
      isScrewLoaded(
        newSelectedScrew.label,
        newSelectedScrew.row,
        newSelectedScrew.x
      )
    ) {
      handleRemoveScrew(newSelectedScrew)
    } else {
      handleStatusChange(newSelectedScrew)
    }
  }

  const isScrewAlreadySelected = (screw: BetterIDTrayScrew): boolean => {
    return selectedScrews.some(
      (selectedScrew) =>
        selectedScrew.label === screw.label &&
        selectedScrew.row === screw.row &&
        selectedScrew.column === screw.column &&
        selectedScrew.x === screw.x
    )
  }

  const removeSelectedScrew = (screw: BetterIDTrayScrew) => {
    const updatedSelectedScrews = selectedScrews.filter(
      (selectedScrew) =>
        !(
          selectedScrew.label === screw.label &&
          selectedScrew.row === screw.row &&
          selectedScrew.column === screw.column &&
          selectedScrew.x === screw.x
        )
    )

    setSelectedScrews(updatedSelectedScrews)
  }

  const hasExceededScrewLimit = (): boolean => {
    return selectedScrews.length === productData[0]?.deviceCount
  }

  const showSnackbarError = (message: string) => {
    setSnackbarMessage(message)
    setSnackbarIcon(<Error />)
    setSnackbarState('error')
    setSnackbarOpen(true)
  }

  const addSelectedScrew = (newSelectedScrew: BetterIDTrayScrew) => {
    setSelectedScrews((prevSelectedScrews) => [
      ...prevSelectedScrews,
      newSelectedScrew,
    ])
  }

  const handleLoadScrew = () => {
    setActiveStep(2)
    if (productData[0].deviceCount > 1) {
      setIsAdditionalDeviceCountModalOpen(true)
    } else {
      handleStatusChange()
    }
  }

  const handleConfirmLoadMultiple = () => {
    setSelectedScrews([selectedScrew as BetterIDTrayScrew])
    setIsLoadMultipleBoxVisible(true)
    setIsAdditionalDeviceCountModalOpen(false)
    setSelectedScrew(null)
    setScrewLoadModalOpen(false)
  }

  const handleStatusChange = (selectedScrewAsset?: BetterIDTrayScrew) => {
    const screwData = matchProduct(
      selectedScrewAsset?.label,
      selectedScrewAsset?.column
    )

    const updatedScrew = createUpdatedScrew(selectedScrewAsset, screwData)
    const updatedScrews = [...trayScrews, updatedScrew]

    setIsAdditionalDeviceCountModalOpen(false)
    setTrayScrews(updatedScrews)
    setScrewLoadModalOpen(false)
    setSelectedScrew(null)
  }

  const createUpdatedScrew = (
    selectedScrewAsset?: BetterIDTrayScrew,
    screwData?: { deviceID: string; title: string }
  ): BetterIDTrayScrew => {
    return isLoadingWithUDIs
      ? {
          ...(selectedScrew as BetterIDTrayScrew),
          deviceId: productData[0].deviceId,
          deviceDescription: productData[0].deviceDescription,
          company: productData[0].company,
          expirationDate: productData[0].expirationDate,
          wasted: false,
        }
      : {
          ...(selectedScrewAsset as BetterIDTrayScrew),
          deviceId: screwData?.deviceID,
          deviceDescription: screwData?.title,
          company: {
            name: 'Stryker',
            id: 0,
            regEmail: '',
          },
          expirationDate: null,
          wasted: false,
        }
  }

  const handleCancelScrewsSelection = () => {
    setSelectedScrews([])
    setIsLoadMultipleBoxVisible(false)
    setProductData([])
    setManualCode('')
    setScannedCode('')
  }

  const handleConfirmScrewsSelection = () => {
    const newSelectedScrews = selectedScrews.map((screw) => ({
      ...(screw as BetterIDTrayScrew),
      deviceId: productData[0].deviceId,
      deviceDescription: productData[0].deviceDescription,
      company: productData[0].company,
      expirationDate: productData[0].expirationDate,
      wasted: false,
    }))

    setTrayScrews((prevTrayScrews) => [...prevTrayScrews, ...newSelectedScrews])
    setSelectedScrew(null)
    setSelectedScrews([])
    setScannedCode('')
    setManualCode('')
    setIsLoadMultipleBoxVisible(false)
  }

  const handleCancelSelection = () => {
    setSelectedScrew(null)
    setManualCode('')
    setScannedCode('')
    setScrewLoadModalOpen(false)
    if (onlyUseManualWorkflow) {
      setActiveStep(0)
    }
  }

  const handleConfirmScrewRemovalModalClose = () => {
    setIsConfirmScrewRemovalModalOpen(false)
    setSelectedScrew(null)
  }

  const handleRemoveScrew = (screw: BetterIDTrayScrew) => {
    const newTrayScrews = trayScrews.filter(
      (trayScrew) =>
        !(
          trayScrew.x === screw.x &&
          trayScrew.row === screw.row &&
          trayScrew.label === screw.label
        )
    )

    setTrayScrews(newTrayScrews)
    handleConfirmScrewRemovalModalClose()
  }

  const getPlateDI = (key: PlateKey): string => {
    return plateUDIMap[key]
  }

  const getInitialPlateCount = (plateName: PlateKey): number => {
    const existingPlateIndex = trayPlates.findIndex(
      (trayPlate) => trayPlate.plateName === plateName
    )

    if (existingPlateIndex !== -1) {
      return trayPlates[existingPlateIndex].plateCount
    }

    return 0
  }

  const updateTrayPlates = (
    trayPlates: TrayPlate[],
    plateName:
      | PlateKey
      | ZimmerCurvedRodsPlateKey
      | ZimmerTransverseConnectorsPlateKey
      | ZimmerStandardClosureTopsPlateKey,
    newCount: number,
    deviceId: string
  ): TrayPlate[] => {
    const updatedPlates = [...trayPlates]

    const existingPlateIndex = updatedPlates.findIndex(
      (trayPlate) => trayPlate.plateName === plateName
    )

    if (existingPlateIndex !== -1) {
      if (newCount <= 0) {
        return updatedPlates.filter(
          (trayPlate) => trayPlate.plateName !== plateName
        )
      } else {
        updatedPlates[existingPlateIndex] = {
          ...updatedPlates[existingPlateIndex],
          plateCount: newCount,
        }
        return updatedPlates
      }
    } else {
      if (newCount > 0) {
        return updatedPlates.concat({
          deviceId,
          plateName,
          plateCount: newCount,
        })
      }
      return updatedPlates
    }
  }

  const handlePlateCountChange = (
    plateName:
      | PlateKey
      | ZimmerCurvedRodsPlateKey
      | ZimmerTransverseConnectorsPlateKey
      | ZimmerStandardClosureTopsPlateKey,
    newCount: number
  ) => {
    if (plateName.includes('curvedRod')) {
      return
    }

    const plateDI = getPlateDI(plateName as PlateKey)

    const updatedTrayPlates = updateTrayPlates(
      trayPlates,
      plateName,
      newCount,
      plateDI
    )

    setTrayPlates(updatedTrayPlates)
  }

  return {
    confirmChangesModalOpen,
    setConfirmChangesModalOpen,
    isConfirmScrewRemovalModalOpen,
    setIsConfirmScrewRemovalModalOpen,
    trayScrews,
    setTrayScrews,
    screwAreas,
    handleScrewSelection,
    handleStatusChange,
    isScrewLoaded,
    handleCancelSelection,
    findScrew,
    handleConfirmScrewRemovalModalClose,
    handleRemoveScrew,
    handleLoadScrew,
    handleConfirmLoadMultiple,
    handleConfirmScrewsSelection,
    handleCancelScrewsSelection,
    isLoadingWithUDIs,
    setIsLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
    getSelectedJSONFile,
    plateCounts,
    setPlateCounts,
    handlePlateCountChange,
    getInitialPlateCount,
    trayPlates,
    setTrayPlates,
  }
}

export default useTrayMapLogic
