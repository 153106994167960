import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'

/**
 * Returns the appropriate analysis JSON file name based on the provided tray type.
 *
 * @param {TrayType} trayType - The type of tray for which the analysis JSON file name is to be determined.
 * @returns {string} The name of the analysis JSON file corresponding to the specified tray type.
 */
export const getAnalysisJSONFileName = (trayType: TrayType): string => {
  switch (trayType?.toLowerCase()) {
    case 'stryker middle face':
      return 'Stryker_1.7_Middle_Face.json'
    case 'stryker upper face':
      return 'Stryker_1.2_Upper_Face.json'
    case 'zimmer curved rods':
      return 'Zimmer_4.json'
    default:
      return 'Stryker_2.0MP_Screws.json'
  }
}
