import { ImplantStatus } from 'common/types'

export function getReadableImplantStatus(
  status: ImplantStatus
): ImplantStatus | string {
  switch (status) {
    case 'IMPLANTED':
      return 'Implanted'
    case 'WASTED':
      return 'Wasted'
    case 'ASSOCIATED_ASSET':
      return 'Associated Product'
    case 'EXPLANTED':
      return 'Explanted'
    case 'NOT_PRESENT':
      return 'Not Present'
  }
}
