import useZimmerTrayMapLogic from 'lib/hooks/useZimmerTrayMapLogic/useZimmerTrayMapLogic'
import { rodUDIMap } from './GrayScale.types'
import { GrayScaleTrayMapProps } from './GrayScale.types'

const useZimmerRodTrayMapLogic = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
}: GrayScaleTrayMapProps) => {
  return useZimmerTrayMapLogic({
    isInOR,
    setSelectedPlate,
    setModalOpen,
    udiMap: rodUDIMap,
    parLevel: 2,
  })
}

export default useZimmerRodTrayMapLogic
