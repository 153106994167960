/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from 'react'

// Router
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Services
import { useGetPreferenceCardById } from 'lib/services/api/reports-service/preference-card/getPreferenceCardById'
import { useGetSurgeons } from 'lib/services/api/reports-service/preference-card/getSurgeons'
import { useGetProcedureTypes } from 'lib/services/api/reports-service/preference-card/getProcedureTypes'
import { useCreatePrefCard } from 'lib/services/api/reports-service/preference-card/createPreferenceCard'
import { useUpdatePrefCard } from 'lib/services/api/reports-service/preference-card/updatePreferenceCard'
import {
  useGetRepsQuery,
  useSendProductRepInviteMutation,
} from 'lib/apollo/hooks'

// Types
import { CustomChangeEvent, Option, RecordCompanyRep } from 'common/types'
import {
  ConsumablesProps,
  EquipmentProps,
  InputCategory,
  InstrumentTraysProps,
  PreferenceCardProps,
  ProcedureDetails,
  ProductsInputs,
  SalesRepsOption,
  SurgeonInformation,
  MedicationTypes,
  Medication,
  ISalesRepModal,
} from './CardView.types'
import { PositionChangeProps } from '../PreferenceCards.types'

// Other
import { debounce } from 'lodash'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { enqueueSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
const useCardViewLogic = ({ mode }: { mode: 'view' | 'create' }) => {
  const navigate = useNavigate()

  const { id } = useParams()
  const { state } = useLocation()
  const isViewMode = mode === 'view'
  const isCreateMode = mode === 'create'
  const isMobile = useIsMobile()
  const DELAY = 1000

  // States
  const [preferenceCard, setPreferenceCard] = useState<PreferenceCardProps>({
    id: id ?? '',
  })
  const [surgeonInformation, setSurgeonInformation] =
    useState<SurgeonInformation>()
  const [procedureDetails, setProcedureDetails] = useState<ProcedureDetails>({
    tableRotationSide: 'Left',
    medication: {
      lidocaine: {
        concentration: undefined,
        epiConcentration: undefined,
        volume: '',
      },
      lidocaineWithEpi: {
        concentration: undefined,
        epiConcentration: undefined,
        volume: '',
      },
      marcaine: {
        concentration: undefined,
        epiConcentration: undefined,
        volume: '',
      },
      marcaineWithEpi: {
        concentration: undefined,
        epiConcentration: undefined,
        volume: '',
      },
      other: '',
    },
  })
  const [equipmentItems, setEquipmentItems] = useState<EquipmentProps>({
    options: [],
    notes: [],
  })
  const [instrumentTrays, setInstrumentTrays] = useState<InstrumentTraysProps>()
  const [consumables, setConsumables] = useState<ConsumablesProps>({
    stapler: false,
  })
  const [linkedSalesReps, setLinkedSalesReps] = useState<SalesRepsOption[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<{
    implants: Option[]
    biologic: Option[]
  }>({
    biologic: [],
    implants: [],
  })
  const [selectedVendor, setSelectedVendor] = useState<Option>()
  const [salesRepModal, setSalesRepModal] = useState<ISalesRepModal>({
    isOpen: false,
    type: 'rep',
    modalId: uuidv4(),
  })

  const isNMRepEnabled = equipmentItems.options[0]?.['Neuromonitoring']
  const isPerfusionistRepEnabled = equipmentItems.options[0]?.['Cell Saver']
  const nMRep = linkedSalesReps.find(
    (item) => item.specialty === 'Neuromonitoring'
  )
  const perfusionistRep = linkedSalesReps.find(
    (item) => item.specialty === 'Perfusionist'
  )

  // Services
  const [sendProductRepInviteMutation, { loading: isLoadingAddNewRep }] =
    useSendProductRepInviteMutation()
  const { data: surgeonsData, isLoading: isLoadingSurgeons } = useGetSurgeons()
  const surgeonsOptions = surgeonsData?.surgeons.map((item) => ({
    id: item.id,
    name: `${item.firstName} ${item.lastName}`,
  }))

  const { data: procedureTypesData, isLoading: isLoadingProcedureTypes } =
    useGetProcedureTypes()
  const procedureTypesOptions = procedureTypesData?.procedures
    ?.filter((item) => item && item !== '-')
    .map((item, index) => ({
      id: index,
      name: item,
    }))

  const { data: currentPrefCard, isLoading: isLoadingCard } =
    useGetPreferenceCardById({
      id: id ?? '',
      enabled: Boolean(id),
    })
  const card_details = currentPrefCard?.card_details

  const { data: rawSalesReps } = useGetRepsQuery({
    bidCompanyId: selectedVendor?.id as number,
  })
  const salesReps =
    (rawSalesReps?.getReps.data
      ?.filter((item) => !linkedSalesReps.find((rep) => rep.id === item.id))
      .map(({ id, firstName, lastName, email, mobile, bidCompanyId }) => ({
        id,
        email,
        firstName,
        lastName,
        phoneNumber: mobile,
        companyId: bidCompanyId,
      })) as RecordCompanyRep[]) || []

  const repsOptions: Option[] =
    rawSalesReps?.getReps.data.map((item) => ({
      name: `${item.lastName}, ${item.firstName}|${item.email}`,
      id: item.id,
    })) || []

  const { mutateAsync: create, isLoading: isCreatingCard } = useCreatePrefCard()
  const { mutateAsync: update, isLoading: isUpdatingCard } = useUpdatePrefCard()

  const groupedRepsByCompany = useMemo(() => {
    const allSelectedCompanies = [
      ...selectedCompanies.implants,
      ...selectedCompanies.biologic,
    ]

    return allSelectedCompanies.reduce((acc, company) => {
      const repsForCompany = linkedSalesReps.filter(
        (rep) => rep.companyId === company.id && !rep.isSpecialtyRep
      )

      if (repsForCompany.length > 0) {
        acc[company.id] = { companyName: company.name, reps: repsForCompany }
      }

      return acc
    }, {} as Record<string, { companyName: string; reps: typeof linkedSalesReps }>)
  }, [selectedCompanies, linkedSalesReps])

  // Handlers
  const handleSurgeonInformationChange = useCallback(
    (field: keyof SurgeonInformation, value: string | string[] | Option) => {
      setSurgeonInformation((prevState) => ({ ...prevState, [field]: value }))
    },
    []
  )

  const handlePositionChange = useCallback(
    (selectedPosition: PositionChangeProps) => {
      setProcedureDetails((prevState) => ({
        ...prevState,
        selectedPosition,
      }))
    },
    []
  )

  const handleInputChange = useCallback(
    (field: keyof ProcedureDetails, value?: string | boolean | string[]) => {
      setProcedureDetails((prevState) => ({ ...prevState, [field]: value }))
    },
    []
  )

  const handleEquipmentItemChange = useCallback(
    (index: number, field: string, value: string | boolean) => {
      setEquipmentItems((prev) => {
        const updatedItems = [...(prev.options || [])]
        if (!updatedItems[index]) {
          updatedItems[index] = {}
        }
        updatedItems[index][field] = value
        return { ...prev, options: updatedItems }
      })
    },
    []
  )

  const handleCheckboxChange = (value: number, checked: boolean) => {
    setConsumables((prev) => {
      let updatedBladesCount = [...(prev.bladesCount || [])]
      if (checked) {
        updatedBladesCount.push(value)
      } else {
        updatedBladesCount = updatedBladesCount.filter(
          (count) => count !== value
        )
      }
      return { ...prev, bladesCount: updatedBladesCount }
    })
  }

  const handleClickBack = useCallback(() => {
    navigate('/preference-cards')
  }, [navigate])

  const handleMedicationsChange = (
    medName: keyof MedicationTypes,
    field: keyof Medication | 'other',
    value: string
  ) => {
    setProcedureDetails((prevState) => {
      const currentMedication = prevState.medication ?? {
        lidocaine: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        lidocaineWithEpi: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        marcaine: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        marcaineWithEpi: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        other: '',
      }

      if (medName === 'other') {
        return {
          ...prevState,
          medication: {
            ...currentMedication,
            other: value,
          },
        }
      }

      return {
        ...prevState,
        medication: {
          ...currentMedication,
          [medName]: {
            ...currentMedication[medName],
            [field]: value,
          },
        },
      }
    })
  }

  const resetMedication = (medName: keyof MedicationTypes) => {
    setProcedureDetails((prevState) => {
      const currentMedication = prevState.medication ?? {
        lidocaine: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        lidocaineWithEpi: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        marcaine: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        marcaineWithEpi: {
          concentration: undefined,
          epiConcentration: undefined,
          volume: '',
        },
        other: '',
      }

      if (medName === 'other') {
        return {
          ...prevState,
          medication: {
            ...currentMedication,
            other: '',
          },
        }
      }

      // Reset fields for the selected medication
      return {
        ...prevState,
        medication: {
          ...currentMedication,
          [medName]: {
            concentration: undefined,
            epiConcentration: undefined,
            volume: '',
          },
        },
      }
    })
  }

  const handleDeleteCompany = (type: InputCategory, item: Option) => {
    setSelectedCompanies((prevCompanies) => {
      if (type === 'biologic') {
        const updatedBiologicCompanies = prevCompanies.biologic.filter(
          (company) => company.id !== item.id
        )

        return {
          ...prevCompanies,
          biologic: updatedBiologicCompanies,
        }
      }

      if (type === 'implants') {
        const updatedImplantCompanies = prevCompanies.implants.filter(
          (company) => company.id !== item.id
        )

        return {
          ...prevCompanies,
          implants: updatedImplantCompanies,
        }
      }

      return prevCompanies // Fallback in case the type doesn't match
    })
  }

  const handleAddCompanies = (type: InputCategory, item: Option) => {
    if (!item) return

    setSelectedCompanies((prevCompanies) => {
      if (type === 'biologic') {
        const isUniqueCompany = !prevCompanies.biologic.some(
          (company) => company.id === item.id
        )

        return {
          ...prevCompanies,
          biologic: isUniqueCompany
            ? [...prevCompanies.biologic, item]
            : prevCompanies.biologic,
        }
      }

      if (type === 'implants') {
        const isUniqueCompany = !prevCompanies.implants.some(
          (company) => company.id === item.id
        )

        return {
          ...prevCompanies,
          implants: isUniqueCompany
            ? [...prevCompanies.implants, item]
            : prevCompanies.implants,
        }
      }

      return prevCompanies
    })
  }

  const handleDeleteSalesRep = (item: SalesRepsOption) => {
    setLinkedSalesReps((prev) => prev.filter((rep) => rep.email !== item.email))
  }

  const handleNotifySalesRep = (
    email: string,
    bool: boolean,
    type: 'EMAIL' | 'SMS'
  ) => {
    setLinkedSalesReps((prev) =>
      prev.map((rep) => {
        if (rep.email === email) {
          return {
            ...rep,
            sendEmail: type === 'EMAIL' ? bool : rep.sendEmail,
            sendSms: type === 'SMS' ? bool : rep.sendSms,
          }
        }
        return rep
      })
    )
  }

  const handleAddSalesRep = (e: CustomChangeEvent) => {
    if (!e.target.value) return
    const rep = e.target.value as Option
    const selectedRep = salesReps.find((item) => item.id === rep.id)
    if (!selectedRep) return

    setLinkedSalesReps((prev) => {
      const newSalesRep = {
        ...({
          ...selectedRep,
          sendEmail: true,
          sendSms: true,
          name: `${selectedRep.firstName} ${selectedRep.lastName}`,
        } as SalesRepsOption),
      }
      const isDuplicate = prev.some((rep) => rep.id === newSalesRep.id)

      if (isDuplicate) return prev

      return [...prev, newSalesRep]
    })
  }

  const handleAddNewSalesRep = (newRep: SalesRepsOption) => {
    if (salesRepModal.type === 'rep') {
      const {
        email,
        companyId,
        phoneNumber,
        companyName,
        firstName,
        lastName,
      } = newRep
      sendProductRepInviteMutation({
        variables: {
          bidCompanyId: companyId as number,
          email,
          firstName,
          lastName,
          mobile: phoneNumber as string,
          accessLevel: 1,
          companyName: companyName as string,
        },
      })
        .then((res) => {
          if (res.data?.sendProductRepInvite.success) {
            setLinkedSalesReps((prev) => [...prev, newRep])
            setSalesRepModal({ isOpen: false, modalId: uuidv4() })
            enqueueSnackbar('Invitation sent successfully to sales rep.', {
              variant: 'success',
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(
            'Something wrong happened while inviting the sales rep.',
            { variant: 'error' }
          )
        })
    } else {
      setLinkedSalesReps((prev) => {
        const isDuplicate = prev.some((rep) => rep.email === newRep.email)

        if (isDuplicate) {
          enqueueSnackbar('This email already exists.', { variant: 'error' })
          return prev
        }

        return [...prev, newRep]
      })
      setSalesRepModal({ isOpen: false, modalId: uuidv4() })
    }
  }

  // Retrieve the current preference card logic
  useEffect(() => {
    if (currentPrefCard) {
      setSurgeonInformation({
        ...card_details?.surgeonInformation,
        physicianName: surgeonsOptions?.find(
          (item) => item.id === currentPrefCard.surgeon.id
        ),
        procedureName: currentPrefCard.procedure_type,
      })
      setProcedureDetails(card_details?.procedureDetails ?? {})
      setEquipmentItems(card_details?.equipment ?? { options: [] })
      setInstrumentTrays(card_details?.instrumentTrays ?? {})
      setConsumables(card_details?.consumables ?? {})
      setLinkedSalesReps(card_details?.linkedSalesReps ?? [])
      setSelectedCompanies({
        biologic: card_details?.companies?.biologic || [],
        implants: card_details?.companies?.implants || [],
      })
    }
  }, [currentPrefCard, currentPrefCard])

  // Retrieve the surgeon information from state
  // Create new pref card from procedures list logic
  useEffect(() => {
    if (state && isCreateMode) {
      const { surgeonId, procedureType } = state as {
        surgeonId: string
        procedureType: string
      }
      const surgeon = surgeonsOptions?.find((item) => item.id === surgeonId)
      setSurgeonInformation({
        physicianName: surgeon,
        procedureName: procedureType,
      })
    }
  }, [state, surgeonsData])

  // Updating logic
  const savePreferenceCards = debounce((data: PreferenceCardProps) => {
    if (isViewMode) {
      update({
        id: id as string,
        card_details: data,
      }).catch((error) => {
        console.error('ERROR', error)
      })
    }
  }, DELAY)

  const debouncedSavePreferenceCards = debounce(savePreferenceCards, DELAY)
  useEffect(() => {
    const updatedPreferenceCard: PreferenceCardProps = {
      id: id ?? '',
      surgeonInformation,
      procedureDetails,
      equipment: equipmentItems,
      instrumentTrays,
      consumables,
      linkedSalesReps,
      companies: selectedCompanies,
    }
    setPreferenceCard(updatedPreferenceCard)
    debouncedSavePreferenceCards(updatedPreferenceCard)

    return () => {
      debouncedSavePreferenceCards.cancel()
    }
  }, [
    id,
    surgeonInformation,
    procedureDetails,
    equipmentItems,
    instrumentTrays,
    consumables,
    linkedSalesReps,
    selectedCompanies,
  ])

  // Creating logic
  const createPreferenceCard = () => {
    const data: PreferenceCardProps = {
      id: id ?? '',
      surgeonInformation,
      procedureDetails,
      equipment: equipmentItems,
      instrumentTrays,
      consumables,
      linkedSalesReps,
    }
    create({
      surgeon_id: surgeonInformation?.physicianName?.id as string,
      procedure_type: surgeonInformation?.procedureName ?? '',
      card_details: data,
    })
      .then(() => {
        navigate('/preference-cards')
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.error, {
          variant: 'error',
        })
      })
  }

  return {
    nMRep,
    canSave:
      surgeonInformation?.physicianName?.id &&
      surgeonInformation?.procedureName,
    isMobile,
    consumables,
    isLoading: isCreatingCard || isUpdatingCard || isLoadingCard,
    medications: procedureDetails.medication,
    repsOptions,
    isLoadingCard,
    salesRepModal,
    isNMRepEnabled,
    preferenceCard,
    equipmentItems,
    isCreatingCard,
    selectedVendor,
    isUpdatingCard,
    surgeonsOptions,
    perfusionistRep,
    instrumentTrays,
    procedureDetails,
    selectedCompanies,
    isLoadingSurgeons,
    surgeonInformation,
    isLoadingAddNewRep,
    groupedRepsByCompany,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    isPerfusionistRepEnabled,
    setConsumables,
    handleClickBack,
    resetMedication,
    setSalesRepModal,
    handleAddCompanies,
    setSelectedVendor,
    handleAddSalesRep,
    handleInputChange,
    setEquipmentItems,
    setInstrumentTrays,
    handleDeleteCompany,
    handlePositionChange,
    handleNotifySalesRep,
    handleAddNewSalesRep,
    createPreferenceCard,
    handleCheckboxChange,
    handleDeleteSalesRep,
    handleMedicationsChange,
    handleEquipmentItemChange,
    handleSurgeonInformationChange,
  }
}
export default useCardViewLogic
