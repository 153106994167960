import { FC } from 'react'

// Components
import Select from 'components/molecules/Select/Select'
import Button from 'components/molecules/Button/Button'
import TextInput from 'components/molecules/TextInput/TextInput'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { IconButton, Typography } from '@mui/material'
import { DateInput } from 'components/mui'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// Utils
import dayjs from 'lib/dayjs'

// Logic
import useAddProcedureLogic from './AddProcedure.logic'

// Hook form
import { Controller } from 'react-hook-form'

// Style
import './AddProcedure.scss'

const AddProcedure: FC = () => {
  const {
    errors,
    control,
    patients,
    surgeons,
    isLoading,
    locations,
    procedures,
    isLoadingPatients,
    isLoadingSurgeons,
    isLoadingProcedures,
    isLoadingCreateSurgery,
    onSubmit,
    handleSubmit,
    handleNavigateHome,
  } = useAddProcedureLogic()

  return (
    <PageDisplay>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-procedure-container">
          <div className="header">
            <IconButton onClick={handleNavigateHome}>
              <KeyboardBackspaceIcon className="back-icon" color="primary" />
            </IconButton>
            <Typography variant="h2">Manually Add Procedure</Typography>
            <div />
          </div>

          <Typography variant="h3">Patient Information</Typography>
          <div className="section">
            <Controller
              name="patient"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Patient name cannot be empty',
                },
              }}
              render={({ field: { onChange, ...field } }) => (
                <AutocompleteInput
                  disabled={isLoadingCreateSurgery}
                  {...field}
                  required={false}
                  variant="outlined"
                  handleChangeFormData={onChange}
                  placeholder="Patient Name"
                  label="Patient Name*"
                  options={patients}
                  error={!!errors.patient}
                  isLoading={isLoadingPatients}
                  helperText={errors.patient?.message}
                  shrink={true}
                />
              )}
            />

            <Controller
              name="patientDob"
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value, ...field } }) => (
                <DateInput
                  readOnly
                  openTo="month"
                  disabled
                  value={value}
                  handleChangeFormData={onChange}
                  format="MM/DD/YYYY"
                  label="Patient DOB"
                  variant="outlined"
                  error={!!errors.patientDob}
                  helperText={errors.patientDob?.message}
                  fullWidth
                  {...field}
                />
              )}
            />

            <Controller
              name="mrNumber"
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value, ...field } }) => (
                <TextInput
                  {...field}
                  disabled
                  value={value}
                  handleChangeFormData={onChange}
                  variant="outlined"
                  placeholder="MR#"
                  label="MR#"
                  error={!!errors.mrNumber}
                  helperText={errors.mrNumber?.message}
                  shrink
                />
              )}
            />
          </div>

          <Typography variant="h3">Case Information</Typography>
          <div className="section">
            <Controller
              name="surgeon"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Surgeon name cannot be empty',
                },
              }}
              render={({ field: { onChange, ...field } }) => (
                <AutocompleteInput
                  {...field}
                  required={false}
                  variant="outlined"
                  handleChangeFormData={onChange}
                  label="Surgeon Name*"
                  placeholder="Surgeon Name"
                  options={surgeons}
                  error={!!errors.surgeon}
                  helperText={errors.surgeon?.message as string}
                  isLoading={isLoadingSurgeons}
                  disabled={isLoadingCreateSurgery}
                />
              )}
            />

            <Controller
              name="procedure"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Procedure name cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <Select
                  {...field}
                  onSelect={onChange}
                  label="Procedure Name*"
                  placeholder="Procedure Name"
                  options={procedures}
                  error={!!errors.procedure}
                  helperText={errors.procedure?.message as string}
                  isLoading={isLoadingProcedures}
                  disabled={isLoadingCreateSurgery}
                />
              )}
            />

            <Controller
              name="procedureLocation"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Procedure location cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <Select
                  {...field}
                  onSelect={onChange}
                  label="Procedure Location*"
                  placeholder="Procedure Location"
                  options={locations}
                  isLoading={isLoading}
                  error={!!errors.procedureLocation}
                  helperText={errors.procedureLocation?.message as string}
                  disabled={isLoadingCreateSurgery}
                />
              )}
            />

            <Controller
              name="dateOfProcedure"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Procedure date cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <DateInput
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  readOnly
                  openTo="month"
                  handleChangeFormData={onChange}
                  label="Date of Procedure*"
                  value={value}
                  error={!!errors.dateOfProcedure}
                  variant="outlined"
                  helperText={errors.dateOfProcedure?.message}
                  fullWidth
                  disabled={isLoadingCreateSurgery}
                  {...field}
                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="timeOfProcedure"
                control={control}
                defaultValue={null}
                render={({ field }) => {
                  const { onChange, value, ref, ...rest } = field
                  const valueAsDayjs = value ? dayjs(value) : null

                  return (
                    <TimePicker
                      label="Time of Procedure"
                      value={valueAsDayjs}
                      disabled={isLoadingCreateSurgery}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        onChange(newValue ? newValue.toDate() : null)
                      }}
                      {...rest}
                    />
                  )
                }}
              />
            </LocalizationProvider>
          </div>

          <Typography variant="h3">Optional Information</Typography>
          <div className="section">
            <Controller
              name="operatingRoomNumber"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <TextInput
                  {...field}
                  shrink
                  value={value}
                  handleChangeFormData={onChange}
                  variant="outlined"
                  placeholder="Operating Room Number"
                  label="Operating Room Number"
                  type="number"
                  error={!!errors.operatingRoomNumber}
                  helperText={errors.operatingRoomNumber?.message}
                  disabled={isLoadingCreateSurgery}
                />
              )}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            isLoading={isLoadingCreateSurgery}
          >
            Add To Procedure List
          </Button>
        </div>
      </form>
    </PageDisplay>
  )
}

export default AddProcedure
