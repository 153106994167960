import {
  useAddReferringPhysician,
  useEditReferringPhysician,
} from 'lib/apollo/hooks'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  physicianDetailsFormSchema,
  TNewReferringPhysicianState,
} from './NewReferringPhysician.types'
import {
  formatUSPhoneNumber,
  formatUSPhoneNumberForSubmission,
  getZodError,
} from 'common/utils'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { enqueueSnackbar } from 'notistack'
import { REFERRING_PHYSICIANS } from 'app/ROUTES_CONSTANTS'
import { CustomChangeEvent, ReferringPhysician } from 'common/types'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'

export const SPECIALITY = [
  'Plastics',
  'Orthopedics',
  'Neurosurgery',
  'General Surgery',
  'GI',
  'Dental',
  'Podiatry',
  'ENT/HNS',
  'Urology',
  'Vascular',
  'OMFS',
  'Cardiac',
  'IR',
].sort()

export const useNewReferringPhysicianLogic = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const state = useLocation().state as TNewReferringPhysicianState
  const { firstName, lastName, email, edit, speciality, mobile, _id } = state
  const { showPhysiciansSpeciality, physiciansTitle, showPhysiciansMobile } =
    useOrganizationSettings()

  const [formData, setFormData] = useState<ReferringPhysician>({
    _id: _id,
    firstName: firstName,
    lastName: lastName,
    email: email,
    confirmEmail: email,
    speciality: speciality,
    mobile: mobile,
  })

  const [formErrors, setFormErrors] = useState<Record<string, string>>()

  const [editReferringPhysician, { loading: editLoading }] =
    useEditReferringPhysician()

  const [addReferringPhysician, { loading: addLoading }] =
    useAddReferringPhysician()

  const isLoading = edit ? editLoading : addLoading
  const headerTitle = `${physiciansTitle} / ${
    edit
      ? `Edit ${!isMobile ? 'Physician' : ''}`
      : `Add ${!isMobile ? 'Physician' : ''}`
  }`
  const successAlertTitle = `${physiciansTitle} was ${
    edit ? 'updated' : 'created'
  } successfully`

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = physicianDetailsFormSchema.safeParse(formData)
    if (!result.success || (showPhysiciansSpeciality && !formData.speciality)) {
      let errors: Record<string, string> = {}
      if (!result.success) {
        errors = getZodError(result.error)
      }
      if (showPhysiciansSpeciality && !formData.speciality) {
        errors.speciality = 'Speciality cannot be empty'
      }
      setFormErrors(errors)
      return
    }

    const { confirmEmail, mobile, ...restFormData } = formData

    edit
      ? editReferringPhysician({
          variables: {
            physician: {
              ...restFormData,
              mobile: mobile ? formatUSPhoneNumberForSubmission(mobile) : '',
            },
          },
        }).then((response) => {
          if (response.data?.editPhysician) {
            enqueueSnackbar(successAlertTitle, { variant: 'success' })
          }
          navigate(`/${REFERRING_PHYSICIANS}`)
        })
      : addReferringPhysician({
          variables: {
            physician: {
              ...restFormData,
              ...(mobile && {
                mobile: formatUSPhoneNumberForSubmission(mobile),
              }),
            },
          },
        })
          .then((response) => {
            if (response.data?.createPhysician) {
              enqueueSnackbar(successAlertTitle, { variant: 'success' })
            }
            navigate(`/${REFERRING_PHYSICIANS}`)
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' })
          })
  }

  const handleChangeUpdatedPhysicianDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | CustomChangeEvent
  ) => {
    if (e.target.name === 'mobile' && typeof e.target.value === 'string') {
      e.target.value = formatUSPhoneNumber(e.target.value.replace(/\D/g, ''))
    }
    setFormErrors((prev) => ({ ...prev, [e.target.name]: '' }))
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return {
    formData,
    isLoading,
    formErrors,
    headerTitle,
    showPhysiciansMobile,
    showPhysiciansSpeciality,
    navigate,
    handleFormSubmit,
    handleChangeUpdatedPhysicianDetails,
  }
}
