import { GET_LOCATIONS_TREE } from 'lib/services/api/schema'
import { useQuery, QueryResult } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import { GetLocationsTreeResponse } from './types'
import { InventoryProviderClient } from 'lib/apollo/inventory-config'

const useGetLocationsTree = (): QueryResult<
  GetLocationsTreeResponse,
  Error
> => {
  const client = InventoryProviderClient()
  return useQuery(GET_LOCATIONS_TREE, {
    client,
    onError: (error: Error) => {
      captureWarning(error)
    },
  })
}

export default useGetLocationsTree
