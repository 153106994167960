import './Review.scss'
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Modal,
  Box,
} from '@mui/material'
import { LoadingButton } from 'components/mui'
import useReviewLogic from './Review.logic'
import { StickerPrintContainer } from 'components/organisms/TraySticker/TraySticker'
import { AddTrayDetailsConfirmation } from '../AddTrayDetails/AddTrayDetailsConfirmation'

const LogoutModal = ({
  open,
  onClose,
  onAddMore,
  onLogout,
}: {
  open: boolean
  onClose: () => void
  onAddMore: () => void
  onLogout: () => void
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="logout-confirmation"
    className="custom-modal"
  >
    <Box className="parent-modal-box">
      <Box className="header-box">
        <Typography variant="h6">Add Another Tray?</Typography>
      </Box>
      <Box className="child-box" sx={{ my: 1 }}>
        <Typography variant="body1">
          Would you like to add another tray? If not, you will be logged out in
          15 seconds.
        </Typography>
      </Box>
      <Box className="button-box" sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={onLogout}>Logout</Button>
        <Button variant="contained" onClick={onAddMore} autoFocus>
          Add Another Tray
        </Button>
      </Box>
    </Box>
  </Modal>
)

const Review = () => {
  const {
    trayDetails,
    handleBack,
    handleDone,
    handleAddAnother,
    isCreatingTrayItem,
    showLogoutModal,
    handleModalClose,
    handleLogout,
    handlePrintLabel,
  } = useReviewLogic()

  return (
    <>
      <div className="review-step">
        <Paper className="review-content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Tray ID</Typography>
              <Typography>{trayDetails.trayBarcode}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Location</Typography>
              <Typography>{trayDetails.locationName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Vendor</Typography>
              <Typography>{trayDetails.trayVendor}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Description</Typography>
              <Typography>{trayDetails.trayDescription}</Typography>
            </Grid>

            {trayDetails.trayWeight && (
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Weight</Typography>
                <Typography>{trayDetails.trayWeight} lbs</Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Category</Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>
                {trayDetails.trayCategory}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Sub Trays <strong>({trayDetails.subTrays.length})</strong>
              </Typography>
              {trayDetails.subTrays.map((subTray, index) => (
                <div key={index} className="sub-tray-item">
                  <img src={subTray.trayImg} alt={`Sub Tray ${index + 1}`} />
                  <div>
                    <Typography
                      variant="body2"
                      textTransform="capitalize"
                      color="text.secondary"
                    >
                      Type:{' '}
                      <span className="sub-tray-item_description">
                        {subTray.trayType}
                      </span>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Par Level:{' '}
                      <span className="sub-tray-item_description">
                        {subTray.parLevel}
                      </span>
                    </Typography>
                  </div>
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                Inventory Sheets{' '}
                <strong>({trayDetails.inventorySheets.length})</strong>
              </Typography>
              <div className="inventory-sheets-grid">
                {trayDetails.inventorySheets.map((sheet, index) => (
                  <div key={index} className="inventory-sheet-item">
                    {sheet.mimeType.includes('image') ? (
                      <img
                        src={sheet.src}
                        alt={`Inventory Sheet ${index + 1}`}
                      />
                    ) : (
                      <a
                        href={sheet.src}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className="buttons_container">
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant="outlined" onClick={handlePrintLabel}>
          Print Label
        </Button>
        <LoadingButton
          loading={isCreatingTrayItem}
          onClick={handleAddAnother}
          variant="contained"
        >
          Submit & Add Another Tray
        </LoadingButton>
        <LoadingButton
          loading={isCreatingTrayItem}
          onClick={handleDone}
          variant="contained"
        >
          Submit Tray
        </LoadingButton>
      </div>

      <LogoutModal
        open={showLogoutModal}
        onClose={handleModalClose}
        onAddMore={handleAddAnother}
        onLogout={handleLogout}
      />

      <StickerPrintContainer />
    </>
  )
}

export default Review
