import { Dispatch, SetStateAction } from 'react'
import { TrayPlate } from 'views/SPDLayout/SPD/SPD.types'

export interface ZimmerRodTrayMapProps {
  isInOR?: boolean
  setSelectedPlate?: Dispatch<SetStateAction<TrayPlate | null>>
  setModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const rodUDIMap = {
  curvedRod80mm: '00888480211327',
  curvedRod80mmx2: '00888480211327',
  curvedRod75mm: '00888480211310',
  curvedRod75mmx2: '00888480211310',
  curvedRod70mm: '00880304919747',
  curvedRod70mmx2: '00880304919747',
  curvedRod65mm: '00880304919754',
  curvedRod65mmx2: '00880304919754',
  curvedRod60mm: '00880304919761',
  curvedRod60mmx2: '00880304919761',
  curvedRod55mm: '00888480211273',
  curvedRod55mmx2: '00888480211273',
  curvedRod50mm: '00880304919785',
  curvedRod50mmx2: '00880304919785',
  curvedRod45mm: '00888480211259',
  curvedRod45mmx2: '00888480211259',
  curvedRod40mm: '00880324003934',
  curvedRod40mmx2: '00880324003934',
  curvedRod35mm: '00888480211235',
  curvedRod35mmx2: '00888480211235',
  curvedRod30mm: '00880304919822',
  curvedRod30mmx2: '00880304919822',
} as const

export const rodDescriptionMap = {
  curvedRod80mm: '80mm TI Alloy Curved Rod',
  curvedRod80mmx2: '80mm TI Alloy Curved Rod',
  curvedRod75mm: '75mm TI Alloy Curved Rod',
  curvedRod75mmx2: '75mm TI Alloy Curved Rod',
  curvedRod70mm: '70mm TI Alloy Curved Rod',
  curvedRod70mmx2: '70mm TI Alloy Curved Rod',
  curvedRod65mm: '65mm TI Alloy Curved Rod',
  curvedRod65mmx2: '65mm TI Alloy Curved Rod',
  curvedRod60mm: '60mm TI Alloy Curved Rod',
  curvedRod60mmx2: '60mm TI Alloy Curved Rod',
  curvedRod55mm: '55mm TI Alloy Curved Rod',
  curvedRod55mmx2: '55mm TI Alloy Curved Rod',
  curvedRod50mm: '50mm TI Alloy Curved Rod',
  curvedRod50mmx2: '50mm TI Alloy Curved Rod',
  curvedRod45mm: '45mm TI Alloy Curved Rod',
  curvedRod45mmx2: '45mm TI Alloy Curved Rod',
  curvedRod40mm: '40mm TI Alloy Curved Rod',
  curvedRod40mmx2: '40mm TI Alloy Curved Rod',
  curvedRod35mm: '35mm TI Alloy Curved Rod',
  curvedRod35mmx2: '35mm TI Alloy Curved Rod',
  curvedRod30mm: '30mm TI Alloy Curved Rod',
  curvedRod30mmx2: '30mm TI Alloy Curved Rod',
} as const

export type ZimmerCurvedRodsPlateKey = keyof typeof rodUDIMap
