import { TScrubTechnician } from 'common/types'
import { z } from 'zod'

export type TNewScrubTechnicianState = TScrubTechnician & {
  edit: boolean
}

export const scrubTechnicianDetailsFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'Scrub technician first name cannot be empty' })
      .regex(/^\S*$/, {
        message: 'Scrub technician  first name cannot contain spaces',
      }),
    lastName: z
      .string()
      .min(1, { message: 'Scrub technician last name cannot be empty' })
      .regex(/^\S*$/, {
        message: 'Scrub technician last name cannot contain spaces',
      }),
    email: z.string().email({ message: 'Invalid email address' }),
    confirmEmail: z.string().email({ message: 'Invalid email address' }),
    speciality: z.enum(['CST', 'RN', 'ST'], {
      errorMap: () => ({
        message: 'Speciality must be either CST, RN or ST',
      }),
    }),
  })
  .refine(
    (data) => data.email.toLowerCase() === data.confirmEmail.toLowerCase(),
    {
      path: ['confirmEmail'],
      message: 'Emails must match',
    }
  )
