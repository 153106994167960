import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import {
  updateTrayPlates,
  getInitialRodCount,
  shouldPlateIncreaseBeDisabled,
} from 'utils/trayMapUtils'
import { TrayPlate } from 'views/SPDLayout/SPD/SPD.types'
import { UseZimmerTrayMapLogicProps } from './UseZimmerTrayMapLogic.types'

const useZimmerTrayMapLogic = ({
  isInOR = false,
  setSelectedPlate,
  setModalOpen,
  udiMap,
  parLevel,
}: UseZimmerTrayMapLogicProps) => {
  const { trayPlates, setTrayPlates } = useSPD()

  const setInitialTrayPlates = (initialPlates: TrayPlate[]) => {
    setTrayPlates(initialPlates)
  }

  const getPlateDI = (key: string): string => {
    return udiMap[key]
  }

  const handlePlateCountChange = (
    plateName: TrayPlate['plateName'],
    newCount: number
  ) => {
    if (isInOR) {
      const plateMatch = trayPlates.find(
        (trayPlate) => trayPlate.plateName === plateName
      )
      if (
        plateMatch &&
        plateMatch.plateCount > 0 &&
        newCount < plateMatch.plateCount
      ) {
        setSelectedPlate?.(plateMatch)
        setModalOpen?.(true)
      }
    }

    const plateDI = getPlateDI(plateName)

    const updatedTrayPlates = updateTrayPlates(
      trayPlates,
      plateName,
      newCount,
      plateDI
    )

    setTrayPlates(updatedTrayPlates)
  }

  return {
    handlePlateCountChange,
    setInitialTrayPlates,
    trayPlates,
    getPlateDI,
    shouldPlateIncreaseBeDisabled: (plateKey: string) =>
      shouldPlateIncreaseBeDisabled(trayPlates, plateKey, parLevel),
    getInitialRodCount: (plateName: string) =>
      getInitialRodCount(trayPlates, plateName),
  }
}

export default useZimmerTrayMapLogic
