import { ArrowForwardIos } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import classNames from 'classnames'

import useTrayManagementFilterLogic from './TrayManagementFilter.logic'

import { TrayManagementFilterProps } from './TrayManagementFilter.types'
import './TrayManagementFilter.scss'

const TrayManagementFilter: React.FC<TrayManagementFilterProps> = ({
  filter,
}) => {
  const { isOpen, handleClick, isSubfilterActive, handleSubFilterClick } =
    useTrayManagementFilterLogic({ filter })

  return (
    <div className="tray-management-filter_container">
      <div
        className={classNames('main-filter', { active: isOpen })}
        onClick={handleClick}
      >
        <ArrowForwardIos
          className={classNames('arrow_icon', { rotated: isOpen })}
        />

        <Typography variant="body1" className="title">
          {filter.title}
        </Typography>
      </div>

      {isOpen && (
        <div className="subfilters_container">
          {filter.subFilters?.map((subFilter) => (
            <FormControlLabel
              key={subFilter.filterValue}
              control={
                <Checkbox
                  checked={isSubfilterActive(subFilter.filterValue)}
                  onChange={() => handleSubFilterClick(subFilter.filterValue)}
                />
              }
              label={subFilter.title}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default TrayManagementFilter
