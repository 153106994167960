// Components
import { Typography } from '@mui/material'
import LandingIcon from './LandingIcon/LandingIcon'

// Types
import { MainIconFlowState } from 'common/types'

// Logic
import { useMainIconLanding } from './MainIconLanding.logic'

// Styles
import classNames from 'classnames'
import './MainIconLanding.scss'

// Other
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'

const MainIconLanding = () => {
  const { handleChangeAssetType, icons, isMobile } = useMainIconLanding()
  const [twoColumnsClass, setTwoColumnsClass] = useState<string>('')

  const visibleIconsNumber =
    Object.keys(icons).length -
    Object.values(icons).filter((icon) => icon.isVisible === false).length

  useEffect(() => {
    switch (visibleIconsNumber) {
      case 4:
        setTwoColumnsClass('two-columns')
        break
      default:
        setTwoColumnsClass('')
        break
    }
  }, [visibleIconsNumber])

  return (
    <div
      className={classNames('main-icon-landing_wrapper', {
        'is-mobile': isMobile,
      })}
    >
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Typography variant="h1" align="center">
        Select Product Type
      </Typography>

      <div className={`icons-container ${twoColumnsClass}`}>
        {Object.entries(icons).map(
          ([key, { IconComponent, label, dataTestId, isVisible }]) => {
            return isVisible === false ? null : (
              <LandingIcon
                key={key}
                isMobile={isMobile}
                onClick={() =>
                  handleChangeAssetType(
                    label?.toUpperCase() as MainIconFlowState['assetTypeLabel']
                  )
                }
                icon={IconComponent}
                label={label}
                dataTestId={dataTestId}
              />
            )
          }
        )}
      </div>
    </div>
  )
}

export default MainIconLanding
