import { useAuth0 } from 'app/Auth'
import axios from 'axios'
import { useMutation } from 'react-query'
import { CreateLaborCostParams, LaborCostActionResponse } from '../types'

export const useCreateLaborCost = () => {
  const { getAccessToken } = useAuth0()
  const LABOR_COST_URL = process.env.REACT_APP_REPORTS_URL

  return useMutation<LaborCostActionResponse, Error, CreateLaborCostParams>(
    async (data: CreateLaborCostParams) => {
      const token = await getAccessToken()
      try {
        const response = await axios.post(
          `${LABOR_COST_URL}api/v1/labor_cost`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data
      } catch (error) {
        throw error
      }
    }
  )
}
