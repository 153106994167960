import React, { createContext, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useGeneratePresignedUrlMutation } from 'lib/apollo/hooks'
import { uploadImageToS3 } from 'lib/utils/uploadToS3'
import useSubmitTrayCapture from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitTrayCapture.logic'

import {
  InventorySheetFile,
  InventorySheetsContextProps,
  MimeType,
  mimeToExtensionMap,
} from './InventorySheets.types'

const InventorySheetsContext = createContext<
  InventorySheetsContextProps | undefined
>(undefined)

export const InventorySheetsProvider: React.FC = ({ children }) => {
  const { generatePresignedUrl } = useGeneratePresignedUrlMutation()
  const { base64ToFile } = useSubmitTrayCapture()

  const [inventorySheetFiles, setInventorySheetFiles] = useState<
    InventorySheetFile[]
  >([])

  /**
   * Checks if any file is currently uploading.
   */
  const isThereAnImageUploading = inventorySheetFiles.some(
    (inventorySheetFile) => inventorySheetFile.isUploading
  )

  /**
   * Adds a new file to the inventory sheets.
   *
   * This function generates a unique ID for the file, creates a new file object,
   * updates the state with the new file, and initiates the file upload process.
   *
   * @param {string} file - The file to be added to the inventory sheets.
   * @param {MimeType} mimeType - The MIME type of the file.
   */
  const addFileToInventorySheets = (file: string, mimeType: MimeType) => {
    const newFileId: string = uuidv4()
    const newFile: InventorySheetFile = {
      id: newFileId,
      src: file,
      isUploaded: false,
      isUploading: true,
      mimeType,
    }

    setInventorySheetFiles((prev) => [...prev, newFile])
    uploadFile(newFileId, file, mimeType)
  }

  /**
   * Updates the state of inventory sheet files with the provided URL and uploading status.
   * @param {string} id - The ID of the file to update.
   * @param {string | undefined} s3Url - The URL of the uploaded file, or undefined if the upload failed.
   * @param {boolean} isUploading - The uploading status of the file.
   */
  const updateInventorySheetFile = (
    id: string,
    s3Url: string | undefined,
    isUploading: boolean
  ) => {
    setInventorySheetFiles((prev) =>
      prev.map((img) =>
        img.id === id
          ? { ...img, src: s3Url || img.src, isUploaded: !!s3Url, isUploading }
          : img
      )
    )
  }

  /**
   * Handles the result of an inventory sheet file upload.
   * @param {string} id - The ID of the file to update.
   * @param {string | undefined} s3Url - The URL of the uploaded file, or undefined if the upload failed.
   */
  const handleInventorySheetFileUpload = (
    id: string,
    s3Url: string | undefined
  ) => {
    if (s3Url) {
      updateInventorySheetFile(id, s3Url, false)
    } else {
      updateInventorySheetFile(id, undefined, false)
    }
  }

  /**
   * Uploads a file to S3 after converting it from a base64 string.
   * @param {string} id - The ID of the file being uploaded.
   * @param {string} base64File - The base64 string representation of the file.
   * @param {MimeType} mimeType - The MIME type of the file.
   * @param {boolean} [isAnInventorySheet=true] - Indicates whether the file is part of an inventory sheet.
   * @returns {Promise<string | undefined>} The URL of the uploaded file, or undefined if the upload failed.
   */
  const uploadFile = async (
    id: string,
    base64File: string,
    mimeType: MimeType,
    isAnInventorySheet: boolean = true
  ): Promise<string | undefined> => {
    try {
      const extension = mimeToExtensionMap[mimeType]
      const key = `${uuidv4()}.${extension}`
      const presignedUrl = await generatePresignedUrl(key)

      if (!presignedUrl) {
        if (isAnInventorySheet) {
          updateInventorySheetFile(id, undefined, false)
        }
        return undefined
      }

      const file = base64ToFile(base64File, key, mimeType)
      const s3Url = await uploadImageToS3(file, presignedUrl, key)

      if (isAnInventorySheet) {
        handleInventorySheetFileUpload(id, s3Url)
      }

      return s3Url
    } catch (error) {
      console.error('Failed to upload file:', error)
      if (isAnInventorySheet) {
        updateInventorySheetFile(id, undefined, false)
      }
      return undefined
    }
  }

  /**
   * Deletes a file from the inventory sheet files list.
   * @param {string} imgId - The ID of the file to delete.
   */
  const handleDeleteImg = (imgId: string) => {
    const newImgs = inventorySheetFiles.filter(
      (inventorySheet) => inventorySheet.id !== imgId
    )

    setInventorySheetFiles(newImgs)
  }

  const resetInventorySheets = () => {
    setInventorySheetFiles([])
  }

  return (
    <InventorySheetsContext.Provider
      value={{
        inventorySheetFiles,
        setInventorySheetFiles,
        addFileToInventorySheets,
        handleDeleteImg,
        isThereAnImageUploading,
        uploadFile,
        resetInventorySheets,
      }}
    >
      {children}
    </InventorySheetsContext.Provider>
  )
}

/**
 * Custom hook to access the InventorySheetsContext.
 * @returns {InventorySheetsContextProps} The context value of the inventory sheets.
 */
export const useInventorySheetsContext = (): InventorySheetsContextProps => {
  const context = useContext(InventorySheetsContext)
  if (!context) {
    throw new Error(
      'useInventorySheetsContext must be used within an InventorySheetsProvider'
    )
  }
  return context
}
