/**
 * Formats a string to title case, capitalizing the first letter of each word.
 *
 * @param str - The string to format.
 * @returns A new string where the first letter of each word is capitalized.
 *
 * @example
 * formatToTitleCase("implantable hardware")
 * // Returns: "Implantable Hardware"
 */
export const formatToTitleCase = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
