import { InputAdornment, TextField } from '@mui/material'
import { Add, Search } from '@mui/icons-material'
import classNames from 'classnames'

import TrayManagementFilter from 'components/molecules/TrayManagementFilter/TrayManagementFilter'
import Select from 'components/molecules/Select/Select'
import { LoadingButton } from 'components/mui'

import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import useTrayManagementLogic from 'views/TrayManagementLayout/TrayManagement/TrayManagement.logic'
import useTrayManagementFiltersLogic from './TrayManagementFilters.logic'

import './TrayManagementFilters.scss'

const TrayManagementFilters = () => {
  const {
    listOfFilters,
    trayIdFilterValue,
    handleTrayIdFilterChange,
    vendorOptions,
    companyFilterValue,
    setCompanyFilterValue,
  } = useTrayManagementFiltersLogic()
  const { handleAddTrayClick } = useTrayManagementLogic()
  const { isFiltersNavbarOpen } = useTrayConfigurationContext()

  return (
    <div
      className={classNames('tray-management-filters_container', {
        open: isFiltersNavbarOpen,
      })}
    >
      <div className="input_container">
        <TextField
          className="tray-id-search"
          autoComplete="off"
          placeholder="Search By Tray Id"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
          type="text"
          value={trayIdFilterValue}
          onChange={handleTrayIdFilterChange}
          variant="standard"
        />

        <Select
          fullWidth
          label="Search By Vendor"
          options={vendorOptions}
          onClear={() => setCompanyFilterValue(undefined)}
          onSelect={(value) => setCompanyFilterValue(value)}
          selectedValue={companyFilterValue?.name}
        />
      </div>

      {listOfFilters.map((filter) => (
        <TrayManagementFilter key={filter.filterValue} filter={filter} />
      ))}

      <LoadingButton
        onClick={handleAddTrayClick}
        customIcon={<Add />}
        variant="text"
        className="add-tray_button"
      >
        Add Tray
      </LoadingButton>
    </div>
  )
}

export default TrayManagementFilters
