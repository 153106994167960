import { MenuItem, TextField, Typography } from '@mui/material'
import { ISelectInput } from './SelectInput.types'
import { makeStyles } from '@material-ui/core/styles'
import { OptionObjectPair, Option } from 'common/types'

function SelectInput({
  id,
  label,
  name,
  disabled = false,
  handleChangeFormData,
  options,
  placeholder = 'Select',
  required = false,
  value = '',
  itemStyles = {},
  sx = {},
  errorMessage,
  shrink = true,
}: ISelectInput) {
  const useStyles = makeStyles({
    select: {
      '& ul': {
        backgroundColor: 'white',
      },
      '& li': {
        position: 'relative',
      },
    },
  })

  const classes = useStyles()

  return (
    <div className="select-input-container">
      <TextField
        select
        fullWidth
        disabled={disabled}
        required={required}
        id={id}
        label={label}
        classes={{
          root: `select {
            background-color: "red"
          }`,
        }}
        name={name}
        value={value}
        onChange={handleChangeFormData}
        SelectProps={{
          displayEmpty: true,
          renderValue: value
            ? undefined
            : () => <Typography color={'gray'}>{placeholder}</Typography>,
          MenuProps: {
            classes: { paper: classes.select },
          },
        }}
        sx={{
          my: 2,
          textTransform: 'capitalize',
          ...sx,
        }}
        variant="standard"
        InputLabelProps={{
          shrink: value ? true : shrink,
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={
              (option as OptionObjectPair).value
                ? (option as OptionObjectPair).value
                : (option as Option).id
            }
            sx={{
              textTransform: 'capitalize',
              position: 'absolute',
              ...itemStyles,
            }}
          >
            {(option as OptionObjectPair).label
              ? (option as OptionObjectPair).label
              : (option as Option).name}
          </MenuItem>
        ))}
      </TextField>
      {errorMessage && (
        <Typography fontSize={12} color="red" ml={0.5} mt={-1}>
          {errorMessage}
        </Typography>
      )}
    </div>
  )
}

export default SelectInput
