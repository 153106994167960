import { useAddTrayContext } from '../AddTray.context'
import { useSubTraysContext } from '../SubTrays/SubTrays.context'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useEffect } from 'react'

const useTrayContentLogic = (trayIndex: number) => {
  const { subTrays } = useAddTrayContext()
  const { setCurrentView } = useSubTraysContext()
  const { setTrayScrews, setTrayPlates } = useSPD()

  // Get the specific sub tray based on the index
  const subTray = subTrays[trayIndex]

  useEffect(() => {
    if (subTray?.content) {
      setTrayScrews(subTray.content.screws || [])
      setTrayPlates(subTray.content.plates || [])
    } else {
      setTrayScrews([])
      setTrayPlates([])
    }
  }, [subTray, setTrayScrews, setTrayPlates])

  const handleBack = () => {
    // Clear the screws and plates when navigating back
    setTrayScrews([])
    setTrayPlates([])
    setCurrentView('list')
  }

  return {
    subTray,
    handleBack,
  }
}

export default useTrayContentLogic
