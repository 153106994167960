import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
} from '@mui/material'
import { CameraAlt, FlipCameraIos, RestartAlt } from '@mui/icons-material'
import classNames from 'classnames'

import useAnalyzeTrayLogic from './AnalyzeTray.logic'
import { useAddTrayContext } from '../AddTray.context'

import { LoadingButton } from 'components/mui'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import TrayAnalysisContent from 'components/organisms/TrayAnalysisContent/TrayAnalysisContent'

import './AnalyzeTray.scss'
import { formatToTitleCase } from 'lib/utils/formatToTitleCase'

const AnalyzeTray = () => {
  const {
    handleImageCapture,
    videoRef,
    cameras,
    description,
    handleDescriptionChange,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    isTrayMapDrawerOpen,
    handleTrayMapDrawerClose,
    isSubmitting,
    handleAnalysisClick,
    handleTrayTypeChange,
    handleBackClick,
    hasPlates,
    shouldConfirmButtonBeDisabled,
    handleConfirm,
    parLevel,
    handleTrayParLevelChange,
    isInstrumentTray,
    recapture,
  } = useAnalyzeTrayLogic()
  const { trayImage, selectedTrayType, isAnalysisComplete } =
    useAddTrayContext()

  return (
    <>
      <Paper className="analyze-tray-step step-item">
        <Box className="analyze-tray">
          {selectedTrayType !== 'instrument' && (
            <FormControl fullWidth>
              <Select
                fullWidth
                variant="outlined"
                displayEmpty
                renderValue={(selected) =>
                  !selected ? (
                    <Typography variant="body1" color="gray">
                      Select Tray Type (required)
                    </Typography>
                  ) : (
                    formatToTitleCase(selected)
                  )
                }
                value={selectedTrayType || ''}
                onChange={handleTrayTypeChange}
              >
                <MenuItem disabled value="">
                  Select Tray Type
                </MenuItem>
                {[
                  'Stryker Screw Caddy',
                  'Stryker Upper Face',
                  'Stryker Middle Face',
                  'Zimmer Curved Rods',
                  'Zimmer Adjustable Transverse Connector',
                  'Zimmer Standard Closure Tops',
                ].map((trayType) => (
                  <MenuItem
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    key={trayType}
                    value={trayType}
                  >
                    {trayType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box className="camera-section">
            <Box className="camera-container">
              {(!selectedTrayType || isSubmitting) && (
                <Box className="tray-type-overlay">
                  <Typography
                    variant="h5"
                    className={classNames(
                      'overlay-text',
                      isSubmitting ? '' : 'warning'
                    )}
                  >
                    {isSubmitting
                      ? 'Analyzing tray...'
                      : 'Please select a tray type'}
                  </Typography>
                </Box>
              )}
              {!trayImage && (
                <>
                  {isCameraMenuOpen && (
                    <div className="camera-options">
                      {cameras.map((camera) => (
                        <div
                          key={camera.deviceId}
                          className={classNames('camera', {
                            active: camera.deviceId === activeCamera?.deviceId,
                          })}
                          onClick={() => handleSelectCamera(camera)}
                        >
                          <Typography variant="body2" className="camera-text">
                            {camera.label}
                          </Typography>

                          <Typography variant="body2" className="camera-text">
                            {camera?.cameraType}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                  <LoadingButton
                    disabled={!selectedTrayType || isSubmitting}
                    className="camera-toggle-btn"
                    onClick={handleToggleCameraMenu}
                  >
                    <FlipCameraIos />
                  </LoadingButton>
                  <LoadingButton
                    className="capture-btn"
                    disabled={!selectedTrayType || isSubmitting}
                    onClick={() => handleImageCapture(true)}
                  >
                    <CameraAlt />
                  </LoadingButton>
                </>
              )}
              {trayImage ? (
                <img src={trayImage.src} alt="Tray" className="tray-image" />
              ) : (
                <video
                  ref={videoRef}
                  autoPlay
                  muted
                  playsInline
                  className="camera-feed"
                />
              )}
            </Box>
            {(hasPlates || !isInstrumentTray) && (
              <FormControl className="par-level_container" fullWidth>
                <Typography
                  component="label"
                  mb={2}
                  className="par-level_label"
                >
                  Tray Par Level
                </Typography>
                <TextField
                  autoComplete="off"
                  placeholder="Tray Par Level"
                  fullWidth
                  type="number"
                  value={parLevel}
                  onChange={handleTrayParLevelChange}
                  variant="outlined"
                />
              </FormControl>
            )}
            <TextField
              autoComplete="off"
              placeholder="Enter Tray Details/ Description"
              fullWidth
              multiline
              minRows={4}
              maxRows={7}
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              variant="outlined"
            />
            <Box className="action-buttons">
              {trayImage || true ? (
                <Button onClick={recapture} className="recapture-btn">
                  <RestartAlt sx={{ mr: 0.5 }} />
                  Recapture
                </Button>
              ) : undefined}
              {selectedTrayType !== 'instrument' && (
                <LoadingButton
                  dataTestId="add-tray-submit_button"
                  loading={isSubmitting}
                  disabled={!trayImage || isSubmitting || trayImage.isUploading}
                  onClick={handleAnalysisClick}
                  variant="outlined"
                  className="analyze-btn"
                >
                  {isAnalysisComplete ? 'View Analysis' : 'Analyze Tray Image'}
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <div className="buttons_container">
        <Button variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <LoadingButton
          className="confirm-btn"
          disabled={shouldConfirmButtonBeDisabled}
          onClick={handleConfirm}
        >
          Confirm Tray
        </LoadingButton>
      </div>
      <DialogDrawer
        open={isTrayMapDrawerOpen}
        onClose={handleTrayMapDrawerClose}
        height="95%"
        overflowY
      >
        <TrayAnalysisContent
          trayType={selectedTrayType}
          onClose={handleTrayMapDrawerClose}
          trayImg={trayImage?.src}
        />
      </DialogDrawer>
    </>
  )
}

export default AnalyzeTray
