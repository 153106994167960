import { Surgery } from 'common/types'
import React from 'react'
import { z } from 'zod'

export const repSignUpSchema = z
  .object({
    email: z.string().email({ message: 'Invalid email address' }),
    emailValidation: z.string().email({ message: 'Invalid email address' }),
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    mobile: z.string().regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
      message: 'Mobile number must be in the format (321) 323-2131',
    }),
    companyName: z.string().min(1, { message: 'Company name is required' }),
    bidCompanyId: z.number().nullable(),
  })
  .refine((data) => data.email === data.emailValidation, {
    path: ['emailValidation'],
    message: 'Emails must match',
  })

export type TRepSignUpFormData = z.infer<typeof repSignUpSchema>

export type TValidateFormResponse = Record<string, string> | null

export type TRepSignUpFormProps = {
  surgeryId?: Surgery['_id']
  onClose?: () => void
  resetForm?: boolean
  setResetForm?: React.Dispatch<React.SetStateAction<boolean>>
}
