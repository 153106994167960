import type { MutationResult } from '@apollo/client'
import { useEffect, useState, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainIconFlowState } from 'common/types'
import Mask from 'components/Mask'
import PaperModal from 'components/PaperModal/PaperModal'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  LinearProgress,
  Alert,
  Snackbar,
} from '@mui/material'
import { CurrentScanDetails } from 'lib/scan-handler'
import { useCortexDecoderContext } from 'lib/cortex'
import styles from './scanAssetEntry.module.css'
import BetterIdResultsModal from 'components/BetterIdResultsModal'
import { AlternateScanResultModal } from 'components/AlternateScanResultModal'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { LoadingButton } from 'components/mui'
import ScannerActions from 'components/organisms/ScannerActions/ScannerActions'
import { ScannerFormatSwitcher } from 'components/ScannerFormatSwitcher'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'

interface Props {
  addSurgeryAssetScanMutation: MutationResult
  resetQuery: () => void
  resetQueryAndMutation: () => void
  scanDetails: CurrentScanDetails
  surgeryId: string
  mainIconFlowState: MainIconFlowState
  isBatchMode: boolean
}

declare global {
  interface Window {
    mockScan: (text: string) => void
  }
}

function ScanAssetEntry({
  addSurgeryAssetScanMutation,
  resetQuery,
  resetQueryAndMutation,
  scanDetails,
  mainIconFlowState,
}: Props) {
  // hooks
  const navigate = useNavigate()
  let location = useLocation()
  const { currentScan, setCurrentScan, error, isActive } =
    useCortexDecoderContext()
  const { assetType } = useAssetType()

  // states
  const [isExpiredAlert, setIsExpiredAlert] = useState<boolean | undefined>()
  const [isLoading, setIsLoading] = useState(true)
  const [searchDisplay, setSearchDisplay] = useState('Searching for asset')

  // constants
  const { lookupQuery, selectedResult, reset, isComplete } = scanDetails
  const assetData = lookupQuery.data
  const isAssetFound = isComplete && Boolean(selectedResult)
  const { isBatchModeEnabled } = mainIconFlowState
  const navigateToManualAdd = () => navigate('../manual')

  const SearchStatus = (
    <Typography
      variant="h3"
      sx={{
        ml: 1,
        color: 'success.dark',
        textTransform: 'initial',
      }}
    >
      {searchDisplay}
    </Typography>
  )

  // handles
  const resetScanner = () => {
    reset()
    setIsExpiredAlert(false)
    setIsExpiredAlert(undefined)
    resetQueryAndMutation()
  }

  const switchToBatch = () => {
    navigate('../batch', {
      state: {
        mode: 'batch',
      },
    })
  }

  const isBatchMode =
    (assetType === 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT' &&
      mainIconFlowState.isMultipackHardware === false) ||
    assetType === 'CONSUMABLE PRODUCT' ||
    assetType === 'IMPLANTABLE OTHER'

  if (isBatchMode) {
    switchToBatch()
  }

  useEffect(() => {
    const shouldNavigate = () => {
      return (
        isAssetFound &&
        !lookupQuery.error &&
        !addSurgeryAssetScanMutation.data &&
        isComplete &&
        !isExpiredAlert
      )
    }

    const hasExpired =
      selectedResult?.expirationDate &&
      new Date(selectedResult.expirationDate) < new Date()

    if (typeof isExpiredAlert === 'undefined' && shouldNavigate()) {
      if (hasExpired) {
        setIsExpiredAlert(true)
      } else {
        navigate(`../result/?mode=scan`)
      }
    } else if (shouldNavigate()) {
      navigate(`../result/?mode=scan`)
    }
  }, [
    addSurgeryAssetScanMutation.data,
    lookupQuery.error,
    isAssetFound,
    navigate,
    isComplete,
    isExpiredAlert,
    selectedResult?.expirationDate,
  ])

  useEffect(() => {
    if (isAssetFound === false && isComplete === true) {
      window.cortexScanner?.stopDecoding()
    } else {
      window.cortexScanner?.startDecoding()
    }
  }, [isAssetFound, isComplete, isExpiredAlert])

  // Resets the query and mutation when the user navigates away from the page
  useEffect(
    () => {
      reset()
      resetQueryAndMutation()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, reset]
  )

  useLayoutEffect(() => {
    setIsLoading(false)
  }, [])

  // Add mock scanner for testing
  useEffect(() => {
    if (window && process.env.REACT_APP_NODE_ENV !== 'production') {
      window.mockScan = (text: string) =>
        setCurrentScan({
          barcodeData: text,
          decodeTime: Date.now(),
          symbologyName: 'DataMatrix',
          barcodeCoordinates: {
            TopLeft: { X: '0', Y: '0' },
            TopRight: { X: '0', Y: '0' },
            BottomRight: { X: '0', Y: '0' },
            BottomLeft: { X: '0', Y: '0' },
          },
        })
    }
  }, [setCurrentScan])

  const displayExtendingSearchAlert = () => {
    if (lookupQuery.loading) {
      setSearchDisplay('Extending Product search')
    } else {
      clearTimeout(extendingSearch)
      setSearchDisplay('Searching for Product')
    }
  }

  const extendingSearch = setTimeout(displayExtendingSearchAlert, 5000)

  return (
    <>
      <Helmet>
        <title>Capture</title>
      </Helmet>
      <BetterIdResultsModal
        currentScanValue={currentScan?.barcodeData}
        results={scanDetails.results}
        selectedResult={scanDetails.selectedResult}
        setSelectedResult={scanDetails.setSelectedResult}
        valueType={scanDetails.type}
        onClose={() => navigate('../scan')}
      />
      <AlternateScanResultModal
        currentScanValue={currentScan?.barcodeData}
        results={scanDetails.results}
        lookupQuery={scanDetails.lookupQuery}
        isComplete={scanDetails.isComplete}
        valueType={scanDetails.type}
        onClose={() => navigate('../scan')}
      />
      <AlertDialog
        title="Product Expired"
        description="This product has expired and cannot be added to the procedure."
        isOpen={isExpiredAlert ?? false}
        secondaryButtonAction={resetScanner}
        secondaryButtonText="Cancel"
        primaryButtonAction={() => {
          setIsExpiredAlert(false)
        }}
        primaryButtonText="Add Anyways"
        dismissable={false}
      />
      <Box display="inline-flex" flexDirection="column" alignItems="center">
        {error ? (
          <ErrorFallback
            title="Failed to initiate Smart Vision"
            description="Our team has been notified of the issue."
          />
        ) : (
          <>
            {(!isActive || isLoading) && (
              <Box
                width="100vw"
                height="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                position="fixed"
                sx={{
                  backgroundColor: 'white',
                }}
              >
                <Box textAlign="center" mb={28}>
                  <Typography
                    variant="h3"
                    color="grey.800"
                    my={6}
                    letterSpacing={1}
                    fontWeight={500}
                    fontSize={18}
                  >
                    Preparing Smart Vision
                  </Typography>
                  <LinearProgress />
                </Box>
              </Box>
            )}
            <ScannerActions>
              <ScannerFormatSwitcher />
            </ScannerActions>
          </>
        )}
        {/* Add asset manually button */}
        {assetData === undefined &&
          !lookupQuery.error &&
          !lookupQuery.loading && (
            <Box
              position="fixed"
              bottom="80px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              columnGap={1}
            >
              <LoadingButton onClick={navigateToManualAdd} variant="contained">
                Add Manually
              </LoadingButton>
              {isBatchModeEnabled && (
                <LoadingButton onClick={switchToBatch} variant="contained">
                  Switch to Batch
                </LoadingButton>
              )}
            </Box>
          )}

        {/* Searching progress indicator */}
        <Mask open={lookupQuery.loading}>
          <PaperModal
            backgroundColor={'success.light'}
            positionAboveBottomNavigation
            flexDirection="row"
          >
            <Box className={styles.queryLoadingContent}>
              <CircularProgress size={22} color="success" sx={{ mx: 1 }} />

              {extendingSearch && SearchStatus}
            </Box>
            <Button
              color="success"
              sx={{ color: 'success.dark' }}
              onClick={resetQuery}
            >
              Cancel
            </Button>
          </PaperModal>
        </Mask>

        {/* Query error */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(lookupQuery.error)}
          autoHideDuration={6000}
          onClose={resetQuery}
          sx={{
            bottom: '7% !important',
          }}
        >
          <Alert
            onClose={resetQuery}
            severity="error"
            sx={{ width: '100%' }}
            elevation={6}
            variant="filled"
          >
            Failed to query BetterID & GUDID databases for product data
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}

export default ScanAssetEntry
