import { createContext, useContext, useEffect, useState } from 'react'
import { AlertColor, AlertPropsColorOverrides } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import { useUser } from 'app/User'
import { useGetAllTrayItems } from 'lib/apollo/hooks'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'

import { OverridableStringUnion } from '@mui/types'
import { TrayConfigurationContextProps } from './TrayManagement.types'
import { TrayType } from 'components/organisms/AddTraySelectType/AddTraySelectType.types'
import { SPDContextProps } from 'views/SPDLayout/SPD/SPD.types'
import { ItemResponse, Option } from 'common/types'
import { FilterValue } from 'components/organisms/TrayManagementFilters/TrayManagementFilters.types'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { TrayStatus } from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { useLocation } from 'react-router-dom'

const TrayConfigurationContext = createContext<
  TrayConfigurationContextProps | undefined
>(undefined)

export const TrayConfigurationProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { isRep, user } = useUser()
  const { trayItems, loading, error, refetch } = useGetAllTrayItems()
  const { pathname } = useLocation()

  const [trays, setTrays] = useState<ItemResponse[]>([])
  const [selectedTrayCategory, setSelectedTrayCategory] = useState<
    TrayType['category'] | null
  >(null)
  const [selectedTray, setSelectedTray] = useState<
    SPDContextProps['trayData'] | null
  >(null)
  const [isListOfSurgeriesOpen, setIsListOfSurgeriesOpen] =
    useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarState, setSnackbarState] = useState<
    OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined
  >('success')
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarIcon, setSnackbarIcon] = useState<JSX.Element>(
    <CheckCircle color="success" />
  )
  const [filterValue, setFilterValue] = useState<FilterValue>('')
  const [trayIdFilterValue, setTrayIdFilterValue] = useState<string>('')
  const [companyFilterValue, setCompanyFilterValue] = useState<
    Option | undefined
  >(undefined)
  const [trayStatusFilterValue, setTrayStatusFilterValue] =
    useState<TrayStatus>(undefined)
  const [isAddTrayModalOpen, setIsAddTrayModalOpen] = useState<boolean>(false)
  const [isFiltersNavbarOpen, setIsFiltersNavbarOpen] = useState<boolean>(true)
  const [isEditTrayStatusModalOpen, setIsEditTrayStatusModalOpen] =
    useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState<Set<TrayStatus>>(
    new Set()
  )
  const [selectedCategory, setSelectedCategory] = useState<
    TrayType['category'] | undefined
  >(undefined)

  useEffect(() => {
    refetch()
  }, [pathname])

  useEffect(() => {
    if (!loading && !error) {
      const filteredTrayItems = filterTrayItems(
        trayItems,
        selectedFilters,
        selectedCategory,
        trayIdFilterValue,
        companyFilterValue,
        isRep
      )

      setTrays(filteredTrayItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    selectedFilters,
    selectedCategory,
    trayIdFilterValue,
    companyFilterValue?.name,
    trayStatusFilterValue,
    refetch,
    isEditTrayStatusModalOpen,
    trayItems,
  ])

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  const applyBarcodeFilter = (
    trayItem: ItemResponse,
    trayIdFilterValue?: string
  ) =>
    trayIdFilterValue
      ? trayItem.barcode
          ?.toLowerCase()
          .includes(trayIdFilterValue.toLowerCase())
      : true

  const applyCompanyFilter = (
    trayItem: ItemResponse,
    companyFilterValue?: { name: string }
  ) =>
    companyFilterValue
      ? trayItem.companyName
          ?.toLowerCase()
          .includes(companyFilterValue.name.toLowerCase())
      : true

  const applyRepEmailFilter = (
    trayDetails: TrayItemProductDetails,
    userEmail?: string,
    isRep?: boolean
  ) => {
    if (isRep && userEmail) {
      return trayDetails.email === userEmail
    }
    return true
  }

  const filterTrayItems = (
    trayItems: ItemResponse[],
    selectedFilters: Set<TrayStatus>,
    selectedCategory: TrayType['category'] | undefined,
    trayIdFilterValue?: string,
    companyFilterValue?: { name: string },
    isRep?: boolean
  ): ItemResponse[] => {
    return trayItems.filter((trayItem) => {
      const trayDetails = extractTrayJSONData(
        trayItem
      ) as TrayItemProductDetails

      return (
        applyRepEmailFilter(trayDetails, user?.email, isRep) &&
        applyCategoryFilter(trayDetails, selectedCategory) &&
        applySelectedFilters(trayDetails, selectedFilters) &&
        applyBarcodeFilter(trayItem, trayIdFilterValue) &&
        applyCompanyFilter(trayItem, companyFilterValue)
      )
    })
  }

  const applyCategoryFilter = (
    trayDetails: TrayItemProductDetails,
    selectedCategory: TrayType['category'] | undefined
  ) => {
    if (!selectedCategory) return true
    return trayDetails.trayCategory === selectedCategory
  }

  const applySelectedFilters = (
    trayDetails: TrayItemProductDetails,
    selectedFilters: Set<TrayStatus>
  ) => {
    if (selectedFilters.size === 0) return true
    return selectedFilters.has(trayDetails.status)
  }

  const handleFiltersNavbarMenuClick = () => {
    setIsFiltersNavbarOpen((prev) => !prev)
  }

  return (
    <TrayConfigurationContext.Provider
      value={{
        trays,
        setTrays,
        loadingTrays: loading,
        error,
        selectedTrayCategory,
        setSelectedTrayCategory,
        selectedTray,
        setSelectedTray,
        isListOfSurgeriesOpen,
        setIsListOfSurgeriesOpen,
        snackbarIcon,
        setSnackbarIcon,
        snackbarState,
        setSnackbarState,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        handleSnackbarClose,
        filterValue,
        setFilterValue,
        trayIdFilterValue,
        setTrayIdFilterValue,
        companyFilterValue,
        setCompanyFilterValue,
        trayStatusFilterValue,
        setTrayStatusFilterValue,
        isAddTrayModalOpen,
        setIsAddTrayModalOpen,
        isFiltersNavbarOpen,
        setIsFiltersNavbarOpen,
        handleFiltersNavbarMenuClick,
        refetch,
        isEditTrayStatusModalOpen,
        setIsEditTrayStatusModalOpen,
        selectedFilters,
        setSelectedFilters,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </TrayConfigurationContext.Provider>
  )
}

export const useTrayConfigurationContext = () => {
  const context = useContext(TrayConfigurationContext)

  if (context === undefined) {
    throw new Error(
      'useTrayConfigurationContext must be used with TrayConfigurationProvider'
    )
  }

  return context
}
