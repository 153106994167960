import { useEffect, useState } from 'react'

import { EditCountProps } from './EditCount.types'

const useEditCountLogic = ({ onChange, initialCount }: EditCountProps) => {
  const [count, setCount] = useState<number>(initialCount)

  useEffect(() => {
    if (count !== initialCount) {
      setCount(initialCount)
    }
  }, [initialCount])

  const handleDecrease = () => {
    if (count > 0) {
      const newCount = count - 1
      setCount(newCount)
      onChange(newCount)
    }
  }

  const handleIncrease = () => {
    const newCount = count + 1
    setCount(newCount)
    onChange(newCount)
  }
  return {
    count,
    handleDecrease,
    handleIncrease,
  }
}

export default useEditCountLogic
