import { Add, Close } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { ImplantReportType } from 'common/types'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'
import { CustomModal } from 'components/molecules/Modal/Modal'
import dayjs from 'lib/dayjs'
import '../ImplantReportSender.scss'
import { Delete } from '@mui/icons-material'
import TextInput from 'components/molecules/TextInput/TextInput'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import AutocompleteInput from '../../AutocompleteInput/AutocompleteInput'
import { useImplantReportSenderModalLogic } from './ImplantReportSenderModal.logic'
import { ImplantReportSenderModalProps } from '../ImplantReportSender.types'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export const ImplantReportSenderModal = ({
  assetIds,
  inputModalOpen,
  formatEnumValue,
  surgeryDate,
  surgeryId,
  implantReportStatus,
  implantReportInputs,
  setImplantReportInputs,
  unformatEnumValue,
  setErrorMessage,
  setInputModalOpen,
}: ImplantReportSenderModalProps) => {
  const {
    attachments,
    fileInputRef,
    validationErrors,
    extractionValues,
    reportAlreadySentModal,
    sendImplantReportMutation,
    handleAttach,
    submitReport,
    handleOnClose,
    validateAndSubmit,
    handleChangeFormData,
    handleRemoveAttachment,
    handleAddExtractionInput,
    setReportAlreadySentModal,
    handleChangeExtractionInput,
    handleRemoveExtractionInput,
    handleAttachementFileChange,
  } = useImplantReportSenderModalLogic({
    assetIds,
    surgeryId,
    implantReportStatus,
    implantReportInputs,
    setImplantReportInputs,
    unformatEnumValue,
    setErrorMessage,
    setInputModalOpen,
    formatEnumValue,
  })

  return (
    <CustomModal
      open={inputModalOpen}
      secondaryButtonAction={handleOnClose}
      header={`Implant Report: ${formatEnumValue(
        implantReportInputs?.['reportType'] as keyof typeof ImplantReportType
      )}`}
      isLoadingState={sendImplantReportMutation.loading}
      primaryButtonText="Submit"
      handleOnSubmit={validateAndSubmit}
    >
      {implantReportInputs['reportType'] === 'EXTRACTION' && (
        <div>
          <div className="extraction-report-input-with-button">
            <div className="extraction-report-input-array">
              {extractionValues.map((value, index) => (
                <TextInput
                  key={index}
                  variant="outlined"
                  placeholder="Extraction site*"
                  label="Extraction Site*"
                  type="text"
                  fullwidth={false}
                  value={value}
                  handleChangeFormData={(e) => {
                    handleChangeExtractionInput(index, e.target.value)
                  }}
                  InputProps={{
                    endAdornment:
                      extractionValues.length > 1 ? (
                        <IconButton
                          onClick={() => handleRemoveExtractionInput(index)}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      ) : null,
                  }}
                  errorMessage={validationErrors?.extractionValues?.[index]} // Access error by index
                />
              ))}
            </div>
            <IconButton
              size="small"
              onClick={handleAddExtractionInput}
              disableFocusRipple
              disableTouchRipple
            >
              <Add className="add-icon" />
            </IconButton>
          </div>
          <TextInput
            variant="outlined"
            placeholder="Additional Comments"
            label="Additional Comments"
            handleChangeFormData={(e) => {
              handleChangeFormData('additionalComments', e.target.value)
            }}
            value={implantReportInputs.reportInputs['additionalComments']}
          />
        </div>
      )}

      {(implantReportInputs['reportType'] === 'FIRST_STAGE' ||
        implantReportInputs['reportType'] === 'SINGLE') && (
        <div className="first-single-stage">
          <CustomDatePicker
            sx={{ width: '80%' }}
            label="Next Appointment Date"
            className="next-date-picker"
            value={
              implantReportInputs.reportInputs['nextApptDate'] as dayjs.Dayjs
            }
            onDateSelection={(date) => {
              handleChangeFormData('nextApptDate', date)
            }}
            minDate={dayjs(surgeryDate).add(1, 'day')}
            errorMessage={validationErrors?.nextApptDate?.[0]}
          />

          <TextInput
            variant="outlined"
            className="next-appt-input"
            placeholder={`${
              implantReportInputs['reportType'] === 'FIRST_STAGE'
                ? '2nd Stage'
                : 'Final check'
            } will be scheduled in ____ months*`}
            label={`${
              implantReportInputs['reportType'] === 'FIRST_STAGE'
                ? '2nd Stage'
                : 'Final check'
            } will be scheduled in ____ months*`}
            handleChangeFormData={(e) => {
              handleChangeFormData('nextApptInMonths', Number(e.target.value))
            }}
            value={implantReportInputs.reportInputs['nextApptInMonths']}
            type="number"
            errorMessage={validationErrors?.nextApptInMonths?.[0]}
          />

          {implantReportInputs['reportType'] === 'SINGLE' && (
            <TextInput
              variant="outlined"
              placeholder="Additional Comments"
              label="Additional Comments"
              handleChangeFormData={(e) => {
                handleChangeFormData('additionalComments', e.target.value)
              }}
              value={implantReportInputs.reportInputs['additionalComments']}
            />
          )}

          {implantReportInputs['reportType'] === 'FIRST_STAGE' && (
            <div className="first-single-stage">
              <AutocompleteInput
                id={'abutment-type-menu'}
                handleChangeFormData={(e) => {
                  handleChangeFormData(
                    'secondStageAbutmentType',
                    e.target.value as string
                  )
                }}
                name={'abutment-type'}
                label="Select Second Stage Abutment Type*"
                variant="outlined"
                value={
                  implantReportInputs.reportInputs['secondStageAbutmentType']
                }
                options={['Healing', 'Cementable', 'Other']}
                autoSelect={false}
                disableClearable
                errorMessage={validationErrors?.secondStageAbutmentType?.[0]}
              />
              {implantReportInputs.reportInputs['secondStageAbutmentType'] ===
                'Other' && (
                <TextInput
                  variant="outlined"
                  className="other-abutment-input"
                  placeholder="Other Abutment Type"
                  label="Other Abutment Type"
                  handleChangeFormData={(e) => {
                    handleChangeFormData('otherAbutmentType', e.target.value)
                  }}
                  value={implantReportInputs.reportInputs['otherAbutmentType']}
                />
              )}
              <TextInput
                variant="outlined"
                placeholder="Additional Comments"
                label="Additional Comments"
                handleChangeFormData={(e) => {
                  handleChangeFormData('additionalComments', e.target.value)
                }}
                value={implantReportInputs.reportInputs['additionalComments']}
              />
            </div>
          )}
        </div>
      )}

      {(implantReportInputs['reportType'] === 'SECOND_STAGE' ||
        implantReportInputs['reportType'] === 'FINAL') && (
        <div className="restorative-report-input-array">
          <TextInput
            variant="outlined"
            placeholder="Additional Comments"
            label="Additional Comments"
            handleChangeFormData={(e) => {
              handleChangeFormData('additionalComments', e.target.value)
            }}
            value={implantReportInputs.reportInputs['additionalComments']}
          />
          <CustomDatePicker
            label="Restorative Phase Date"
            className="restorative-date-picker"
            value={
              implantReportInputs.reportInputs[
                'restorativePhaseDate'
              ] as dayjs.Dayjs
            }
            onDateSelection={(date) => {
              handleChangeFormData('restorativePhaseDate', date)
            }}
            minDate={dayjs(surgeryDate)}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            className="secondary-text"
          >
            If a restorative phase date is not entered, then the report will
            default to "The patient understands that they should contact your
            office to begin the restorative phase within 1-2 weeks."
          </Typography>
        </div>
      )}

      {implantReportInputs['reportType'] === 'OTHER' && (
        <TextInput
          id="other-implant-report-memo"
          label="Please add additional comments such as type of consultation here"
          multiline
          rows={4}
          variant="outlined"
          handleChangeFormData={(e) => {
            handleChangeFormData('additionalComments', e.target.value)
          }}
          value={implantReportInputs.reportInputs['additionalComments']}
          errorMessage={validationErrors?.additionalComments?.[0]}
        />
      )}

      {reportAlreadySentModal && (
        <AlertDialog
          isOpen={reportAlreadySentModal}
          mode="primary"
          title="Report Already Sent"
          description="This report has already been sent to the referring physician. Are you sure you want to send it again?"
          secondaryButtonAction={() => setReportAlreadySentModal(false)}
          primaryButtonAction={submitReport}
          primaryButtonText="Submit"
          isPrimaryLoading={sendImplantReportMutation.loading}
          zIndex={1300}
        />
      )}

      <div className="attachment-wrapper">
        {attachments.length > 0 ? (
          <div className="attachment-images-wrapper">
            {attachments.map(({ filename, thumbnail }, index) => (
              <div className="attachment-image-box">
                <IconButton
                  className="delete-attachment"
                  onClick={handleRemoveAttachment(index)}
                >
                  <Close className="close" />
                </IconButton>
                <img
                  src={thumbnail}
                  alt="thumbnail"
                  className="attachment-image"
                />
                <div className="titles ellipsis">{filename}</div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="titles" onClick={handleAttach}>
              Attach Documents (PDF, Image)
            </div>
          </>
        )}

        <IconButton disabled={attachments.length > 3} onClick={handleAttach}>
          <AttachFileIcon
            fontSize="small"
            sx={{
              color: `${
                attachments.length < 4
                  ? 'var(--primary-dark)'
                  : 'var(--disabled)'
              }`,
            }}
          />
          <input
            hidden
            type="file"
            ref={fileInputRef}
            accept=".pdf, image/*"
            onChange={handleAttachementFileChange}
          />
        </IconButton>
      </div>
    </CustomModal>
  )
}
