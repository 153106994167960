import {
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import {
  ASSET_TYPES,
  DispositionList,
  EXPLANTED_REASON_LIST,
  getDispositionList,
  WASTED_REASON_LIST,
} from 'common/disposition'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { useEditDispositionModalLogic } from './EditDispositionModal.logic'
import MediaUpload from '../MediaUpload/MediaUpload'
import TextInput from 'components/molecules/TextInput/TextInput'
import { EditDispositionModalProps } from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'

export const EditDispositionModal = ({
  assetTray,
  dataTestId,
  isManualAddition,
  scans,
  isMultipack,
  isMultipackHardware,
  singleConsumablesCount,
}: EditDispositionModalProps) => {
  const {
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSavingEditedAsset,
    setIsEditModalOpen,
    renderImplantSites,
    isConsumable,
    isEditModalOpen,
    handleReset,
    isSiteLocationEnabled,
    selectedAssetId,
    consumableAssetGroup,
    selectedAssetGroupId,
    isLoading,
    areInputsUnchanged,
    implantStatusLabel,
    wastedReasonLabel,
    setIsMediaActionTriggered,
    validationErrors,
  } = useEditDispositionModalLogic({
    assetTray,
    isMultipack,
    isMultipackHardware,
    isManualAddition,
    singleConsumablesCount,
  })

  return (
    <CustomModal
      open={isEditModalOpen}
      dataTestId={dataTestId}
      secondaryButtonAction={() => {
        handleReset()
        setIsEditModalOpen(false)
      }}
      header={isConsumable ? 'Edit Consumable' : 'Edit Disposition'}
      primaryButtonDisabled={areInputsUnchanged}
      isLoadingState={isLoading || isSavingEditedAsset(selectedAssetGroupId)}
      handleOnSubmit={handleSubmit}
      primaryButtonText={'Save'}
    >
      <FormControl fullWidth sx={{ my: 2 }}>
        <Box>
          <InputLabel id="asset-type-label">Product Type</InputLabel>
          <Select
            fullWidth
            data-testid="asset-type-input"
            id="assetType"
            name="assetType"
            variant="outlined"
            labelId="asset-type-label"
            label="Product Type"
            value={formState?.assetType}
            onChange={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) ||
              isConsumable ||
              isMultipackHardware
            }
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
              },
            }}
          >
            <MenuItem
              data-testid="hardware-menuitem"
              style={{ cursor: 'pointer', fontSize: 16 }}
              key={ASSET_TYPES[0]}
              value={ASSET_TYPES[0]}
            >
              Implantable Hardware/Assoc. Product
            </MenuItem>
            <MenuItem
              data-testid="other-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[2]}
              value={ASSET_TYPES[2]}
            >
              Implantable Other
            </MenuItem>
            <MenuItem
              data-testid="consumable-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[3]}
              value={ASSET_TYPES[3]}
            >
              Consumable
            </MenuItem>
          </Select>
        </Box>
      </FormControl>

      {formState?.assetType && !isConsumable && (
        <AutocompleteInput
          dataTestId="implant-status-input"
          id="implantStatus"
          name="implantStatus"
          variant="outlined"
          label="Disposition*"
          disabled={isSavingEditedAsset(selectedAssetGroupId)}
          value={implantStatusLabel}
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          options={
            getDispositionList({
              assetType: formState.assetType,
              isMultipackHardware,
            }) as DispositionList
          }
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          errorMessage={validationErrors.implantStatus}
          disableClearable
          error={!!validationErrors.implantStatus}
        />
      )}

      {renderImplantSites && (
        <AutocompleteInput
          dataTestId="implant-site-input"
          id="implantSite"
          name="implantSite"
          label={
            formState.implantStatus === 'IMPLANTED'
              ? 'Implant Site*'
              : 'Explant Site*'
          }
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          value={formState?.implantSite}
          options={implantSiteList}
          handleChangeFormData={handleChangeFormData}
          variant="outlined"
          disabled={isSavingEditedAsset(selectedAssetGroupId)}
          disableClearable
          errorMessage={validationErrors.implantSite}
          error={!!validationErrors.implantSite}
        />
      )}

      {isSiteLocationEnabled && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`implant-site`}
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            name={`siteLocation`}
            value={formState.siteLocation ?? ''}
            placeholder={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            inputMode="text"
            error={!!validationErrors.siteLocation}
            sx={{
              backgroundColor: 'grayscale.lightest',
            }}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
          />
          {!!validationErrors.siteLocation && (
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.siteLocation}
            </Typography>
          )}
        </FormControl>
      )}

      {formState?.implantStatus === 'WASTED' && !isConsumable && (
        <AutocompleteInput
          dataTestId="wasted-reason-input"
          id="wastedReason"
          name="wastedReason"
          variant="outlined"
          label="Wasted Reason*"
          value={wastedReasonLabel}
          options={WASTED_REASON_LIST}
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          disabled={isSavingEditedAsset(selectedAssetGroupId)}
          errorMessage={validationErrors.wastedReason}
          disableClearable
          error={!!validationErrors.wastedReason}
        />
      )}

      {isManualAddition && formState?.implantStatus === 'EXPLANTED' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="explanted-reason-label">Explanted Reason*</InputLabel>
          <Select
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            labelId="explanted-reason-label"
            label="Explanted Reason*"
            value={formState.explantedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            error={!!validationErrors.explantedReason}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {EXPLANTED_REASON_LIST.map((reason) => (
              <MenuItem key={reason.id} value={reason.id}>
                {reason.name}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="caption"
            sx={{ color: 'error.main', ml: '0.5em' }}
          >
            {validationErrors.explantedReason}
          </Typography>
        </FormControl>
      )}

      {isManualAddition &&
        formState?.explantedReason === 'OTHER' &&
        formState.implantStatus === 'EXPLANTED' && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              id={`explanted-reason-note`}
              label="Other Reason*"
              placeholder="Other Reason*"
              name={`explantedReasonNote`}
              value={formState.explantedReasonNote ?? ''}
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={isSavingEditedAsset(selectedAssetGroupId)}
              error={!!validationErrors.explantedReasonNote}
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.explantedReasonNote}
            </Typography>
          </FormControl>
        )}

      {isConsumable && (
        <TextInput
          handleChangeFormData={handleChangeFormData}
          id={`consumable-count-${consumableAssetGroup?.bidAssetId}`}
          label="Items Used*"
          name={`count`}
          value={formState.count ?? ''}
          inputMode="numeric"
          variant="outlined"
          type="number"
          required={false}
          disabled={isSavingEditedAsset(selectedAssetGroupId)}
          sx={{ mb: 1, backgroundColor: 'grayscale.lightest' }}
          error={!!validationErrors.count}
          errorMessage={validationErrors.count}
        />
      )}

      {!isConsumable && (
        <MediaUpload
          hasActions
          setIsMediaActionTriggered={setIsMediaActionTriggered}
          assetId={selectedAssetId || ''}
          fileNames={
            selectedAssetId
              ? (scans?.filter((item) => item._id === selectedAssetId)[0]
                  .media as string[])
              : []
          }
        />
      )}
    </CustomModal>
  )
}
