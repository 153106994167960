import { useState } from 'react'

const useApproveRejectTrayLogic = ({ onReject }: { onReject: () => void }) => {
  const [hasSelectedRejection, setHasSelectedRejection] =
    useState<boolean>(false)
  const [rejectionReason, setRejectionReason] = useState<string>('')

  const handleRejectionClick = () => {
    setHasSelectedRejection(true)
  }

  const cancelRejection = () => {
    setHasSelectedRejection(false)
    setRejectionReason('')
  }

  const handleRejectionReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRejectionReason(event.target.value)
  }

  return {
    hasSelectedRejection,
    setHasSelectedRejection,
    rejectionReason,
    setRejectionReason,
    handleRejectionClick,
    handleRejectionReasonChange,
    cancelRejection,
  }
}

export default useApproveRejectTrayLogic
