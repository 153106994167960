import { CircularProgress, Skeleton, TextField } from '@mui/material'

import SPDCortexScanner from '../SPDCortexScanner/SPDCortexScanner'
import ConfirmSPDWorkflowModal from '../../../../components/organisms/ConfirmSPDWorkflowModal/ConfirmSPDWorkflowModal'

import { useSPDScan } from './Scan.context'
import { useSPD } from '../SPD.context'

import './Scan.scss'
import { ChangeEvent } from 'react'
import { CaptureTrayDialogProps } from 'components/organisms/CaptureTrayDialog/CaptureTrayDailog.types'
import useScanLogic from './Scan.logic'

const Scan = ({ onNext }: CaptureTrayDialogProps) => {
  const {
    scannedCode,
    setScannedCode,
    lookupQuery,
    manualCode,
    setManualCode,
  } = useSPDScan()
  useScanLogic({ onNext })

  const { confirmDurationModalOpen, setConfirmDurationModalOpen } = useSPD()

  return (
    <div className="spd-scan">
      <div className="scanner">
        <SPDCortexScanner
          canScan={true}
          onScan={(code) => {
            setScannedCode(code)
          }}
        />

        <Skeleton
          className="skeleton"
          variant="rounded"
          width="100%"
          height="100%"
          animation="wave"
        />

        {(scannedCode.length > 0 || manualCode.length > 0) &&
          lookupQuery.loading && (
            <div className="scanned-code_wrapper">
              <span className="scanned-text_wrapper">
                <CircularProgress size="0.8em" className="spinner" />
                <p className="scanned-text">Searching for product</p>
              </span>
              <p className="scanned-code">{scannedCode || manualCode}</p>
            </div>
          )}

        <ConfirmSPDWorkflowModal
          open={confirmDurationModalOpen}
          onClose={() => {
            setConfirmDurationModalOpen(false)
          }}
        />
      </div>
      <div className="input_wrapper">
        <TextField
          fullWidth
          autoComplete="off"
          placeholder="Product UDI"
          variant="outlined"
          type="text"
          className="manual-input"
          value={manualCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setManualCode(e.target.value)
          }
        />
      </div>
    </div>
  )
}

export default Scan
