import { AssetFormData } from 'common/types'
import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

interface AssetFormState {
  assetFormData: AssetFormData
  handleChangeAssetType: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeTray: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeSerialNumber: (e: React.ChangeEvent<HTMLInputElement>) => void
  createDispositionsChangeHandler: (
    index: number
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  resetAssetFormData: () => void
}

const AssetFormDataContext = createContext(({} as AssetFormState) || undefined)

const initialAssetFormData: AssetFormData = { dispositions: {} }

function AssetFormDataProvider({
  children,
  initialTray,
}: {
  children: React.ReactNode
  initialTray?: string
}) {
  const [assetFormData, setAssetFormDataMap] = useState<AssetFormData>({
    ...initialAssetFormData,
    assetTray: initialTray,
  })

  const handleChangeAssetType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | any) => {
      setAssetFormDataMap((prev) => {
        return {
          ...prev,
          assetType: e.target.value,
        }
      })
    },
    []
  )

  const handleChangeTray = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAssetFormDataMap((prev) => ({
        ...prev,
        assetTray: e.target.value,
      }))
    },
    []
  )

  const handleChangeSerialNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAssetFormDataMap((prev) => ({
        ...prev,
        serialNumber: e.target.value,
      }))
    },
    []
  )

  const createDispositionsChangeHandler = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      const parsedName = name.split('-').shift()
      if (parsedName) {
        setAssetFormDataMap((prev) => {
          return {
            ...prev,
            dispositions: {
              ...prev.dispositions,
              [index]: {
                ...prev.dispositions[index],
                [parsedName]: value,
              },
            },
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const resetAssetFormData = useCallback(
    () =>
      setAssetFormDataMap({
        ...initialAssetFormData,
        assetTray: assetFormData.assetTray,
      }),
    [assetFormData.assetTray]
  )

  const value = useMemo(
    () => ({
      assetFormData,
      handleChangeAssetType,
      handleChangeTray,
      handleChangeSerialNumber,
      createDispositionsChangeHandler,
      resetAssetFormData,
    }),
    [
      assetFormData,
      handleChangeAssetType,
      handleChangeTray,
      handleChangeSerialNumber,
      createDispositionsChangeHandler,
      resetAssetFormData,
    ]
  )

  return (
    <AssetFormDataContext.Provider value={value}>
      {children}
    </AssetFormDataContext.Provider>
  )
}

function useAssetFormData() {
  const context = useContext(AssetFormDataContext)
  if (context === undefined) {
    throw new Error('useAssetFormData must be used with AssetFormDataProvider')
  }
  return context
}

export { AssetFormDataProvider, useAssetFormData }
