import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useUser } from 'app/User'
import { uploadImageToS3 } from 'lib/utils/uploadToS3'
import { useCaptureTrayContext } from '../CaptureTray.context'
import {
  useCreateTrayAnalysisMutation,
  useGeneratePresignedUrlMutation,
} from 'lib/apollo/hooks'

import { TrayScrew } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/TrayVisualization.types'
import { Surgery, TrayAnalysisInput } from 'common/types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { useAssignedDigitalTrays } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import useTrayVisualizationLogic from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/TrayVisualization.logic'

import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import useGrayScaleTrayMapLogic from 'components/organisms/GrayScale/GrayScale.logic'

export interface AnalysisResult {
  section_label: string
  column_index: number
  column_label: number
  row_index: number
  row_label: number
  removed: boolean
  wasted: boolean
}

interface AbortControllerType {
  controller: AbortController
  signal: AbortSignal
}

const useSubmitTrayCapture = () => {
  const navigate = useNavigate()
  const { surgeryId } = useParams()
  const { user } = useUser()
  const { setTrayPlates } = useSPD()
  const { getPlateDI } = useGrayScaleTrayMapLogic({
    isInOR: false,
    setSelectedPlate: () => {},
    setModalOpen: () => {},
  })
  const { createTrayAnalysis } = useCreateTrayAnalysisMutation()
  const { setBaseImage, setIsSubmitting, setIsAlertOpen, setAlertData } =
    useCaptureTrayContext()
  const { generatePresignedUrl } = useGeneratePresignedUrlMutation()
  const { setUsageScrews } = useAssignedDigitalTrays()
  const { findScrew } = useTrayVisualizationLogic()

  const [isAnalysisFromSPD, setIsAnalysisFromSPD] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [analysisScrews, setAnalysisScrews] = useState<AnalysisResult[]>([])
  const [abortController, setAbortController] = useState<AbortControllerType>(
    () => {
      const controller = new AbortController()
      return { controller, signal: controller.signal }
    }
  )
  const [aborted, setAborted] = useState<boolean>(false)
  const [analysisError, setAnalysisError] = useState<string>('')
  const [analysisFileName, setAnalysisFileName] = useState(
    'Stryker_2.0MP_Screws.json'
  )

  useEffect(() => {
    if (aborted) {
      const controller = new AbortController()
      setAbortController({ controller, signal: controller.signal })
      setAborted(false)
    }
  }, [aborted])

  const saveAnalysis = async (inputData: TrayAnalysisInput) => {
    try {
      const result = await createTrayAnalysis(inputData)
      if (result.error) {
        if (process.env.REACT_APP_NODE_ENV !== 'production') {
          console.error('Error creating tray analysis:', result.error)
        }
      } else {
        localStorage.setItem('analysis-data', JSON.stringify({}))
        localStorage.setItem('analysis-data', JSON.stringify(result.data))
        setBaseImage(1890)
      }
    } catch (error) {
      console.error('Error creating tray analysis:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const submitImage = async (image: string) => {
    try {
      setIsSubmitting(true)

      // Upload image to ImgBB
      const imageUrl = (await handleBase64UploadToS3(image)) as string | null

      if (imageUrl) {
        handleSubmit(imageUrl)
      } else {
        handleSubmissionError('Failed to submit image. Please try again.')
      }
    } catch (error) {
      console.error('Error submitting image:', error)
    }
  }

  const base64ToFile = (
    base64Data: string,
    filename: string,
    contentType: string
  ): File => {
    const byteCharacters = Buffer.from(
      base64Data.split(',')[1],
      'base64'
    ).toString('binary')
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return new File([blob], filename, { type: contentType })
  }

  const handleBase64UploadToS3 = async (base64Image: string) => {
    setIsSubmitting(true)
    const file = base64ToFile(base64Image, 'image.jpg', 'image/jpeg')
    const key = `tray_images/${Date.now()}_${file.name}`
    try {
      const presignedUrl = (await generatePresignedUrl(key)) as string
      const imageUrl = await uploadImageToS3(file, presignedUrl, key)
      setIsSubmitting(false)
      return imageUrl
    } catch (error) {
      return null
    }
  }

  const handleSubmit = async (image: string) => {
    try {
      setIsSubmitting(true)

      const startTime = performance.now()

      const res = await fetch(
        `${process.env.REACT_APP_DTM_COMPUTER_VISION_API}/api/process_image`,
        {
          method: 'POST',
          signal: abortController.signal,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            after_image_url: image,
            template_filename: analysisFileName,
          }),
        }
      )

      const endTime = performance.now()

      const analysisDuration = Math.floor((endTime - startTime) / 1000)

      const data = await res.json()
      // Check if it's a Zimmer 4 tray
      if (data.result?.regions) {
        // Extract the areas from the response
        const plateRegions = data.result.regions.filter(
          (region: any) => region.type === 'plate'
        )

        plateRegions.forEach((region: any) => {
          // Format the areas into tray plates
          const formattedTrayPlates = region.areas
            .map((area: any) => {
              if (!area.label) return null

              return {
                deviceId: 'generic-plate-id', // Use a generic deviceId
                plateName: area.label, // Use the label directly as plateName
                plateCount: area.count || 0, // Default count to 0 if not present
              }
            })
            .filter(Boolean)

          // Set the formatted data in context
          setTrayPlates(formattedTrayPlates)
        })
      }

      if (data.error?.length > 0) {
        console.error('Error: ', data.error)
        setAnalysisError(data.error)
        handleSubmissionError('Tray analysis failed. Please try again.')
        await saveAndNavigate(image, [], analysisDuration, data.error)
        return
      }

      if (!res.ok) {
        throw new Error('Submission failed')
      }

      if (data.tray_analysis?.tray_info?.tray_mfr === 'Zimmer') {
        setIsSubmitting(false)
        setIsComplete(true)
        return
      }

      const screwsFromAnalysis = removeDuplicateScrews(data.tray_analysis)

      const analysisResultScrews = extractTrayScrews(
        screwsFromAnalysis
          .filter((screw: AnalysisResult) => screw.removed === false)
          .map((screw: AnalysisResult) => ({
            label: screw.section_label,
            x: screw.column_index,
            column: screw.column_label,
            row: screw.row_index,
            wasted: screw.wasted,
          }))
      )
      setAnalysisScrews(analysisResultScrews)

      const trayScrews = extractTrayScrews(
        screwsFromAnalysis
          .filter((screw: AnalysisResult) => screw.removed === true)
          .map((screw: AnalysisResult) => ({
            label: screw.section_label,
            x: screw.column_index,
            column: screw.column_label,
            row: screw.row_index,
            wasted: screw.wasted,
          }))
      )

      if (trayScrews.length === 0) {
        handleSubmissionError('No screws were detected in this image')
      }
      await saveAndNavigate(image, trayScrews, analysisDuration)
    } catch (error) {
      console.error(error)
    }
  }

  const removeDuplicateScrews = (screws: AnalysisResult[]) => {
    const uniqueObjectsMap = new Map<string, AnalysisResult>()

    for (const obj of screws) {
      const key = `${obj.column_index}_${obj.row_index}_${obj.section_label}`
      uniqueObjectsMap.set(key, obj)
    }

    return Array.from(uniqueObjectsMap.values())
  }

  const handleSubmissionError = (errorMessage?: string) => {
    setIsSubmitting(false)
    setIsAlertOpen(true)
    setAlertData({
      description: errorMessage || 'Please try again with a different image.',
      mode: 'error',
    })
  }

  const extractTrayScrews = (data: any) => {
    localStorage.setItem('tray-products', JSON.stringify(data))
    const trayData = localStorage.getItem('tray-products') as string
    return JSON.parse(trayData)
  }

  const saveAndNavigate = async (
    image: string,
    trayScrews: TrayScrew[],
    processingTime: number,
    error: string = 'no error found'
  ) => {
    try {
      const analysisResults = trayScrews.map((screw: any) => ({
        column: screw.x,
        size: screw.column,
        label: screw.label,
        row: screw.row,
        wasted: screw.wasted,
      }))

      const newUsageScrews = trayScrews
        .map((trayScrew) => {
          const screwFromSPD = findScrew(
            trayScrew.label,
            trayScrew.row + 1,
            trayScrew.x
          )

          return screwFromSPD || null
        })
        .filter((screw) => screw !== null) as BetterIDTrayScrew[]

      setUsageScrews(newUsageScrews)

      const newTrayAnalysis: TrayAnalysisInput = {
        tray_img: image,
        analysis_results: analysisResults,
        user_corrections: [],
        surgery_id: surgeryId as Surgery['_id'],
        user_id: user?.id as string,
        error,
        processing_time: processingTime,
        accuracy_rate: 100,
      }

      await saveAnalysis(newTrayAnalysis)

      if (error === 'no error found') {
        setIsComplete(true)
        if (!isAnalysisFromSPD) {
          navigate('../tray-visualization')
        }
        return
      }
    } catch (error) {
      console.error('Failed to save analysis and navigate')
    }
  }

  const cancelSubmit = () => {
    abortController.controller.abort()
    setAborted(true)
    setIsSubmitting(false)
  }

  const resetSubmitTrayCapture = () => {
    setIsAnalysisFromSPD(false)
    setIsComplete(false)
    setAnalysisScrews([])
    setAnalysisError('')
    setAnalysisFileName('Stryker_2.0MP_Screws.json')
  }

  return {
    handleSubmit,
    submitImage,
    cancelSubmit,
    isAnalysisFromSPD,
    setIsAnalysisFromSPD,
    isComplete,
    analysisScrews,
    handleBase64UploadToS3,
    setIsComplete,
    analysisError,
    setAnalysisError,
    base64ToFile,
    analysisFileName,
    setAnalysisFileName,
    resetSubmitTrayCapture,
  }
}

export default useSubmitTrayCapture
