import React, { useState, useEffect, useRef } from 'react'
import useZimmerRodTrayMapLogic from './GrayScale.logic' // Import the logic hook
import './GrayScale.scss'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
// Stryker Mid Face
import midFaceImage from './IMG_2800_rectified.png'
import midFaceData from './IMG_2800_results.transformed.json'

// Stryker Upper Face
import upperFaceImage from './IMG_2766_rectified.png'
import upperFaceData from './IMG_2766.transformed.json'

// Zimmer Curved Rods
import zimmerCurvedImage from './IMG_3630_rectified.png'
import zimmerCurvedData from './IMG_3630_results.transformed.json'

// Stryker Screw Caddy
import screwCaddyImage from './screw.png'
import screwCaddyData from './screw.transformed.json'
import {
  GrayScaleTrayMapProps,
  ZimmerHardwareType,
  ScrewAnnotation,
  PlateAnnotation,
  DrillAnnotation,
  TrayData,
} from './GrayScale.types'
import useTrayMapLogic from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import zimmerAdjustableImage from './IMG_0945_rectified.png'
import zimmerAdjustableData from './IMG_0945.transformed.json'

const GrayScaleTrayMap = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
  trayType,
  setSelectedScrew,
  getPlateCount,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  mapContent,
}: GrayScaleTrayMapProps) => {
  const [screwAnnotations, setScrewAnnotations] = useState<ScrewAnnotation[]>(
    []
  )
  const [plateAnnotations, setPlateAnnotations] = useState<PlateAnnotation[]>(
    []
  )
  const [drillAnnotations, setDrillAnnotations] = useState<DrillAnnotation[]>(
    []
  )
  const [selectedAnnotation, setSelectedAnnotation] = useState<string | null>(
    null
  ) // Use string to uniquely identify items
  const [showCountTwo, setShowCountTwo] = useState<boolean>(false)
  const [originalWidth, setOriginalWidth] = useState<number | null>(null)
  const [originalHeight, setOriginalHeight] = useState<number | null>(null)
  const [backgroundImage, setBackgroundImage] = useState(upperFaceImage)
  const [universalJsonData, setUniversalJsonData] = useState<TrayData | null>(
    null
  )

  const { trayPlates, trayScrews, setTrayPlates, setTrayScrews } = useSPD()

  // Image scaling constants
  const containerWidth = 1200 // Desired display width
  const containerHeight = 700

  // Add ref for container
  const containerRef = useRef<HTMLDivElement>(null)

  // Constants for the default display size
  const DEFAULT_DISPLAY_WIDTH = 1200 // The width users typically see
  const BASE_CIRCLE_SIZES = {
    screw: { size: 20, font: 12 },
    plate: { size: 25, font: 14 },
    drill: { size: 25, font: 14 },
  }

  // Two separate scale states
  const [imageScale, setImageScale] = useState(1)
  const [uiScale, setUiScale] = useState(1)

  const { handleStatusChange, setIsLoadingWithUDIs, isLoadingWithUDIs } =
    useTrayMapLogic()

  // State to track the screw asset to handle
  const [pendingScrewAsset, setPendingScrewAsset] = useState<any>(null)

  useEffect(() => {
    if (pendingScrewAsset) {
      handleStatusChange(pendingScrewAsset)
      setPendingScrewAsset(null) // Reset after handling
    }
  }, [pendingScrewAsset])

  useEffect(() => {
    if (!containerRef.current || !originalWidth) return

    const updateScales = () => {
      const containerWidth = containerRef.current?.clientWidth || 0

      // Scale for image and coordinates (based on original dimensions)
      const newImageScale = containerWidth / originalWidth
      setImageScale(newImageScale)

      // Scale for UI elements (based on default display width)
      const newUiScale = containerWidth / DEFAULT_DISPLAY_WIDTH
      setUiScale(newUiScale)
    }

    updateScales()
    const resizeObserver = new ResizeObserver(updateScales)
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [originalWidth, containerRef.current])

  // Import tray logic methods
  const {
    handlePlateCountChange,
    shouldPlateIncreaseBeDisabled,
    setInitialTrayPlates,
    getPlateDI,
  } = useZimmerRodTrayMapLogic({ isInOR, setSelectedPlate, setModalOpen })

  useEffect(() => {
    if (trayType) {
      // Only set data if we have a trayType
      switch (trayType.toLowerCase()) {
        case 'stryker upper face':
          setBackgroundImage(upperFaceImage)
          setUniversalJsonData(upperFaceData)
          break
        case 'stryker middle face':
          setBackgroundImage(midFaceImage)
          setUniversalJsonData(midFaceData)
          break
        case 'zimmer curved rods':
          setBackgroundImage(zimmerCurvedImage)
          setUniversalJsonData(zimmerCurvedData)
          break
        case 'stryker screw caddy':
          setBackgroundImage(screwCaddyImage)
          setUniversalJsonData(screwCaddyData)
          break
        case 'zimmer adjustable transverse connector':
          setBackgroundImage(zimmerAdjustableImage)
          setUniversalJsonData(zimmerAdjustableData)
          break
        default:
          setBackgroundImage(upperFaceImage)
          setUniversalJsonData(upperFaceData)
      }
    }
  }, [trayType])

  // Initialize annotations based on universalJsonData
  useEffect(() => {
    if (universalJsonData) {
      const trayData = universalJsonData as {
        [key: string]: {
          screw?: ScrewAnnotation[]
          plate?: PlateAnnotation[]
          drill?: DrillAnnotation[]
        }
      }

      const firstKey = Object.keys(trayData)[0]
      const firstTrayData = firstKey ? trayData[firstKey] : null

      if (firstTrayData?.screw) {
        const mappedAnnotations = firstTrayData.screw.map((screw) => {
          const exists = trayScrews.some(
            (ts) =>
              ts.row === screw.row_label &&
              ts.column === screw.column_label &&
              ts.x === screw.column_index &&
              ts.label === screw.label
          )

          return {
            ...screw,
            count: exists ? 1 : 0,
          }
        })

        setScrewAnnotations(mappedAnnotations)
      }

      // Process plates
      if (firstTrayData?.plate && firstTrayData.plate.length > 0) {
        setPlateAnnotations(firstTrayData.plate)
      }

      // Process drills
      if (firstTrayData?.drill && firstTrayData.drill.length > 0) {
        setDrillAnnotations(firstTrayData.drill)
      }
    } else {
      console.error('Invalid universalJsonData format')
    }
  }, [universalJsonData, trayScrews])

  // Initialize trayScrews and trayPlates if empty
  useEffect(() => {
    if (trayPlates.length === 0 && plateAnnotations.length > 0) {
      const initialPlates = [
        ...plateAnnotations.map((a) => ({
          deviceId: getPlateDI(a.label),
          plateName: a.label as any,
          plateCount: a.count,
        })),
        ...drillAnnotations.map((a) => ({
          deviceId: getPlateDI(a.label),
          plateName: a.label as any,
          plateCount: a.count,
        })),
      ]
      setInitialTrayPlates(initialPlates)
    }
  }, [plateAnnotations, drillAnnotations])

  // Handle screw click (toggle count between 0 and 1)
  const handleScrewClick = (index: number) => {
    const annotation = screwAnnotations[index]

    const screwAsset = {
      label: annotation.label,
      row: annotation.row_label,
      column: annotation.column_label,
      x: annotation.column_index,
    }

    const exists = trayScrews.some(
      (ts) =>
        ts.row === annotation.row_label &&
        ts.column === annotation.column_label &&
        ts.x === annotation.column_index &&
        ts.label === annotation.label
    )

    if (annotation.count === 1) {
      if (setSelectedScrew) {
        setSelectedScrew(screwAsset)
      }

      if (setModalOpen) {
        setModalOpen(true)
      }

      setTrayScrews((prev) =>
        prev.filter(
          (ts) =>
            !(
              ts.row === annotation.row_label &&
              ts.column === annotation.column_label &&
              ts.x === annotation.column_index &&
              ts.label === annotation.label
            )
        )
      )

      setScrewAnnotations((prev) =>
        prev.map((screw, i) => (i === index ? { ...screw, count: 0 } : screw))
      )
    } else {
      // Adding screw (count 0 to 1)
      if (!exists) {
        setIsLoadingWithUDIs(false)
        setPendingScrewAsset(screwAsset)
        setScrewAnnotations((prev) =>
          prev.map((screw, i) => (i === index ? { ...screw, count: 1 } : screw))
        )
        setTrayScrews((prev) => [...prev, screwAsset])
      } else {
        setTrayScrews((prev) =>
          prev.filter(
            (ts) =>
              !(
                ts.row === annotation.row_label &&
                ts.column === annotation.column_label &&
                ts.x === annotation.column_index &&
                ts.label === annotation.label
              )
          )
        )

        setScrewAnnotations((prev) =>
          prev.map((screw, i) => (i === index ? { ...screw, count: 0 } : screw))
        )
      }
    }
  }

  // Handle plate and drill count changes
  const handleCountChange = (
    type: 'plate' | 'drill',
    index: number,
    change: number
  ) => {
    if (type === 'plate') {
      const plate = plateAnnotations[index]
      const plateName = plate.label
      const currentPlate = trayPlates.find((p) => p.plateName === plateName)

      // If decreasing count in OR mode
      if (change < 0 && isInOR && currentPlate) {
        if (setSelectedPlate) {
          setSelectedPlate(currentPlate)
        }
        if (setModalOpen) {
          setModalOpen(true)
        }
        return
      }

      // Normal count change (not decreasing in OR)
      const currentCount = currentPlate?.plateCount || 0
      const newCount = Math.max(0, currentCount + change)
      handlePlateCountChange(plateName as any, newCount)
    } else if (type === 'drill') {
      const drill = drillAnnotations[index]
      const drillName = drill.label
      const currentDrill = trayPlates.find((p) => p.plateName === drillName)

      // If decreasing count in OR mode
      if (change < 0 && isInOR && currentDrill) {
        if (setSelectedPlate) {
          setSelectedPlate(currentDrill)
        }
        if (setModalOpen) {
          setModalOpen(true)
        }
        return
      }

      // Normal count change (not decreasing in OR)
      const currentCount = currentDrill?.plateCount || 0
      const newCount = Math.max(0, currentCount + change)
      handlePlateCountChange(drillName as any, newCount)
    }
  }

  // Update dimensions from JSON data
  useEffect(() => {
    if (universalJsonData) {
      const trayData = universalJsonData as {
        [key: string]: {
          dimensions?: { width: number; height: number }
        }
      }

      const firstKey = Object.keys(trayData)[0]
      const dimensions = firstKey ? trayData[firstKey].dimensions : null

      if (dimensions) {
        setOriginalWidth(dimensions.width)
        setOriginalHeight(dimensions.height)
      }
    }
  }, [universalJsonData])

  // Function to handle count circle click
  const handleCountCircleClick = (type: 'plate' | 'drill', index: number) => {
    const uniqueId = `${type}-${index}`
    setSelectedAnnotation((prev) => (prev === uniqueId ? null : uniqueId))
  }

  useEffect(() => {
    if (mapContent) {
      if (!trayScrews?.length) {
        setTrayScrews(mapContent.screws || [])
      }
      if (!trayPlates?.length) {
        setTrayPlates(mapContent.plates || [])
      }
    }
  }, [mapContent])

  return (
    <div
      ref={containerRef}
      className="annotation-container"
      style={{
        aspectRatio:
          originalWidth && originalHeight
            ? `${originalWidth}/${originalHeight}`
            : 'auto',
      }}
    >
      <img src={backgroundImage} alt="Tray" className="gray-scale-tray-image" />
      {/* Render Screw Annotations */}
      {screwAnnotations.map((annotation, index) => {
        const {
          img_screw_center,
          img_screw_radius,
          label,
          column_label,
          row_label,
        } = annotation

        if (!img_screw_center || img_screw_radius === undefined) return null

        const [cx, cy] = img_screw_center
        const r = img_screw_radius

        const scaledCX = cx * imageScale
        const scaledCY = cy * imageScale
        const scaledRadius = r * imageScale

        const exists = trayScrews.some(
          (ts) =>
            ts.row === annotation.row_label &&
            ts.column === annotation.column_label
        )
        const displayCount = annotation.count

        return (
          <div
            key={`screw-${index}`}
            className="annotation-box screw"
            style={{
              left: `${scaledCX - scaledRadius}px`,
              top: `${scaledCY - scaledRadius}px`,
              width: `${scaledRadius * 2}px`,
              height: `${scaledRadius * 2}px`,
            }}
          >
            <div className="annotation-controls">
              <span
                className={`count-circle ${displayCount > 0 ? 'active' : ''}`}
                onClick={() => handleScrewClick(index)}
                style={{
                  width: `${BASE_CIRCLE_SIZES.screw.size * uiScale}px`,
                  height: `${BASE_CIRCLE_SIZES.screw.size * uiScale}px`,
                  fontSize: `${BASE_CIRCLE_SIZES.screw.font * uiScale}px`,
                }}
              >
                {displayCount}
              </span>
            </div>
          </div>
        )
      })}

      {/* Render Plate Annotations */}
      {plateAnnotations.map((annotation, index) => {
        const { label, img_UL_coords, img_LR_coords, removed, count } =
          annotation

        // Calculate scaled coordinates
        const [x1, y1] = img_UL_coords
        const [x2, y2] = img_LR_coords
        const scaledX1 = x1 * imageScale
        const scaledY1 = y1 * imageScale
        const scaledX2 = x2 * imageScale
        const scaledY2 = y2 * imageScale
        const width = scaledX2 - scaledX1
        const height = scaledY2 - scaledY1

        // Get current count from trayPlates
        const currentPlate = trayPlates.find(
          (plate) => plate.plateName === label
        )
        const displayCount = currentPlate?.plateCount || 0

        const uniqueId = `plate-${index}`
        const isSelected = selectedAnnotation === uniqueId

        return (
          <div
            key={`plate-${index}`}
            className="annotation-box plate"
            style={{
              left: `${scaledX1}px`,
              top: `${scaledY1}px`,
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div className="annotation-controls">
              {isSelected && (
                <div className="button-container">
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('plate', index, 1)}
                    disabled={shouldPlateIncreaseBeDisabled(label)}
                  >
                    +
                  </button>
                  <span
                    className={`count-circle ${
                      displayCount > 0 ? 'active' : ''
                    } selected`}
                    onClick={() => handleCountCircleClick('plate', index)}
                    style={{
                      width: `${BASE_CIRCLE_SIZES.plate.size * uiScale}px`,
                      height: `${BASE_CIRCLE_SIZES.plate.size * uiScale}px`,
                      fontSize: `${BASE_CIRCLE_SIZES.plate.font * uiScale}px`,
                    }}
                  >
                    {displayCount}
                  </span>
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('plate', index, -1)}
                    disabled={displayCount <= 0}
                  >
                    -
                  </button>
                </div>
              )}
              {!isSelected && (
                <span
                  className={`count-circle ${displayCount > 0 ? 'active' : ''}`}
                  onClick={() => handleCountCircleClick('plate', index)}
                  style={{
                    width: `${BASE_CIRCLE_SIZES.plate.size * uiScale}px`,
                    height: `${BASE_CIRCLE_SIZES.plate.size * uiScale}px`,
                    fontSize: `${BASE_CIRCLE_SIZES.plate.font * uiScale}px`,
                  }}
                >
                  {displayCount}
                </span>
              )}
            </div>
          </div>
        )
      })}

      {/* Render Drill Annotations */}
      {drillAnnotations.map((annotation, index) => {
        const { label, img_UL_coords, img_LR_coords, removed, count } =
          annotation

        // Calculate scaled coordinates
        const [x1, y1] = img_UL_coords
        const [x2, y2] = img_LR_coords
        const scaledX1 = x1 * imageScale
        const scaledY1 = y1 * imageScale
        const scaledX2 = x2 * imageScale
        const scaledY2 = y2 * imageScale
        const width = scaledX2 - scaledX1
        const height = scaledY2 - scaledY1

        const currentDrill = trayPlates.find(
          (plate) => plate.plateName === label
        )
        const displayCount = currentDrill?.plateCount || 0

        const uniqueId = `drill-${index}`
        const isSelected = selectedAnnotation === uniqueId

        return (
          <div
            key={`drill-${index}`}
            className="annotation-box drill"
            style={{
              left: `${scaledX1}px`,
              top: `${scaledY1}px`,
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div className="annotation-controls">
              {isSelected && (
                <div className="button-container">
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('drill', index, 1)}
                    disabled={isInOR}
                  >
                    +
                  </button>
                  <span
                    className={`count-circle ${
                      displayCount > 0 ? 'active' : ''
                    } selected`}
                    onClick={() => handleCountCircleClick('drill', index)}
                    style={{
                      width: `${BASE_CIRCLE_SIZES.drill.size * uiScale}px`,
                      height: `${BASE_CIRCLE_SIZES.drill.size * uiScale}px`,
                      fontSize: `${BASE_CIRCLE_SIZES.drill.font * uiScale}px`,
                    }}
                  >
                    {displayCount}
                  </span>
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('drill', index, -1)}
                    disabled={displayCount <= 0}
                  >
                    -
                  </button>
                </div>
              )}
              {!isSelected && (
                <span
                  className={`count-circle ${displayCount > 0 ? 'active' : ''}`}
                  onClick={() => handleCountCircleClick('drill', index)}
                  style={{
                    width: `${BASE_CIRCLE_SIZES.drill.size * uiScale}px`,
                    height: `${BASE_CIRCLE_SIZES.drill.size * uiScale}px`,
                    fontSize: `${BASE_CIRCLE_SIZES.drill.font * uiScale}px`,
                  }}
                >
                  {displayCount}
                </span>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default GrayScaleTrayMap
