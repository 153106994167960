import { Typography } from '@mui/material'
import { Delete, Visibility } from '@mui/icons-material'
import Button from 'components/molecules/Button/Button'
import { useAddTrayContext } from '../AddTray.context'
import { LoadingButton } from 'components/mui'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { MappedSubTrays, SubTray } from './SubTrays.types'
import { formatToTitleCase } from 'lib/utils/formatToTitleCase'
import { useTrayStepContext } from '../AddTray.logic'
import { useSubTraysContext } from './SubTrays.context'

const useSubTraysLogic = () => {
  const { subTrays, setSubTrays, setSelectedTrayType } = useAddTrayContext()
  const { nextStep, prevStep } = useTrayStepContext()
  const {
    currentView,
    setCurrentView,
    selectedTrayIndex,
    setSelectedTrayIndex,
  } = useSubTraysContext()

  /**
   * Defines the header cells for the tray management table.
   */
  const subTraysTableHeaderCells: HeadCell[] = [
    { id: 'trayNumber', label: 'ID', numeric: true },
    { id: 'trayImg', label: 'Tray Image', numeric: false },
    { id: 'trayType', label: 'Tray Type', numeric: false },
    { id: 'parLevel', label: 'Par Level', numeric: false },
    { id: 'viewDetails', label: 'Tray Details', numeric: false },
    { id: 'deleteSubTray', label: 'Delete Sub-Tray', numeric: false },
  ]

  /**
   * Maps locally created sub trays into structured cells for the sub trays table body.
   */
  const subTraysTableBodyCells = (subTrays: SubTray[]): MappedSubTrays[] => {
    return subTrays.map((subTray, _index) => {
      return {
        trayNumber: `${_index + 1}`,
        trayImg: <img className="tray_img" src={subTray.trayImg} />,
        trayType: (
          <Typography variant="body1">
            {formatToTitleCase(subTray.trayType || 'Unknown Tray Type')}
          </Typography>
        ),
        parLevel: `${subTray.parLevel}`,
        viewDetails: (
          <Button
            startIcon={<Visibility />}
            onClick={() => handleViewDetailsClick(_index)}
          >
            View Details
          </Button>
        ),
        deleteSubTray: (
          <LoadingButton
            className="delete-sub-tray-button"
            mode="delete"
            sx={{ width: '30px', minWidth: '0px', maxWidth: '30px' }}
            onClick={() => handleDeleteSubTray(_index)}
          >
            <Delete sx={{ width: '17px', height: '17px' }} />
          </LoadingButton>
        ),
      }
    })
  }

  /**
   * Function to handle the "Add Instrument Tray" button click.
   */
  const handleAddInstrumentTrayClick = () => {
    setSelectedTrayType('instrument')
    setCurrentView('analyze')
  }

  /**
   * Function to handle the "Add Implant Tray" button click.
   */
  const handleAddImplantTrayClick = () => {
    setCurrentView('analyze')
  }

  /**
   * Function to handle the "Add Instrument w/ Sub Trays" button click.
   */
  const handleAddInstrumentWithSubTraysClick = () => {
    setCurrentView('analyze')
  }

  /**
   * Handles the event when the user clicks to view details of a specific sub-tray.
   */
  const handleViewDetailsClick = (subTrayIndex: number) => {
    setSelectedTrayIndex(subTrayIndex)
    setCurrentView('details')
  }

  const handleDeleteSubTray = (subTrayIndex: number) => {
    const updatedSubTrays = subTrays.filter(
      (_subTray, _index) => _index !== subTrayIndex
    )
    setSubTrays(updatedSubTrays)
  }

  const handleNext = () => {
    if (subTrays.length > 0) {
      nextStep()
    }
  }

  const handleBack = () => {
    prevStep()
  }

  return {
    handleAddInstrumentTrayClick,
    handleAddImplantTrayClick,
    handleAddInstrumentWithSubTraysClick,
    subTraysTableHeaderCells,
    subTraysTableBodyCells,
    handleNext,
    handleBack,
    currentView,
    selectedTrayIndex,
  }
}

export default useSubTraysLogic
