import { FormControl, Skeleton, TextField, Typography } from '@mui/material'
import { CameraAlt, FlipCameraIos, Replay } from '@mui/icons-material'
import { Player } from '@lottiefiles/react-lottie-player'

import { LoadingButton } from 'components/mui'

import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import { useSPDScan } from '../../../views/SPDLayout/SPD/Scan/Scan.context'
import useCaptureTrayDialog from './CaptureTrayDialog.logic'

import './CaptureTrayDialog.scss'
import { CaptureTrayDialogProps } from './CaptureTrayDailog.types'

const CaptureTrayDialog = ({ onNext }: CaptureTrayDialogProps) => {
  const { productData } = useSPDScan()
  const {
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSelectCamera,
    manualImage,
    handleManualImageChange,
    isValidUrl,
    handleSubmitClick,
    handleCapture,
  } = useCaptureTrayDialog({ onNext })
  const { isSubmitting } = useCaptureTrayContext()

  return (
    <div className="capture-tray">
      <div className="product-container">
        <Typography variant="h1" className="description">
          {productData[0]?.deviceDescription}
        </Typography>
        <Typography variant="body1">
          Company: {productData[0]?.company?.name}
        </Typography>
        <Typography variant="body1">
          Device count: {productData[0]?.deviceCount}
        </Typography>

        <div className="manual-input_container">
          <TextField
            fullWidth
            name="trayImageURL"
            placeholder="Tray image URL"
            value={manualImage}
            onChange={handleManualImageChange}
          />

          {!isValidUrl && manualImage && (
            <Typography variant="body2" className="image-error">
              Invalid image URL
            </Typography>
          )}

          {isValidUrl && manualImage && (
            <div className="manual-image_container">
              <Skeleton className="image-loading_background" />
              <img src={manualImage} alt="Tray" />
            </div>
          )}
        </div>
      </div>

      {image.length > 0 && (
        <img className="captured-img" src={image} alt="Tray" />
      )}

      <div className="camera-container">
        {videoRef === null || mediaStream === null ? (
          <Skeleton className="feed" />
        ) : (
          <>
            <FormControl fullWidth className="camera-select_container">
              <LoadingButton
                loading={false}
                disabled={false}
                onClick={handleToggleCameraMenu}
                sx={{ zIndex: 9999 }}
              >
                <FlipCameraIos />
              </LoadingButton>

              <div
                className="camera-options"
                style={{
                  display: isCameraMenuOpen ? 'flex' : 'none',
                  opacity: isCameraMenuOpen ? 1 : 0,
                }}
              >
                {cameras.map((camera: any) => (
                  <div
                    key={camera.deviceId}
                    className="camera"
                    style={{
                      backgroundColor:
                        camera.deviceId === activeCamera?.deviceId
                          ? '#5035ED'
                          : '#BCB7E1',
                    }}
                    onClick={() => handleSelectCamera(camera)}
                  >
                    <p
                      className="label"
                      style={{
                        color:
                          camera.deviceId === activeCamera?.deviceId
                            ? 'white'
                            : '#11142D',
                      }}
                    >
                      {camera.label}
                    </p>

                    <p
                      className="type"
                      style={{
                        color:
                          camera.deviceId === activeCamera?.deviceId
                            ? 'white'
                            : '#11142D',
                      }}
                    >
                      {camera.cameraType}
                    </p>
                  </div>
                ))}
              </div>
            </FormControl>

            <LoadingButton className="capture" onClick={handleCapture}>
              {image ? (
                <Replay className="icon" />
              ) : (
                <CameraAlt className="icon" />
              )}
            </LoadingButton>

            <video className="feed" ref={videoRef} autoPlay muted playsInline />
          </>
        )}

        {isSubmitting && (
          <div className="loading-atom_container">
            <Player
              autoplay
              loop
              src="https://lottie.host/70fc766e-3d02-4e03-be88-a640ee0daaed/ewUXiG4fm5.json"
              className="loading-atom"
            />
          </div>
        )}

        {image.length > 0 || manualImage.length > 0 ? (
          <div className="submit-button_container">
            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              variant="contained"
              onClick={handleSubmitClick}
              className="submit"
            >
              Digitize
            </LoadingButton>
          </div>
        ) : (
          !isSubmitting && (
            <Typography variant="h2" className="description" mt="30px">
              Please place your captured product in the tray and capture an
              image
            </Typography>
          )
        )}
      </div>
    </div>
  )
}

export default CaptureTrayDialog
