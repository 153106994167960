/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import { useInventorySheetsContext } from './InventorySheets.context'
import { InventorySheetFile, MimeType } from './InventorySheets.types'
import PDFViewer from 'components/organisms/PDFViewer/PDFViewer'

const useInventorySheetsLogic = () => {
  const {
    handleSPDCaptureImage: handleImageCapture,
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()

  const {
    inventorySheetFiles,
    setInventorySheetFiles,
    addFileToInventorySheets,
  } = useInventorySheetsContext()

  const areInventorySheetsEmpty = inventorySheetFiles.length === 0
  const isThereAnImageUploading = inventorySheetFiles.some(
    (file) => file.isUploading
  )

  useEffect(() => {
    if (image) {
      addFileToInventorySheets(image, 'image/jpeg')
    }
  }, [image])

  /**
   * Deletes an image from the inventory sheet images list.
   * @param {string} imgId - The ID of the image to delete.
   */
  const handleDeleteImg = (imgId: string) => {
    const newImgs = inventorySheetFiles.filter(
      (inventorySheet) => inventorySheet.id !== imgId
    )

    setInventorySheetFiles(newImgs)
  }

  /**
   * Converts a file to a base64 string.
   * @param {File} file - The file to convert.
   * @returns {Promise<string>} - A promise that resolves with the base64 string.
   */
  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  /**
   * Handles the file upload event, converting the file to a base64 string and adding it to inventory sheets.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The file input change event.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      try {
        const base64 = await convertFileToBase64(file)
        addFileToInventorySheets(base64, file.type as MimeType)
      } catch (error) {
        console.error('Error converting file to base64:', error)
      }
    }
  }

  /**
   * Renders a preview component based on the file's MIME type.
   *
   * @param {InventorySheetFile} file - The file object containing information like source and MIME type.
   * @returns {JSX.Element} - The JSX element representing the preview component.
   */
  const renderFilePreview = (file: InventorySheetFile): JSX.Element => {
    switch (file.mimeType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <img src={file.src} alt="preview" width="100" />
      case 'application/pdf':
        return <PDFViewer fileUrl={file.src} />
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Excel File
          </a>
        )
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Word Document
          </a>
        )
      default:
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Download File
          </a>
        )
    }
  }

  return {
    videoRef,
    mediaStream,
    isCameraMenuOpen,
    cameras,
    handleToggleCameraMenu,
    activeCamera,
    handleSelectCamera,
    inventorySheetFiles,
    handleImageCapture,
    handleDeleteImg,
    areInventorySheetsEmpty,
    handleFileUpload,
    renderFilePreview,
    isThereAnImageUploading,
  }
}

export default useInventorySheetsLogic
