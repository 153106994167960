import { Button, Typography } from '@mui/material'
import { useUser } from 'app/User'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState, useEffect, FC } from 'react'
import { EventStatusProps } from './ProcedureList.types'

const EventStatus: FC<EventStatusProps> = ({
  isScans,
  surgeryStatus,
  isTestProcedure,
  hasUndocumentedScans,
  implantReportStatus,
  isStarted,
  addendumStatus,
}) => {
  const { isNurse, isRep } = useUser()
  const flags = useFlags()

  const [isReportSent, setIsReportSent] = useState(false)

  useEffect(() => {
    if (implantReportStatus) {
      const reportSent = Object.values(implantReportStatus).some(
        (status) => status.isSent
      )
      setIsReportSent(reportSent)
    }
  }, [implantReportStatus])

  const isNotProduction = process.env.REACT_APP_NODE_ENV !== 'production'

  let color = 'warning'
  let procedureStatusText = 'In progress'
  let buttonText = 'Resume case'

  if (surgeryStatus === 'SUBMITTED') {
    color = 'primary'
    procedureStatusText = 'Record closed'
    buttonText = 'View record'
  }

  if (
    surgeryStatus === 'SCANNING' &&
    !isScans &&
    !hasUndocumentedScans &&
    !isStarted
  ) {
    color = 'success'
    procedureStatusText = ''

    if (isNurse || isRep) {
      buttonText = 'Start case'
    }
  }

  if (isTestProcedure && isNotProduction) {
    procedureStatusText = `${procedureStatusText} - TEST PROCEDURE`
    color = 'error'
    buttonText = `${buttonText} - TEST`
  }

  return (
    <>
      <Typography
        data-testid="event-status-text"
        variant="subtitle2"
        sx={{ mb: 1, color: `${color}.dark` }}
      >
        {procedureStatusText}
      </Typography>

      <Button
        data-testid="event-status-button"
        disableElevation
        fullWidth
        size="small"
        sx={{
          backgroundColor: `${color}.lightest`,
          color: `${color}.dark`,
          '&:hover': {
            backgroundColor: `${color}.lightest`,
          },
        }}
      >
        {buttonText}
      </Button>

      {addendumStatus && surgeryStatus === 'SUBMITTED' && (
        <Typography
          data-testid="event-status-text"
          variant="subtitle2"
          sx={{ mt: 1, color: 'success.dark' }}
        >
          Addendum added
        </Typography>
      )}

      {procedureStatusText === 'Record closed' &&
        flags.implantSurgeryReports !== false && (
          <Typography
            variant="subtitle2"
            sx={{
              mt: 1,
              color: `${isReportSent ? 'success.dark' : 'error.dark'}`,
              textTransform: 'capitalize',
            }}
          >
            {isReportSent ? 'Report sent' : 'Report not sent'}
          </Typography>
        )}
    </>
  )
}

export default EventStatus
