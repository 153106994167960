import {
  useCreateScrubTechnicianMutation,
  useEditScrubTechnicianMutation,
} from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  scrubTechnicianDetailsFormSchema,
  TNewScrubTechnicianState,
} from './NewScrubTechnician.types'
import { getZodError } from 'common/utils'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { enqueueSnackbar } from 'notistack'
import { SCRUB_TECHNICIANS } from 'app/ROUTES_CONSTANTS'
import { CustomChangeEvent, TScrubTechnician } from 'common/types'

export const useNewScrubTechnicianLogic = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const state = useLocation().state as TNewScrubTechnicianState
  const { firstName, lastName, edit, speciality, email, confirmEmail, _id } =
    state

  const [formData, setFormData] = useState<TScrubTechnician>({
    _id,
    firstName,
    lastName,
    speciality,
    email,
    confirmEmail,
  })

  const [formErrors, setFormErrors] =
    useState<Partial<Record<keyof TScrubTechnician, string>>>()

  const [editScrubTechnician, { loading: editLoading }] =
    useEditScrubTechnicianMutation()

  const [createScrubTechnician, { loading: addLoading }] =
    useCreateScrubTechnicianMutation()

  const isLoading = edit ? editLoading : addLoading
  const headerTitle = `Scrub Technicians / ${
    edit
      ? `Edit ${!isMobile ? 'Scrub Technician' : ''}`
      : `Add ${!isMobile ? 'Scrub Technician' : ''}`
  }`
  const successAlertTitle = `Scrub Technician was ${
    edit ? 'updated' : 'created'
  } successfully`

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = scrubTechnicianDetailsFormSchema.safeParse(formData)
    if (!result.success) {
      setFormErrors(getZodError(result.error))
      return
    }

    const { confirmEmail, ...restFormData } = formData
    const formDataToSend = {
      ...restFormData,
      firstName: restFormData.firstName.toLowerCase(),
      lastName: restFormData.lastName.toLowerCase(),
      email: restFormData.email.toLowerCase(),
    }

    edit
      ? editScrubTechnician({
          variables: {
            editScrubTechnicianInput: formDataToSend,
          },
        })
          .then((response) => {
            if (response.data?.editScrubTechnician.success) {
              enqueueSnackbar(successAlertTitle, { variant: 'success' })
              navigate(`/${SCRUB_TECHNICIANS}`)
            } else {
              throw new Error(response.data?.editScrubTechnician.message)
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' })
          })
      : createScrubTechnician({
          variables: {
            createScrubTechnicianInput: formDataToSend,
          },
        })
          .then((response) => {
            console.log(response)
            if (response.data?.createScrubTechnician.success) {
              enqueueSnackbar(successAlertTitle, { variant: 'success' })
              navigate(`/${SCRUB_TECHNICIANS}`)
            } else {
              throw new Error(response.data?.createScrubTechnician.message)
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              error.message.includes('E11000')
                ? 'Scrub technician first name, last name and email already exists!'
                : error.message,
              { variant: 'error' }
            )
          })
  }

  const handleChangeUpdateScrubTechnicianDetails = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | CustomChangeEvent
  ) => {
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: '',
    }))
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    console.log(formData.speciality)
  }, [formData.speciality])

  return {
    formData,
    isLoading,
    formErrors,
    headerTitle,
    navigate,
    handleFormSubmit,
    handleChangeUpdateScrubTechnicianDetails,
  }
}
