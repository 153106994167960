import { CaptureTrayDialogProps } from 'components/organisms/CaptureTrayDialog/CaptureTrayDailog.types'
import { useSPDScan } from './Scan.context'
import { useEffect } from 'react'

const useScanLogic = ({ onNext }: CaptureTrayDialogProps) => {
  const { isDrawerOpen } = useSPDScan()

  useEffect(() => {
    if (isDrawerOpen) {
      onNext()
    }
  }, [isDrawerOpen, onNext])
}

export default useScanLogic
