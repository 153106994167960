import { Button, CircularProgress, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'

import useTrayFilesLogic from './TrayFiles.logic'

import './TrayFiles.scss'

const TrayFiles = () => {
  const { trayId } = useParams()
  const {
    loading,
    error,
    trayDetails,
    inventorySheetFiles,
    renderFilePreview,
  } = useTrayFilesLogic()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Typography color="error">{error.message}</Typography>
  }

  return (
    <div className="tray-files-page_container">
      <div className="files_container">
        <div className="header_wrapper">
          <Button className="back-button" component={Link} to={`../${trayId}`}>
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <Typography variant="h1">
            {trayDetails?.trayCategory !== 'facility' && 'IFU Files +'}{' '}
            Inventory Sheets
          </Typography>
        </div>

        {inventorySheetFiles.length === 0 ? (
          <Typography variant="body1">
            No inventory sheets available for this tray.
          </Typography>
        ) : (
          <div className="files_wrapper">
            {inventorySheetFiles.map((file) => (
              <div key={file.id} className="inventory-sheet_file">
                {renderFilePreview(file)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TrayFiles
