import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from 'app/Auth'
import { useAddTrayContext } from '../AddTray.context'
import { useInventorySheetsContext } from '../InventorySheets/InventorySheets.context'
import { useTrayStepContext } from '../AddTray.logic'
import useTrayConfiguration from 'views/TrayConfiguration/TrayConfiguration.logic'
import { useCreateTrayItemMutation } from 'lib/apollo/hooks'
import { CreateTrayItemInput } from 'common/types'
import { useUser } from 'app/User'
import dayjs from 'lib/dayjs'
import { printSticker } from 'components/organisms/TraySticker/TraySticker'
import { SubTray } from '../SubTrays/SubTrays.types'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useReviewLogic = () => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const { prevStep, reset } = useTrayStepContext()
  const { user } = useUser()
  const { findLocation } = useTrayConfiguration()
  const { createTrayItem, loading: isCreatingTrayItem } =
    useCreateTrayItemMutation()

  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout | null>(null)
  const [hasClickedPrint, setHasClickedPrint] = useState(false)

  const {
    trayBarcode,
    selectedLocationId,
    trayVendor,
    trayDescription,
    trayWeight,
    selectedTrayCategory,
    subTrays,
    traySurgery,
    traySurgeryId,
    selectedSurgeon,
    resetAddTrayContext,
    trayAnalysisScrews,
    selectedTrayType,
  } = useAddTrayContext()

  const { trayPlates } = useSPD()

  const { inventorySheetFiles, resetInventorySheets } =
    useInventorySheetsContext()

  const locationName = selectedLocationId
    ? findLocation(selectedLocationId)?.name
    : ''

  const trayDetails = {
    trayBarcode,
    locationName,
    trayVendor,
    trayDescription,
    trayWeight,
    trayCategory: selectedTrayCategory,
    subTrays,
    inventorySheets: inventorySheetFiles,
  }

  const implantTrays: SubTray[] = subTrays.filter(
    (subTray) => subTray.trayType !== 'instrument'
  )
  const hasImplantTrays: boolean = implantTrays.length > 0
  const firstImplantTray: SubTray | undefined = hasImplantTrays
    ? implantTrays[0]
    : undefined

  const createTrayItemInput = useCallback((): CreateTrayItemInput => {
    return {
      companyName: trayVendor,
      locationId: selectedLocationId as number,
      description: trayDescription,
      barcode: trayBarcode,
      isConsigned: selectedTrayCategory === 'consigned',
      productDetails: JSON.stringify({
        screws: trayAnalysisScrews,
        surgeryId: traySurgeryId,
        surgeryDetails: traySurgery,
        trayCategory: selectedTrayCategory,
        trayImage: firstImplantTray?.trayImg,
        inventorySheetFiles: inventorySheetFiles.map((file) => ({
          src: file.src,
          mimeType: file.mimeType,
        })),
        trayWeight,
        trayType: selectedTrayType,
        plates: trayPlates,
        status:
          selectedTrayCategory === 'facility'
            ? traySurgery
              ? 'assigned'
              : 'unAssigned'
            : 'droppedOff',
        statusDetails: {
          lastUpdated: dayjs().toISOString(),
          lastUpdatedBy: user?.email,
        },
        selectedSurgeon,
      }),
      quantity: 1,
      subTrays: subTrays.map((subTray) => ({
        parLevel: subTray.parLevel,
        trayImg: subTray.trayImg,
        trayType: subTray.trayType,
        description: subTray.description,
        content: JSON.stringify(subTray.content),
      })),
    }
  }, [
    trayVendor,
    selectedLocationId,
    trayDescription,
    trayBarcode,
    selectedTrayCategory,
    trayAnalysisScrews,
    traySurgeryId,
    traySurgery,
    firstImplantTray?.trayImg,
    inventorySheetFiles,
    trayWeight,
    selectedTrayType,
    user?.email,
    selectedSurgeon,
    subTrays,
    trayPlates,
  ])

  const handleSubmit = async () => {
    const itemInput = createTrayItemInput()
    try {
      await createTrayItem(itemInput)
      if (user?.email === 'guest@medgeo.com') {
        setShowLogoutModal(true)
        startLogoutTimer()
      }
    } catch (error) {
      console.error('Error creating tray item:', error)
    }
  }

  const handleBack = () => {
    prevStep()
  }

  const handleDone = async () => {
    await handleSubmit()
    if (user?.email !== 'guest@medgeo.com') {
      navigate('/tray-management')
    }
  }

  const handleAddAnother = async () => {
    await handleSubmit()
    resetForm()
    reset()
  }

  const handleModalClose = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer)
    }
    setShowLogoutModal(false)
  }

  const startLogoutTimer = () => {
    const timer = setTimeout(() => {
      handleLogout()
    }, 15000) // 15 seconds
    setLogoutTimer(timer)
  }

  const handleLogout = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer)
    }
    logout({ returnTo: `${window.location.origin}/?status=loggedout` })
  }

  const resetForm = () => {
    // Reset all context values
    resetAddTrayContext()

    // Reset inventory sheets
    resetInventorySheets()

    // Reset modal state
    handleModalClose()

    // Reset print state
    setHasClickedPrint(false)
  }

  const handlePrintLabel = () => {
    printSticker({
      id: trayBarcode,
      vendor: trayVendor,
      description: trayDescription,
      ownership: selectedTrayCategory || 'facility',
      type: 'implantable',
    })
    setHasClickedPrint(true)
  }

  return {
    trayDetails,
    handleBack,
    handleDone,
    handleAddAnother,
    isCreatingTrayItem,
    showLogoutModal,
    handleModalClose,
    handleLogout,
    handlePrintLabel,
    hasClickedPrint,
  }
}

export default useReviewLogic
