import { Button, CircularProgress, Typography, Paper } from '@mui/material'
import { AttachFile, CameraAlt, FlipCameraIos } from '@mui/icons-material'
import classNames from 'classnames'

import { LoadingButton } from 'components/mui'
import useInventorySheetsLogic from './InventorySheets.logic'
import { useUser } from 'app/User'
import { useTrayStepContext } from '../AddTray.logic'

import './InventorySheets.scss'

const InventorySheets = () => {
  const { isRep } = useUser()
  const { prevStep, nextStep } = useTrayStepContext()
  const {
    videoRef,
    isCameraMenuOpen,
    cameras,
    handleToggleCameraMenu,
    activeCamera,
    handleSelectCamera,
    handleImageCapture,
    inventorySheetFiles,
    handleDeleteImg,
    areInventorySheetsEmpty,
    handleFileUpload,
    renderFilePreview,
    isThereAnImageUploading,
  } = useInventorySheetsLogic()

  return (
    <>
      <Paper className="inventory-sheets-step step-item">
        <div className="inventory-sheets-step_content">
          <div className="camera-section">
            <div className="camera-container">
              <div className="camera-select_container">
                <LoadingButton
                  className="camera-menu_button"
                  onClick={handleToggleCameraMenu}
                  variant="contained"
                  color="inherit"
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                  <FlipCameraIos />
                </LoadingButton>

                <div
                  className="camera-options"
                  style={{
                    display: isCameraMenuOpen ? 'flex' : 'none',
                    opacity: isCameraMenuOpen ? 1 : 0,
                  }}
                >
                  {cameras.map((camera) => (
                    <div
                      key={camera.deviceId}
                      className={classNames('camera', {
                        active: camera.deviceId === activeCamera?.deviceId,
                      })}
                      onClick={() => handleSelectCamera(camera)}
                    >
                      <Typography variant="body2" className="camera-text">
                        {camera.label}
                      </Typography>

                      <Typography variant="body2" className="camera-text">
                        {camera?.cameraType}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>

              <LoadingButton
                onClick={() => handleImageCapture(true)}
                className="capture-photo_button"
                variant="contained"
                color="inherit"
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
              >
                <CameraAlt />
              </LoadingButton>

              <video
                className="feed"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
            </div>

            <div className="file-attachment_container">
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg, application/pdf, image/tiff, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel"
                onChange={handleFileUpload}
                id="file-upload"
              />

              <label htmlFor="file-upload">
                <LoadingButton component="span" startIcon={<AttachFile />}>
                  Attach Inventory Sheet
                </LoadingButton>
              </label>
            </div>
          </div>

          <div className="inventory-sheets-section">
            {areInventorySheetsEmpty ? (
              <Typography variant="body1" className="empty-state">
                Start Capturing Your Inventory Sheets
              </Typography>
            ) : (
              <div className="sheets-grid">
                {inventorySheetFiles.map((file) => (
                  <div key={file.id} className="inventory-sheet_item">
                    {file.isUploading && (
                      <div className="loading_container">
                        <CircularProgress className="loader" />
                      </div>
                    )}

                    {renderFilePreview(file)}

                    <LoadingButton
                      className="delete_button"
                      onClick={() => handleDeleteImg(file.id)}
                    >
                      <span className="delete_icon">×</span>
                    </LoadingButton>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Paper>
      <div className="buttons_container">
        <Button variant="outlined" onClick={prevStep} fullWidth>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          disabled={
            isThereAnImageUploading ||
            (isRep && inventorySheetFiles.length === 0)
          }
          onClick={nextStep}
          fullWidth
        >
          {inventorySheetFiles.length === 0 ? 'Skip' : 'Next'}
        </LoadingButton>
      </div>
    </>
  )
}

export default InventorySheets
