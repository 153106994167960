import { Info } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Modal from 'components/molecules/Modal/Modal'
import { TrayRejectionDetailsModalProps } from './TrayRejectionDetailsModal.types'
import dayjs from 'lib/dayjs'
import './TrayRejectionDetailsModal.scss'

const TrayRejectionDetailsModal = ({
  isOpen,
  onClose,
  rejectionDetails,
}: TrayRejectionDetailsModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="tray-rejection-details-modal_organism">
        <div className="header-box">
          <Info />
          <Typography variant="h3">Tray Rejection Details</Typography>
        </div>

        <div className="content-box">
          <Typography variant="body1">
            Rejection Reason: {rejectionDetails?.rejectionReason}
          </Typography>

          <Typography variant="subtitle2">
            Rejected At:{' '}
            {rejectionDetails?.rejectedAt
              ? dayjs(rejectionDetails.rejectedAt).format('DD/MM/YYYY h:mm A')
              : 'N/A'}
          </Typography>

          <Typography variant="subtitle2">
            Rejected By: {rejectionDetails?.rejectedBy}
          </Typography>
        </div>
      </div>
    </Modal>
  )
}

export default TrayRejectionDetailsModal
