import { ArrowBack } from '@mui/icons-material'
import {
  CircularProgress,
  Skeleton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'

import ConfirmChangesModal from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/Modals/ConfirmChangesModal/ConfirmChangesModal'
import { LoadingButton } from 'components/mui'

import useEditTray from './EditTray.logic'

import './EditTray.scss'
import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'
import { ConfirmLoadMultipleScrewsModal } from 'components/organisms/ConfirmLoadMultipleScrewsModal/ConfirmLoadMultipleScrewsModal'
import GrayScale from 'components/organisms/GrayScale/GrayScale'
import ZimmerTransverseConnectorsTrayMap from 'components/organisms/ZimmerTransverseConnectorsTrayMap/ZimmerTransverseConnectorsTrayMap'
import ZimmerStandardClosureTopsTrayMap from 'components/organisms/ZimmerStandardClosureTopsTrayMap/ZimmerStandardClosureTopsTrayMap'
import { BackOrButton } from 'components/BackOrButton'
import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'

const GRAYSCALE_TRAY_TYPES = [
  'zimmer curved rods',
  'stryker screw caddy',
  'stryker upper face',
  'stryker middle face',
  'zimmer adjustable transverse connector',
]

const EditTray = ({ isReviewing }: { isReviewing: boolean }) => {
  const {
    isInEditMode,
    handleEditModeChange,
    loading,
    error,
    subTrayItem,
    trayDetails,
    handleViewDetailsClick,
    isConfirmationOpen,
    handleConfirmationClick,
    handleLoadWithUDIsClick,
    isLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
    isRejecting,
    handleUserConfirmation,
    handleConfirmationClose,
    isUpdating,
  } = useEditTray({ isReviewing })
  const { trayId } = useParams<{ trayId: string }>()

  return (
    <div className="edit-tray_wrapper">
      <div className="header contained-desktop">
        <div className="header_actions">
          <BackOrButton
            variant="text"
            className="back_button"
            sx={{ width: 'auto' }}
            fallbackUrl={`/tray-management/${trayId}`}
            fallbackChildren={
              <>
                <ArrowBack sx={{ mr: 0.5 }} /> BACK
              </>
            }
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </BackOrButton>
        </div>
      </div>

      <div className="contained-desktop">
        <Typography variant="h1">Sub-Tray Details</Typography>

        <div className="edit-tray_header">
          <div className="left_container">
            <Typography className="tray-details_detail">
              Sub-Tray ID: <strong>{subTrayItem?.id}</strong>
            </Typography>
            <Typography className="tray-details_detail">
              Tray Type: <strong>{trayDetails?.trayType}</strong>
            </Typography>
            <Typography className="tray-details_detail">
              Par Level: <strong>{trayDetails?.parLevel || 'N/A'}</strong>
            </Typography>
          </div>

          <div className="right_container">
            {!subTrayItem || loading || error ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                className="skeleton"
              />
            ) : (
              <img src={trayDetails?.trayImg} alt={subTrayItem.description} />
            )}
          </div>
        </div>

        {loading && <CircularProgress />}

        {!loading && error && (
          <Typography className="error" variant="body1">
            {error.message}
          </Typography>
        )}

        <ConfirmChangesModal
          open={isConfirmationOpen}
          modalText={
            isRejecting
              ? 'Are you sure you want to reject this tray?'
              : isReviewing
              ? 'Are you sure you want to approve this tray'
              : undefined
          }
          onClose={handleConfirmationClose}
          handleConfirm={handleUserConfirmation}
          handleSaveChangesCancellation={handleConfirmationClose}
        />

        <ConfirmLoadMultipleScrewsModal
          open={isAdditionalDeviceCountModalOpen}
          onClose={() => {
            return
          }}
        />

        {!loading && !error && subTrayItem && (
          <>
            <div className="tray-content-section">
              <Typography variant="h2">
                {isReviewing ? 'Approve' : 'Edit'} Tray Content
              </Typography>

              <div className="map_container">
                <div style={{ pointerEvents: isInEditMode ? 'auto' : 'none' }}>
                  {GRAYSCALE_TRAY_TYPES.includes(
                    trayDetails?.trayType ?? ''
                  ) ? (
                    <GrayScale trayType={trayDetails?.trayType ?? ''} />
                  ) : trayDetails?.trayType ===
                    'zimmer standard closure tops' ? (
                    <ZimmerStandardClosureTopsTrayMap />
                  ) : (
                    <TrayMap trayType={trayDetails?.trayType} />
                  )}
                </div>
              </div>

              <div className="buttons-container">
                <div className="left-container">
                  <LoadingButton
                    disabled={false}
                    loading={false}
                    variant="outlined"
                    onClick={handleEditModeChange}
                  >
                    {isInEditMode ? 'View' : 'Edit'}
                  </LoadingButton>

                  {!isInEditMode ? (
                    <LoadingButton
                      disabled={false}
                      loading={false}
                      variant="outlined"
                      onClick={handleViewDetailsClick}
                    >
                      View Details
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      disabled={false}
                      loading={false}
                      variant="outlined"
                      onClick={handleLoadWithUDIsClick}
                    >
                      Load Tray with{' '}
                      {isLoadingWithUDIs ? 'Device Identifiers' : 'UDIs'}
                    </LoadingButton>
                  )}
                </div>

                <div className="right-container">
                  <LoadingButton
                    loading={isUpdating}
                    variant="contained"
                    onClick={() => handleConfirmationClick(false)}
                  >
                    Confirm
                  </LoadingButton>
                </div>
              </div>
            </div>

            <div className="tray-contents-section contained-desktop">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="tray-contents-content"
                  id="tray-contents-header"
                >
                  <div className="accordion-summary">
                    <Typography variant="h2">Tray Contents</Typography>
                    <Typography variant="body2">
                      {trayDetails?.screws.length || 0} Screws
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="tray-contents-items">
                    {trayDetails?.screws.length === 0 ? (
                      <Typography variant="body2">
                        No screws in this tray
                      </Typography>
                    ) : (
                      trayDetails?.screws.map((screw, index) => (
                        <TrayProduct
                          key={`${screw.deviceId}${index}`}
                          {...screw}
                          showDispositionStatus={false}
                        />
                      ))
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EditTray
