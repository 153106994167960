export const closureTopUDIMap = {
  closureTops1: '00889024003170',
  closureTops2: '00889024003170',
  closureTops3: '00889024003170',
  closureTops4: '00889024003170',
  closureTops5: '00889024003170',
  closureTops6: '00889024003170',
  closureTops7: '00889024003170',
  closureTops8: '00889024003170',
  closureTops9: '00889024003170',
  closureTops10: '00889024003170',
  closureTops11: '00889024003170',
  closureTops12: '00889024003170',
  closureTops13: '00889024003170',
  closureTops14: '00889024003170',
} as const

export const closureTopDescriptionMap = {
  closureTops1: 'Standard Closure Top',
  closureTops2: 'Standard Closure Top',
  closureTops3: 'Standard Closure Top',
  closureTops4: 'Standard Closure Top',
  closureTops5: 'Standard Closure Top',
  closureTops6: 'Standard Closure Top',
  closureTops7: 'Standard Closure Top',
  closureTops8: 'Standard Closure Top',
  closureTops9: 'Standard Closure Top',
  closureTops10: 'Standard Closure Top',
  closureTops11: 'Standard Closure Top',
  closureTops12: 'Standard Closure Top',
  closureTops13: 'Standard Closure Top',
  closureTops14: 'Standard Closure Top',
} as const

export type ZimmerStandardClosureTopsPlateKey = keyof typeof closureTopUDIMap
