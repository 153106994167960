import React, { createContext, useContext, useState } from 'react'

type SubTrayView = 'list' | 'analyze' | 'details'

interface SubTraysContextProps {
  currentView: SubTrayView
  setCurrentView: (view: SubTrayView) => void
  selectedTrayIndex?: number
  setSelectedTrayIndex: (index?: number) => void
}

const SubTraysContext = createContext<SubTraysContextProps | undefined>(undefined)

export const useSubTraysContext = () => {
  const context = useContext(SubTraysContext)
  if (!context) {
    throw new Error('useSubTraysContext must be used within SubTraysProvider')
  }
  return context
}

export const SubTraysProvider: React.FC = ({ children }) => {
  const [currentView, setCurrentView] = useState<SubTrayView>('list')
  const [selectedTrayIndex, setSelectedTrayIndex] = useState<number>()

  return (
    <SubTraysContext.Provider
      value={{
        currentView,
        setCurrentView,
        selectedTrayIndex,
        setSelectedTrayIndex,
      }}
    >
      {children}
    </SubTraysContext.Provider>
  )
} 