export const connectorUDIMap = {
  connectorAx1: '00889024007444',
  connectorAx2: '00889024007444',
  connectorBx1: '00889024007451',
  connectorBx2: '00889024007451',
  connectorCx1: '00889024007468',
  connectorCx2: '00889024007468',
  connectorDx1: '00889024007475',
  connectorDx2: '00889024007475',
  connectorEx1: '00889024007482',
  connectorEx2: '00889024007482',
} as const

export const connectorDescriptionMap = {
  connectorAx1: '33 - 36 mm Transverse Connector',
  connectorAx2: '33 - 36 mm Transverse Connector',
  connectorBx1: '36 - 41 mm Transverse Connector',
  connectorBx2: '36 - 41 mm Transverse Connector',
  connectorCx1: '41 - 51 mm Transverse Connector',
  connectorCx2: '41 - 51 mm Transverse Connector',
  connectorDx1: '51 - 70 mm Transverse Connector',
  connectorDx2: '51 - 70 mm Transverse Connector',
  connectorEx1: '70 - 90 mm Transverse Connector',
  connectorEx2: '70 - 90 mm Transverse Connector',
} as const

export type ZimmerTransverseConnectorsPlateKey = keyof typeof connectorUDIMap
