import {
  useDeleteSubTrayItemById,
  useDeleteTrayItem,
  useGetTrayItemByBarcode,
  useUpdateTrayItem,
} from 'lib/apollo/hooks'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTrayConfigurationContext } from '../TrayManagement/TrayManagement.context'
import { SubItem } from 'common/types'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { Typography } from '@mui/material'
import { formatToTitleCase } from 'lib/utils/formatToTitleCase'
import { Delete, Visibility } from '@mui/icons-material'
import { MappedSubTrays } from '../AddTray/SubTrays/SubTrays.types'
import LoadingImage from 'components/molecules/LoadingImage/LoadingImage'
import Button from 'components/molecules/Button/Button'
import { LoadingButton } from 'components/mui'
import {
  TrayStatus,
  TrayStatusDetails,
} from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useUser } from 'app/User'
import useTrayManagementLogic from '../TrayManagement/TrayManagement.logic'
import { printSticker } from 'components/organisms/TraySticker/TraySticker'

export const useTrayDetails = () => {
  const { trayId } = useParams()
  const { user } = useUser()
  const navigate = useNavigate()

  const { setTrays } = useTrayConfigurationContext()
  const { loading, error, trayItem, getTrayItem, refetch } =
    useGetTrayItemByBarcode()
  const { deleteTray, loading: deleting } = useDeleteTrayItem()
  const { deleteSubTray } = useDeleteSubTrayItemById()
  const { editTrayItem } = useUpdateTrayItem()
  const { handleLoadTray } = useTrayManagementLogic()

  const [isApproveRejectTrayOpen, setIsApproveRejectTrayOpen] =
    useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)

  const isSPD = user?.roles?.includes('MGAT_SPD')
  const isRep = user?.roles?.includes('MGAT_REP')
  const trayDetails: TrayItemProductDetails | undefined =
    extractTrayJSONData(trayItem)
  const shouldBeAbleToDeleteSubTrays =
    (isRep && trayDetails?.status === 'rejected') ||
    (isSPD && trayDetails?.trayCategory === 'facility')
  const notAssignedButSelectedSurgeon =
    !trayDetails?.surgeryId && trayDetails?.selectedSurgeon

  useEffect(() => {
    if (!trayId) {
      navigate('..')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId])

  useEffect(() => {
    if (!loading) {
      getTrayItem({ variables: { barcode: trayId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId])

  const handleDeleteTray = async () => {
    const hasUserConfirmed = window.confirm(
      'Are you sure you want to delete this tray?'
    )

    if (hasUserConfirmed) {
      const isTrayDeleted = await deleteTray(trayItem?.id)

      if (isTrayDeleted) {
        setTrays((prevTrays) =>
          prevTrays.filter((tray) => tray.id !== trayItem?.id)
        )
        navigate('/tray-management')
      }
    }
  }

  const handleDeleteSubTray = async (subTrayItemId: number) => {
    const hasUserConfirmed = window.confirm(
      'Are you sure you want to delete this sub-tray?'
    )

    if (hasUserConfirmed) {
      const isSubTrayDeleted = await deleteSubTray(subTrayItemId)

      if (isSubTrayDeleted) {
        refetch()
      }
    }
  }

  const handleEditTrayClick = () => {
    if (trayId) {
      const mainRoute =
        trayDetails?.trayCategory !== 'facility' ? 'approve' : 'edit'

      navigate(`../${mainRoute}/${trayId}`)
    }
  }

  const handleSubTrayClick = (subTrayId: number) => {
    navigate(`../${trayId}/${subTrayId}`)
  }

  const subTraysTableHeaderCells: HeadCell[] = [
    { id: 'trayNumber', label: 'ID', numeric: true },
    { id: 'trayImg', label: 'Tray Image', numeric: false },
    { id: 'trayType', label: 'Tray Type', numeric: false },
    { id: 'parLevel', label: 'Par Level', numeric: false },
    { id: 'viewDetails', label: 'Tray Details', numeric: false },
    { id: 'loadTray', label: 'Load Tray', numeric: false },
    { id: 'deleteSubTray', label: 'Delete Sub-Tray', numeric: false },
  ]

  const subTraysTableBodyCells = (subItems: SubItem[]): MappedSubTrays[] => {
    return subItems.map((subItem) => {
      const subTrayDetails = extractSubTrayJSONData(subItem)

      return {
        trayNumber: `${subItem.id}`,
        trayImg: (
          <LoadingImage
            src={subTrayDetails?.trayImg as string}
            width="150px"
            height="90px"
            borderRadius="15px"
            alt="Sub tray"
          />
        ),
        trayType: (
          <Typography variant="h4">
            {formatToTitleCase(subTrayDetails?.trayType || 'Unknown Tray Type')}
          </Typography>
        ),
        parLevel: `${
          subTrayDetails?.trayType === 'stryker screw caddy'
            ? 'N/A'
            : subTrayDetails?.parLevel
        }`,
        viewDetails: (
          <Button
            startIcon={<Visibility />}
            onClick={() => handleSubTrayClick(subItem.id)}
          >
            View Details
          </Button>
        ),
        loadTray: (
          <>
            {trayDetails?.status === 'readyToFill' && isSPD ? (
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleLoadTray(trayItem)
                }}
              >
                Load Tray
              </Button>
            ) : (
              <Typography>N/A</Typography>
            )}
          </>
        ),
        deleteSubTray: (
          <LoadingButton
            className="delete-sub-tray-button"
            loading={deleting}
            disabled={deleting || !shouldBeAbleToDeleteSubTrays}
            mode="delete"
            sx={{ width: deleting ? '50px' : '30px' }}
            onClick={() => handleDeleteSubTray(subItem.id)}
          >
            <Delete sx={{ width: '17px', height: '17px' }} />
          </LoadingButton>
        ),
      }
    })
  }

  const handleApproveRejectTrayClick = () => {
    setIsApproveRejectTrayOpen((prev) => !prev)
  }

  const handleUpdateTrayApprovalStatus = async (
    status: TrayStatus,
    rejectionReason?: string
  ) => {
    if (!trayItem || !trayDetails) {
      return
    }

    if (status === 'approved') {
      setIsApproving(true)
    } else {
      setIsRejecting(true)
    }

    try {
      const statusDetails: TrayStatusDetails = {
        isRejected: status === 'rejected',
        rejectionReason,
        lastUpdatedBy: user?.email,
        lastUpdatedAt: new Date().toISOString(),
        isApproved: status === 'approved',
        ...(status === 'approved' && {
          approvedBy: user?.email,
          approvedAt: new Date().toISOString(),
        }),
        ...(status === 'rejected' && {
          rejectedBy: user?.email,
          rejectedAt: new Date().toISOString(),
        }),
      }

      const newProductDetails = JSON.stringify({
        ...trayDetails,
        status,
        statusDetails,
      })

      const { subItems, ...newTrayItem } = trayItem

      const editResult = await editTrayItem({
        ...newTrayItem,
        productDetails: newProductDetails,
      })

      if (!editResult) {
        throw new Error('Failed to edit tray item.')
      }

      navigate('/tray-management')
    } catch (error) {
      console.error(error)
    } finally {
      if (status === 'approved') {
        setIsApproving(false)
      } else {
        setIsRejecting(false)
      }
    }
  }

  const handleViewTrayFiles = () => {
    navigate(`./tray-files`)
  }

  const handlePrintSticker = useCallback(() => {
    if (trayItem) {
      printSticker({
        id: trayItem.barcode,
        vendor: trayItem.companyName,
        description: trayItem.description,
        ownership: trayDetails?.trayCategory || 'facility',
        type: 'implantable',
      })
    }
  }, [trayItem, trayDetails?.trayCategory])

  return {
    trayId,
    trayItem,
    loading,
    error,
    trayDetails,
    deleteTray,
    deleting,
    handleDeleteTray,
    handleEditTrayClick,
    subTraysTableHeaderCells,
    subTraysTableBodyCells,
    isApproveRejectTrayOpen,
    handleApproveRejectTrayClick,
    handleUpdateTrayApprovalStatus,
    isApproving,
    isRejecting,
    isSPD,
    handleViewTrayFiles,
    notAssignedButSelectedSurgeon,
    handlePrintSticker,
  }
}
