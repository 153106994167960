import { closureTopUDIMap } from './ZimmerStandardClosureTops.types'
import { ZimmerRodTrayMapProps } from '../ZimmerRodTrayMap/ZimmerRodTrayMap.types'
import useZimmerTrayMapLogic from 'lib/hooks/useZimmerTrayMapLogic/useZimmerTrayMapLogic'

const useZimmerStandardClosureTopsTrayMapLogic = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
}: ZimmerRodTrayMapProps) => {
  return useZimmerTrayMapLogic({
    isInOR,
    setSelectedPlate,
    setModalOpen,
    udiMap: closureTopUDIMap,
    parLevel: 1,
  })
}

export default useZimmerStandardClosureTopsTrayMapLogic
