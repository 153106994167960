import { z } from 'zod'

export type DispositionData = z.infer<typeof dispositionSchema>
type Disposition = (typeof DISPOSITIONS | typeof MULTIPACK_DISPOSITIONS)[number]

//
export type DispositionList = {
  name: string
  id: Disposition
}[]

export type WastedReasonsList = {
  name: string
  id: WastedReasons
}[]

export type ExplantedReasonsList = {
  name: string
  id: ExplantedReasons
}[]

export type AssetType = (typeof ASSET_TYPES)[number]

export const ASSET_TYPES = [
  'non-biological',
  'biological',
  'other-non-biological',
  'consumable',
] as const

export const ASSET_TYPE_LIST = [
  { id: ASSET_TYPES[0], name: 'Implantable Hardware/Associated Asset' },
  // { id: ASSET_TYPES[1], name: 'Implantable Biological' },
  { id: ASSET_TYPES[2], name: 'Implantable Other' },
  { id: ASSET_TYPES[3], name: 'Consumable' },
]

export type Dispositions = (typeof DISPOSITIONS)[number]
export const DISPOSITIONS = [
  'IMPLANTED',
  'WASTED',
  'EXPLANTED',
  'ASSOCIATED_ASSET',
] as const

export const MULTIPACK_DISPOSITIONS = [
  'IMPLANTED',
  'ASSOCIATED_ASSET',
  'WASTED',
  'EXPLANTED',
  'UNUSED',
  'NOT_PRESENT',
] as const

export const DISPOSITION_LIST: DispositionList = [
  { id: DISPOSITIONS[0], name: 'Implanted' },
  { id: DISPOSITIONS[1], name: 'Wasted' },
  { id: DISPOSITIONS[2], name: 'Explanted' },
]

export const MULTIPACK_DISPOSITION_LIST: DispositionList = [
  { id: MULTIPACK_DISPOSITIONS[0], name: 'Implanted' },
  { id: MULTIPACK_DISPOSITIONS[2], name: 'Wasted' },
  { id: MULTIPACK_DISPOSITIONS[3], name: 'Explanted' },
  { id: MULTIPACK_DISPOSITIONS[4], name: 'Unused' },
  { id: MULTIPACK_DISPOSITIONS[5], name: 'Not Present' },
]

export const WASTED_REASONS = [
  'DEFECTIVE',
  'CONTAMINATED',
  'INCORRECTSIZE',
  'TEMPORARY-IN-OUT',
  'INABILITY-TO-ACHIEVE-PRIMARY-STABILITY',
  'OTHER',
] as const

export type WastedReasons = (typeof WASTED_REASONS)[number]
export const WASTED_REASON_LIST = [
  { id: WASTED_REASONS[0], name: 'Defective' },
  { id: WASTED_REASONS[1], name: 'Contaminated' },
  { id: WASTED_REASONS[2], name: 'Incorrect Size' },
  { id: WASTED_REASONS[3], name: 'Temporary In/Out' },
  { id: WASTED_REASONS[4], name: 'Inability to achieve primary stability' },
  { id: WASTED_REASONS[5], name: 'Other' },
]

export const EXPLANTED_REASONS = [
  'SCHEDULED',
  'INFECTION',
  'FAILED_DEVICE',
  'RECALL',
  'OTHER',
] as const

export type ExplantedReasons = (typeof EXPLANTED_REASONS)[number]
export const EXPLANTED_REASON_LIST = [
  { id: EXPLANTED_REASONS[0], name: 'Scheduled' },
  { id: EXPLANTED_REASONS[1], name: 'Infection' },
  { id: EXPLANTED_REASONS[2], name: 'Failed Device' },
  { id: EXPLANTED_REASONS[3], name: 'Recall' },
  { id: EXPLANTED_REASONS[4], name: 'Other' },
]

export const dispositionObject = {
  assetTray: z.string().optional(),
  assetType: z.enum(ASSET_TYPES),
  implantStatus: z
    .enum([...DISPOSITIONS, ...MULTIPACK_DISPOSITIONS])
    .optional(),
  implantSite: z.string().optional(),
  siteLocation: z.string().optional().nullish(),
  wastedReason: z.enum(WASTED_REASONS).optional(),
  explantedReason: z.enum(EXPLANTED_REASONS).optional().nullish(),
  explantedReasonNote: z.string().optional().nullish(),
  count: z
    .string()
    .optional()
    .transform((val) => (val ? Number(val) : undefined)),
}

export const dispositionSchema = z
  .object(dispositionObject)
  .superRefine((data, ctx) => {
    if (data.implantStatus === 'EXPLANTED' && !data.explantedReason) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          data.count === 0
            ? 'Number of Items used cannot be 0'
            : 'Number of Items used cannot be empty',
        path: ['explantedReason'],
      })
    }
    if (data.assetType === 'consumable' && (!data.count || data.count === 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          data.count === 0
            ? 'Number of Items used cannot be 0'
            : 'Number of Items used cannot be empty',
        path: ['count'],
      })
    }
    if (!data.assetType) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Product Type cannot be empty',
        path: ['assetType'],
      })
    }
    if (!data.implantStatus || data.implantStatus.length < 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Disposition cannot be empty',
        path: ['implantStatus'],
      })
    }
    if (!data.implantStatus && !data.implantSite) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Explant site cannot be empty',
        path: ['implantSite'],
      })
    }
    if (
      (data.implantStatus === 'IMPLANTED' ||
        data.implantStatus === 'EXPLANTED') &&
      !data.implantSite
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          data.implantStatus === 'IMPLANTED'
            ? 'Implant site cannot be empty'
            : 'Explant site cannot be empty',
        path: ['implantSite'],
      })
    }
    if (data.implantStatus === 'WASTED' && !data.wastedReason) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Wasted reason cannot be empty',
        path: ['wastedReason'],
      })
    }
    if (
      (data.implantSite === 'Mouth' || data.implantSite === 'Other') &&
      !data.siteLocation
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          data.implantStatus === 'IMPLANTED'
            ? 'Implant location cannot be empty'
            : 'Explant location cannot be empty',
        path: ['siteLocation'],
      })
    }
  })

export const getDispositionList = ({
  assetType,
  isNurseUser,
  isMultipackHardware,
}: {
  isNurseUser?: boolean
  assetType?: AssetType
  isMultipackHardware?: boolean
}): DispositionList => {
  const list = isMultipackHardware
    ? [...MULTIPACK_DISPOSITION_LIST]
    : [...DISPOSITION_LIST]

  if (
    assetType === 'non-biological' &&
    !isMultipackHardware &&
    !list.some((item) => item.id === 'ASSOCIATED_ASSET')
  ) {
    list.splice(1, 0, {
      id: 'ASSOCIATED_ASSET',
      name: 'Utilized associated product',
    })
  }
  if (isNurseUser) {
    list.push({
      id: 'EXPLANTED',
      name: 'Explanted',
    })
  }
  return list
}
