import { DisplaySettings } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import Modal from 'components/molecules/Modal/Modal'
import './ConfirmSPDWorkflowModal.scss'
import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'
import { LoadingButton } from 'components/mui'

interface ConfirmSPDWorkflowModalProps {
  open: boolean
  onClose: () => void
}

const ConfirmSPDWorkflowModal = ({
  open,
  onClose,
}: ConfirmSPDWorkflowModalProps) => {
  const { setOnlyUseManualWorkflow } = useSPD()

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="confirmation-countdown_modal">
        <Box className="header-box">
          <DisplaySettings />

          <Typography variant="h3">Please Select SPD Workflow</Typography>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt="20px"
        >
          <LoadingButton
            onClick={() => {
              setOnlyUseManualWorkflow(false)
              onClose()
            }}
            className="workflow-selection_button"
          >
            Automated
          </LoadingButton>

          <LoadingButton
            onClick={() => {
              setOnlyUseManualWorkflow(true)
              onClose()
            }}
            className="workflow-selection_button"
          >
            Load Manually
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ConfirmSPDWorkflowModal
