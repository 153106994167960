import { Surgery } from 'common/types'

export type TAddRepModalProps = {
  onClose: () => void
  isOpen: boolean
  surgeryId: Surgery['_id']
}

export enum TabValue {
  InviteRep,
  SignUpRep,
}

export type TabPanelProps = {
  $isHidden: Boolean
}
