/* eslint-disable react-hooks/exhaustive-deps */

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'

// Components
import Warning from 'components/molecules/Warning/Warning'

// Logic
import usePreferenceCardLogic from './PreferenceCard.logic'

// Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import pos1 from 'assets/icons/positions/pos-1.png'
import pos2 from 'assets/icons/positions/pos-2.png'
import pos3 from 'assets/icons/positions/pos-3.png'
import pos4 from 'assets/icons/positions/pos-4.png'
import pos5 from 'assets/icons/positions/pos-5.png'
import pos6 from 'assets/icons/positions/pos-6.png'
import pos7 from 'assets/icons/positions/pos-7.png'
import pos8 from 'assets/icons/positions/pos-8.png'
import pos9 from 'assets/icons/positions/pos-9.png'
import pos10 from 'assets/icons/positions/pos-10.png'
import pos11 from 'assets/icons/positions/pos-11.png'
import pos12 from 'assets/icons/positions/pos-12.png'

// Styles
import './PreferenceCard.scss'

const positions: { image: string; name: string }[] = [
  {
    image: pos1,
    name: 'Supine',
  },
  {
    image: pos2,
    name: 'Trendelenburg',
  },
  {
    image: pos3,
    name: 'Reverse Trendelenburg',
  },
  {
    image: pos4,
    name: 'Fracture Table',
  },
  {
    image: pos5,
    name: 'Lithotomy',
  },
  {
    image: pos6,
    name: 'Prone',
  },
  {
    image: pos7,
    name: 'Jackknife',
  },
  {
    image: pos8,
    name: "Fowler's",
  },
  {
    image: pos9,
    name: 'Knee-Chest',
  },
  {
    image: pos10,
    name: 'Kidney',
  },
  {
    image: pos11,
    name: 'Lateral',
  },
  {
    image: pos12,
    name: 'Wilson Frame',
  },
]

const PreferenceCard = () => {
  const {
    preferenceCard,
    accordionsToggle,
    flattenedArray,
    isLoadingCard,
    setAccordionsToggle,
    renderGridItems,
  } = usePreferenceCardLogic()

  return (
    <div className="preference-card-container">
      <div className="header-container">
        <div className="title-container">
          <h3>Preference Card</h3>
        </div>
      </div>
      {!preferenceCard && !isLoadingCard && (
        <div className="warning">
          <Warning text={'No preference card available.'} type="danger" />
        </div>
      )}
      {isLoadingCard && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
      {preferenceCard && (
        <div className="body-container">
          {/* Surgeon Information */}
          <Accordion className="accordion" expanded>
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <Typography>Surgeon information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <div className="col">
                  <div className="item">
                    <div className="label">Surgeon Name</div>
                    <li className="value">
                      {preferenceCard?.surgeonInformation?.physicianName?.name}
                    </li>
                  </div>

                  <div className="item">
                    <div className="label">Procedure Name</div>
                    <li className="value">
                      {preferenceCard?.surgeonInformation?.procedureName}
                    </li>
                  </div>

                  {preferenceCard?.surgeonInformation?.dominantHand?.name && (
                    <div className="item">
                      <div className="label">Dominant Hand</div>
                      <li className="value">
                        {preferenceCard?.surgeonInformation?.dominantHand?.name}
                      </li>
                    </div>
                  )}

                  {preferenceCard?.surgeonInformation?.notes?.length ? (
                    <div className="item">
                      <div className="label">Other Notes</div>
                      {preferenceCard?.surgeonInformation?.notes?.map(
                        (note) => (
                          <li className="value">{note}</li>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="col">
                  {preferenceCard?.surgeonInformation?.picture && (
                    <div className="item">
                      <img
                        style={{ objectFit: 'cover' }}
                        className="image"
                        src={preferenceCard?.surgeonInformation?.picture}
                        alt={
                          preferenceCard?.surgeonInformation?.physicianName
                            ?.name
                        }
                      />
                    </div>
                  )}
                  {preferenceCard?.surgeonInformation?.gloveSize && (
                    <div className="item">
                      <div className="label">Gloves size and type</div>
                      <li className="value">
                        {preferenceCard?.surgeonInformation?.gloveSize ??
                          'None'}
                      </li>
                      {preferenceCard?.surgeonInformation?.gloveType && (
                        <li className="value">
                          {preferenceCard?.surgeonInformation?.gloveType} glove
                        </li>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Procedure Information */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.procedureInformation}
            onChange={(e, expanded) =>
              setAccordionsToggle({
                ...accordionsToggle,
                procedureInformation: expanded,
              })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Procedure Information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <div className="col">
                  {preferenceCard?.procedureDetails?.selectedPosition && (
                    <div className="item">
                      <div className="label">Position</div>
                      <li className="value">
                        {preferenceCard?.procedureDetails?.selectedPosition
                          .other
                          ? preferenceCard?.procedureDetails?.selectedPosition
                              .other
                          : `${
                              positions[
                                preferenceCard?.procedureDetails
                                  ?.selectedPosition.index as number
                              ].name
                            } ${
                              preferenceCard?.procedureDetails?.selectedPosition
                                .side ?? ''
                            }`}
                      </li>
                    </div>
                  )}
                  {preferenceCard?.procedureDetails?.tableRotation &&
                    preferenceCard?.procedureDetails?.tableRotationSide && (
                      <div className="item">
                        <div className="label">Table Rotation</div>
                        <li className="value">
                          {`${
                            preferenceCard?.procedureDetails?.tableRotationSide
                          } ${
                            preferenceCard?.procedureDetails
                              ?.tableRotationDegree
                              ? ` - ${preferenceCard?.procedureDetails?.tableRotationDegree}°`
                              : ''
                          }`}
                        </li>
                      </div>
                    )}
                  {preferenceCard?.procedureDetails?.skinPrep && (
                    <div className="item">
                      <div className="label">Skin Prep</div>
                      <li className="value">
                        {preferenceCard?.procedureDetails?.skinPrep}
                      </li>
                    </div>
                  )}

                  {preferenceCard?.procedureDetails?.chloralPrep && (
                    <div className="item">
                      <div className="label">Chloral Prep</div>
                      <li className="value">Yes</li>
                    </div>
                  )}

                  {preferenceCard?.procedureDetails?.betadine && (
                    <div className="item">
                      <div className="label">Betadine</div>
                      <li className="value">Yes</li>
                    </div>
                  )}

                  {preferenceCard?.procedureDetails?.medication && (
                    <div className="item medication">
                      <div className="label">Medication</div>
                      <ul className="value no-margin">
                        {Object.entries(
                          preferenceCard.procedureDetails.medication
                        ).some(([_, medication]) =>
                          typeof medication === 'string'
                            ? medication.trim() !== ''
                            : medication.concentration ||
                              medication.epiConcentration ||
                              medication.volume
                        ) ? (
                          Object.entries(
                            preferenceCard.procedureDetails.medication
                          ).map(([medicationKey, medication]) => {
                            if (typeof medication === 'string') {
                              // For the 'other' field, directly render the value
                              return medication ? (
                                <li key={medicationKey}>
                                  <strong>
                                    {medicationKey.charAt(0).toUpperCase() +
                                      medicationKey
                                        .slice(1)
                                        .replace(/([A-Z])/g, ' $1')}
                                  </strong>
                                  : {medication}
                                </li>
                              ) : null
                            }

                            // For Medication fields, check and render their properties
                            const { concentration, epiConcentration, volume } =
                              medication
                            if (concentration || epiConcentration || volume) {
                              return (
                                <li key={medicationKey}>
                                  <strong>
                                    {medicationKey.charAt(0).toUpperCase() +
                                      medicationKey
                                        .slice(1)
                                        .replace(/([A-Z])/g, ' $1')}
                                  </strong>
                                  :
                                  <div>
                                    {concentration && (
                                      <div>Concentration: {concentration}</div>
                                    )}
                                    {epiConcentration && (
                                      <div>
                                        Epinephrine Concentration:{' '}
                                        {epiConcentration}
                                      </div>
                                    )}
                                    {volume && <div>Volume: {volume} cc</div>}
                                  </div>
                                </li>
                              )
                            }
                            return null
                          })
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  )}

                  {preferenceCard?.procedureDetails?.DVTProhylaxis && (
                    <div className="item">
                      <div className="label">DVT Prohylaxis</div>
                      <li>
                        {preferenceCard?.procedureDetails?.DVTProhylaxis
                          ? 'Yes'
                          : 'None'}
                      </li>
                    </div>
                  )}
                  {preferenceCard?.procedureDetails?.DVTProhylaxis &&
                    preferenceCard?.procedureDetails?.anticoagSide && (
                      <div className="item">
                        <div className="label">Anticoag Side</div>
                        <li>
                          {preferenceCard?.procedureDetails?.anticoagSide}
                        </li>
                      </div>
                    )}
                  {preferenceCard?.procedureDetails?.stockings && (
                    <div className="item">
                      <div className="label">Stockings</div>
                      <li>
                        {preferenceCard?.procedureDetails?.stockings
                          ? 'Yes'
                          : 'None'}
                      </li>
                    </div>
                  )}
                  {preferenceCard?.procedureDetails?.scd && (
                    <div className="item">
                      <div className="label">SDC</div>
                      <li>
                        {preferenceCard?.procedureDetails?.scd ? 'Yes' : 'None'}
                      </li>
                    </div>
                  )}
                  {preferenceCard?.procedureDetails?.boots && (
                    <div className="item">
                      <div className="label">Boots</div>
                      <li>
                        {preferenceCard?.procedureDetails?.boots
                          ? 'Yes'
                          : 'None'}
                      </li>
                    </div>
                  )}
                </div>

                <div className="col">
                  {preferenceCard?.procedureDetails?.selectedPosition &&
                    !preferenceCard?.procedureDetails?.selectedPosition
                      .other && (
                      <img
                        width={150}
                        className="image"
                        src={
                          positions[
                            preferenceCard?.procedureDetails?.selectedPosition
                              ?.index as number
                          ]?.image
                        }
                        alt={
                          positions[
                            preferenceCard?.procedureDetails?.selectedPosition
                              ?.index as number
                          ]?.name
                        }
                      />
                    )}
                  {preferenceCard?.procedureDetails?.positioningDevices && (
                    <div className="item">
                      <div className="label">Positioning Devices</div>
                      <li>
                        {preferenceCard?.procedureDetails?.positioningDevices}
                      </li>
                    </div>
                  )}
                  {preferenceCard?.procedureDetails?.notes?.length ? (
                    <div className="item">
                      <div className="label">Other Notes</div>
                      {preferenceCard?.procedureDetails?.notes?.map((note) => (
                        <li className="value">{note}</li>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Equipment */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.equipment}
            onChange={(e, expanded) =>
              setAccordionsToggle({ ...accordionsToggle, equipment: expanded })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Equipment</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <Grid container spacing={2}>
                  {flattenedArray?.length
                    ? renderGridItems(flattenedArray)
                    : null}
                </Grid>
                {preferenceCard?.equipment?.notes?.length ? (
                  <div className="item">
                    <div className="label">Other Notes</div>
                    {preferenceCard?.equipment?.notes?.map((note) => (
                      <li className="value">{note}</li>
                    ))}
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Instrument Trays */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.instrumentTrays}
            onChange={(e, expanded) =>
              setAccordionsToggle({
                ...accordionsToggle,
                instrumentTrays: expanded,
              })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Instrument Trays</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <Grid container spacing={2}>
                  {preferenceCard?.instrumentTrays?.instruments?.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </Grid>
                {preferenceCard?.instrumentTrays?.notes?.length ? (
                  <div className="item">
                    <div className="label">Other Notes</div>
                    {preferenceCard?.instrumentTrays?.notes?.map((note) => (
                      <li className="value">{note}</li>
                    ))}
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Implants */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.implants}
            onChange={(e, expanded) =>
              setAccordionsToggle({ ...accordionsToggle, implants: expanded })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Implants</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                {preferenceCard?.companies?.implants?.length ? (
                  <div className="col">
                    <div className="item">
                      {preferenceCard?.companies?.implants?.map(({ name }) => (
                        <li className="value">{name}</li>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Biologics */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.biologics}
            onChange={(e, expanded) =>
              setAccordionsToggle({ ...accordionsToggle, biologics: expanded })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Biologics</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                {preferenceCard?.companies?.biologic?.length ? (
                  <div className="col">
                    <div className="item">
                      {preferenceCard?.companies?.biologic?.map(({ name }) => (
                        <li className="value">{name}</li>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Consumables */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.consumables}
            onChange={(e, expanded) =>
              setAccordionsToggle({
                ...accordionsToggle,
                consumables: expanded,
              })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Consumables</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <Grid container spacing={2}>
                  {preferenceCard?.consumables?.pack?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Pack</div>
                        {preferenceCard?.consumables?.pack?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.bladesCount?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Blades Count</div>
                        <div className="value">
                          {preferenceCard?.consumables?.bladesCount?.map(
                            (item, index) => (
                              <span key={index}>
                                {item}
                                {index <
                                  Number(
                                    preferenceCard?.consumables?.bladesCount
                                      ?.length
                                  ) -
                                    1 && ', '}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.blades?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Blades</div>
                        {preferenceCard?.consumables?.blades?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.burrs?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Burrs</div>
                        {preferenceCard?.consumables?.burrs?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.drains?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Drains</div>
                        {preferenceCard?.consumables?.drains?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}
                  {preferenceCard?.consumables?.sutures?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Sutures</div>
                        {preferenceCard?.consumables?.sutures?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.skinStapler && (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Skin Stapler</div>
                        <div className="value">
                          {preferenceCard?.consumables?.skinStapler
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </div>
                    </Grid>
                  )}

                  {preferenceCard?.consumables?.steriStrips && (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Steri Strips</div>
                        <div className="value">
                          {preferenceCard?.consumables?.steriStrips
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </div>
                    </Grid>
                  )}

                  {preferenceCard?.consumables?.dermabond && (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Dermabond</div>
                        <div className="value">
                          {preferenceCard?.consumables?.dermabond
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </div>
                    </Grid>
                  )}

                  {preferenceCard?.consumables?.dressings?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Dressings</div>
                        {preferenceCard?.consumables?.dressings?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}

                  {preferenceCard?.consumables?.consumablesNotes?.length ? (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="item">
                        <div className="label">Other Notes</div>
                        {preferenceCard?.consumables?.consumablesNotes?.map(
                          (note) => note && <li className="value">{note}</li>
                        )}
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Linked Sales Reps */}
          <Accordion
            className="accordion"
            expanded={accordionsToggle.linkedSalesReps}
            onChange={(e, expanded) =>
              setAccordionsToggle({
                ...accordionsToggle,
                linkedSalesReps: expanded,
              })
            }
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>Linked Sales Reps</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <Grid container spacing={2}>
                {preferenceCard?.linkedSalesReps?.length ? (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="item">
                      {preferenceCard?.linkedSalesReps?.map((item) => (
                        <li className="value">{item.name}</li>
                      ))}
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  )
}

export default PreferenceCard
