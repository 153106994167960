import { useNavigate } from 'react-router-dom'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { TAlertDialog } from './ScrubTechnicians.types'
import {
  useDeleteScrubTechniciansMutation,
  useGetScrubTechniciansQuery,
} from 'lib/apollo/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SCRUB_TECHNICIANS } from 'app/ROUTES_CONSTANTS'
import { TScrubTechnician } from 'common/types'
import { enqueueSnackbar } from 'notistack'

export const useScrubTechniciansLogic = () => {
  const navigate = useNavigate()

  const [searchLabel, setSearchLabel] = useState('Search Scrub Technicians...')
  const [searchString, setSearchString] = useState('')
  const [isAlertOpen, setIsAlertOpen] = useState<TAlertDialog>({
    isOpen: false,
  })

  const scrubTechniciansHeadCells: HeadCell[] = [
    {
      id: 'lastName',
      label: 'Last Name',
      numeric: false,
      sx: {
        minWidth: '10%',
        width: '20%',
      },
    },
    {
      id: 'firstName',
      label: 'First Name',
      numeric: false,
    },
    {
      id: 'speciality',
      label: 'Speciality',
      numeric: false,
    },
    {
      id: 'email',
      label: 'Email',
      numeric: false,
    },
  ]

  const {
    data: scrubTechniciansData,
    loading: isLoadingScrubTecnicians,
    refetch: refetchScrubTechnicians,
    error: errorGettingScrubTechniciansData,
  } = useGetScrubTechniciansQuery()

  const [deleteScrubTechnician, { loading: isDeletingScrubTechnician }] =
    useDeleteScrubTechniciansMutation()

  const scrubTechnicians = useMemo(
    () => scrubTechniciansData?.getScrubTechnicians.data ?? [],
    [scrubTechniciansData]
  )
  const [filteredScrubTechnicians, setFilteredScrubTechnicians] = useState<
    (TScrubTechnician & { checked: boolean })[]
  >([])

  const resetFilteredScrubTechnician = useCallback(() => {
    if (scrubTechnicians && scrubTechnicians.length > 0) {
      const updatedScrubTechnicians = scrubTechnicians.map(
        (scrubTechnician) => ({
          ...scrubTechnician,
          checked: true,
        })
      )
      setFilteredScrubTechnicians(updatedScrubTechnicians)
    }
  }, [scrubTechnicians])

  useEffect(() => {
    resetFilteredScrubTechnician()
    refetchScrubTechnicians()
  }, [resetFilteredScrubTechnician, refetchScrubTechnicians])

  const handleSearchFunction = (searchString: string) => {
    setSearchString(searchString)
    if (searchString === '') {
      resetFilteredScrubTechnician()
      return
    }

    setFilteredScrubTechnicians(
      scrubTechnicians
        .filter(({ firstName, lastName, email, speciality }) =>
          `${firstName.toLowerCase()} ${lastName.toLowerCase()} ${email.toLowerCase()} ${speciality.toLowerCase()}`.includes(
            searchString.toLowerCase()
          )
        )
        .map((scrubTechnician) => ({
          ...scrubTechnician,
          checked: true,
        }))
    )
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchFunction(event.target.value)
  }

  const handleFocus = (focus: boolean) => () => {
    setSearchLabel(
      focus ? 'Search Scrub Technician' : 'Search Scrub Technician...'
    )
  }

  const closeAlertDialog = () => setIsAlertOpen({ isOpen: false })

  const openAlertDialog = (id: string) => () =>
    setIsAlertOpen({
      isOpen: true,
      id,
      description: `Are you sure you want to delete ${
        scrubTechnicians.find(({ _id }) => _id === id)?.lastName
      }?`,
    })

  const navigateToScrubTechnician =
    (state: {
      _id: string
      firstName: string
      lastName: string
      email: string
      confirmEmail: string
      speciality: string
      edit: boolean
    }) =>
    () => {
      navigate(`/${SCRUB_TECHNICIANS}/${state._id}`, { state })
    }

  const handleDeleteScrubTechnician = async () => {
    if (isAlertOpen && isAlertOpen.id) {
      deleteScrubTechnician({
        variables: { scrubTechniciansIds: [isAlertOpen.id] },
      }).then(({ data }) => {
        if (data?.deleteScrubTechnicians.success) {
          refetchScrubTechnicians()
          setSearchString('')
          setIsAlertOpen({
            isOpen: false,
          })
          enqueueSnackbar(
            `${
              scrubTechnicians.find(({ _id }) => _id === isAlertOpen.id)
                ?.lastName
            } was deleted successfully`,
            {
              variant: 'success',
            }
          )
        } else {
          setIsAlertOpen((prev) => ({
            ...prev,
            description: data?.deleteScrubTechnicians.message,
          }))
        }
      })
    }
  }

  const handleCreateScrubTechnician = () => {
    navigate(`/${SCRUB_TECHNICIANS}/new`, {
      state: {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        edit: false,
      },
    })
  }

  return {
    scrubTechnicians,
    searchLabel,
    isAlertOpen,
    searchString,
    filteredScrubTechnicians,
    isLoadingScrubTecnicians,
    errorGettingScrubTechniciansData,
    scrubTechniciansHeadCells,
    isDeletingScrubTechnician,
    handleFocus,
    handleSearch,
    openAlertDialog,
    closeAlertDialog,
    navigateToScrubTechnician,
    handleCreateScrubTechnician,
    handleDeleteScrubTechnician,
  }
}
