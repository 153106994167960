import { Helmet } from 'react-helmet'
import { Box, Typography, Button, Stack } from '@mui/material'
import { BatchDispositionModal } from 'components/BatchDispositionModal/BatchDispositionModal'
import { TrayScanner } from 'components/TrayScanner'
import { useUndocumentedListLogic } from './UndocumentedList.logic'
import { Link, useNavigate } from 'react-router-dom'
import { AssociatedDispositionModal } from 'components/organisms/AssociatedProductsModal/AssociatedProductsModal'
import { UndocumentedListProps } from './UndocumentedList.types'

export function UndocumentedList({
  surgery,
  surgeryId,
  batchAddAssetsToSurgery,
  batchAddAssetsToSurgeryMutation,
}: UndocumentedListProps) {
  const {
    renderAssetBox,
    renderEmptyState,
    isDisposeOpen,
    setIsDisposeOpen,
    selectedScans,
    hardwareAssets,
    consumableAssets,
    otherAssets,
    isConsumableSelected,
    isHardwareSelected,
    isOtherSelected,
    isAllConsumableSelected,
    isAnySelected,
    submitDisposition,
    handleToggleHardware,
    handleToggleConsumable,
    handleToggleOther,
    handleConsumableSubmit,
    undocumentedScans,
    isAssociatedModalOpen,
    setIsAssociatedModalOpen,
    toggleSelection,
    scanStateMap,
    associatedProducts,
    setScanStateMap,
    isAssociatedSelected,
    handleAssociatedSubmit,
    areThereDTMScrews,
    getDispositionStatus,
    handleSaveUploadedMedia,
    hideDocumentSelected,
    selectedMultipackScan,
    selectedScanId,
  } = useUndocumentedListLogic({ surgeryId, batchAddAssetsToSurgery })

  const navigate = useNavigate()

  return (
    <TrayScanner>
      {({
        setIsTrayScannerOpen,
        registerTrayScanHandler,
        isTrayScannerOpen,
      }) => (
        <>
          {isAssociatedModalOpen && (
            <AssociatedDispositionModal
              associatedModalOpen={isAssociatedModalOpen}
              setAssociatedModal={setIsAssociatedModalOpen}
              associatedProducts={associatedProducts}
              surgeryId={surgeryId}
              toggleSelection={toggleSelection}
              scanStateMap={scanStateMap}
              onSave={submitDisposition}
              isSubmitting={batchAddAssetsToSurgeryMutation.loading}
              setScanStateMap={setScanStateMap}
              isHardwareSelected={isHardwareSelected}
              setIsDisposeOpen={setIsDisposeOpen}
              handleAssociatedSubmitInitial={handleAssociatedSubmit}
            />
          )}
          {isDisposeOpen && (
            <BatchDispositionModal
              scan={selectedScans[0]}
              isManualAddition={selectedScans[0]?.isManualAddition}
              activeTray={surgery?.activeAssetTray || undefined}
              assetTrays={surgery?.assetTrays || []}
              assetType={selectedScans[0]?.assetType}
              isOpen={isDisposeOpen && !isTrayScannerOpen}
              onSave={submitDisposition}
              dispositionStatus={getDispositionStatus()}
              onClose={() => setIsDisposeOpen(false)}
              isSubmitting={batchAddAssetsToSurgeryMutation.loading}
              setIsTrayScannerOpen={setIsTrayScannerOpen}
              registerTrayScanHandler={registerTrayScanHandler}
              onSaveMedia={handleSaveUploadedMedia}
              selectedScans={selectedScans}
            />
          )}
          <Helmet>
            <title>Undocumented List</title>
          </Helmet>
          <Box py={2} mb={14} px={1} sx={{ overflowX: 'hidden' }}>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box mb={3} width={areThereDTMScrews ? '60%' : '100%'}>
                <Typography variant="h1">Undocumented List</Typography>
                {undocumentedScans.length > 0 && (
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    fontSize={14}
                  >
                    Click on image captures in the list to select them to be
                    documented together and added to the product list
                  </Typography>
                )}
              </Box>

              {areThereDTMScrews && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    mt: 1,
                    mr: 1,
                    width: '35%',
                    fontSize: 11,
                    textAlign: 'center',
                  }}
                  to="../../tray-visualization"
                  component={Link}
                >
                  View/Edit Tray Map
                </Button>
              )}
            </Stack>
            {hardwareAssets?.length > 0 &&
              renderAssetBox(
                'Implantable Hardware/Associated Product',
                hardwareAssets,
                handleToggleHardware,
                isConsumableSelected || isOtherSelected
              )}
            {consumableAssets?.length > 0 &&
              renderAssetBox(
                'Consumables',
                consumableAssets,
                handleToggleConsumable,
                isHardwareSelected || isOtherSelected
              )}
            {otherAssets?.length > 0 &&
              renderAssetBox(
                'Implantable Other',
                otherAssets,
                handleToggleOther,
                isHardwareSelected || isConsumableSelected
              )}
            {renderEmptyState &&
              renderEmptyState(undocumentedScans?.length === 0)}
          </Box>
          <Box
            position="fixed"
            left={0}
            bottom="70px"
            width="100%"
            sx={{ bgcolor: '#f5f5f5' }}
            data-testid="undocumented-bottom-bar"
          >
            <Box maxWidth="768px" mx="auto" py={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' },
                  rowGap: { xs: 1, sm: 0 },
                }}
              >
                {!hideDocumentSelected && (
                  <Box>
                    <Button
                      data-testid="disposition-undocumented-assets-button"
                      size="small"
                      variant="contained"
                      disabled={!isAnySelected}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        if (selectedMultipackScan) {
                          navigate('../../asset/result', {
                            state: {
                              selectedMultipackScan,
                              selectedScanId,
                            },
                          })
                        } else {
                          isAllConsumableSelected || isConsumableSelected
                            ? handleConsumableSubmit(event)
                            : isAssociatedSelected()
                            ? setIsAssociatedModalOpen(true)
                            : setIsDisposeOpen(true)
                        }
                      }}
                      sx={{
                        color: 'white',
                      }}
                      disableElevation
                    >
                      {isAllConsumableSelected || isConsumableSelected
                        ? 'Disposition Consumable(s)'
                        : 'Document Selected'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </TrayScanner>
  )
}
