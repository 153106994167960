import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSPD } from './SPD.context'
import { useSPDScan } from './Scan/Scan.context'

export const useSPDHook = () => {
  const navigate = useNavigate()
  const {
    trayData,
    setShowTrayDetails,
    setShowSurgeryChangeModal,
    setShowChooseTrayLocationModal,
    onlyUseManualWorkflow,
    screwLoadModalOpen,
    activeStep,
    setActiveStep,
  } = useSPD()
  const { productData } = useSPDScan()

  const steps = onlyUseManualWorkflow
    ? ['Select', 'Scan/ Load', 'Save']
    : ['Open/ Scan', 'Load', 'Digitize']

  useEffect(() => {
    if (screwLoadModalOpen) {
      setActiveStep(1)
    }
  }, [screwLoadModalOpen, setActiveStep])

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])

  useEffect(() => {
    if (!trayData) {
      navigate('/spd')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayData])

  const handleClick = () => {
    setShowTrayDetails(true)
  }

  const handleSurgeryClick = () => {
    setShowSurgeryChangeModal(true)
  }

  const handleLocationClick = () => {
    setShowChooseTrayLocationModal(true)
  }

  const handleNextStep = (stepNumber?: number) => {
    if (stepNumber !== undefined) {
      setActiveStep(stepNumber)
    } else {
      setActiveStep((prevStep) => prevStep + 1)
    }
  }

  const handleStepClick = (stepNumber: number) => {
    if (stepNumber === 1) {
      if (!onlyUseManualWorkflow) {
        if (productData.length > 0) {
          setActiveStep(stepNumber)
        } else {
          alert('Please scan a product before proceeding')
        }
      } else {
        alert('Please select a screw area before proceeding')
      }
    } else {
      setActiveStep(stepNumber)
    }
  }

  return {
    handleLocationClick,
    handleSurgeryClick,
    handleClick,
    handleNextStep,
    steps,
    activeStep,
    handleStepClick,
  }
}
