import { useGetInviteRepQrCodeQuery } from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'
import { TInviteRepProps } from './InviteRep.types'

export const useInviteRepLogic = (props: TInviteRepProps) => {
  const { surgeryId } = props
  const [canAddAsset, setCanAddAsset] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [navigationUrl, setNavigationUrl] = useState<string>('')

  const {
    loading,
    data,
    error: fetchError,
    refetch,
  } = useGetInviteRepQrCodeQuery(canAddAsset, surgeryId)

  useEffect(() => {
    if (loading || !data) return
    if (data.getInviteRepQrCode && !data.getInviteRepQrCode.success) {
      setError(`Error: ${data.getInviteRepQrCode.message}`)
      return
    }
    if (fetchError) {
      setError(`Error: ${fetchError.message}`)
      return
    }
    setNavigationUrl(
      `${window.location.protocol}//${window.location.host}/invite/${data.getInviteRepQrCode.data.jwt}`
    )
  }, [loading, data, fetchError])

  useEffect(() => {
    refetch({ canAddAsset, surgeryId })
  }, [canAddAsset, refetch, surgeryId])

  return {
    error,
    canAddAsset,
    loading,
    navigationUrl,
    setCanAddAsset,
    handleSwitchChange: () => setCanAddAsset((prev) => !prev),
  }
}
