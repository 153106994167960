import { Grid } from '@mui/material'
import { useIsTablet } from 'lib/utils/mediaQueries'
import SearchInput from 'components/molecules/SearchInput/SearchInput'
import './FilterMenu.scss'
import { ButtonSet } from 'components/atoms/ButtonSet/ButtonSet'
import { useFilterMenuLogic } from './FilterMenu.logic'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { FilterProps } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import { AssetTypeLabel, ImplantStatus } from 'common/types'

export interface FilterMenuProps extends FilterProps {
  onReset?: () => void
}

export const FilterMenu = ({
  onReset,
  condenseButtons,
  isLoading,
  renderAssetTypeFilter,
  renderMrnFilter,
  renderPatientNameFilter,
  renderSurgeonFilter,
  renderProcedureTypeFilter,
  renderDepartmentFilter,
  renderDeviceDescription,
  renderDisposition,
}: FilterMenuProps) => {
  const isTablet = useIsTablet()

  const {
    assetType,
    setAssetType,
    mrn,
    patientName,
    surgeonName,
    setSurgeonName,
    procedureType,
    setProcedureType,
    department,
    setDepartment,
    handleSearchMrn,
    surgeonList,
    procedureList,
    deptList,
    handleSearchPatientName,
    handleOnSubmit,
    handleSearchDeviceDescription,
    implantStatus,
    setImplantStatus,
    dispositionsList,
    deviceDescription,
  } = useFilterMenuLogic()

  return (
    <Grid
      container
      data-testid={'filters-menu'}
      className={'filters-menu-container'}
    >
      <Grid container item className="filters" columnGap={1}>
        {renderAssetTypeFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <AutocompleteInput
              label="Product Type"
              id="assetType"
              name="assetType"
              variant="outlined"
              options={[
                'Implantable Hardware/Associated Product',
                'Implantable Biologic',
                'Implantable Other',
                'Consumable',
              ]}
              handleChangeFormData={(value) => {
                setAssetType(value.target.value as AssetTypeLabel)
              }}
              value={assetType}
            />
          </Grid>
        )}
        {renderMrnFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <SearchInput
              onSearch={(value) => handleSearchMrn(value)}
              placeholder="Search MRN"
              isLoading={isLoading}
              label="MRN"
              value={mrn}
            />
          </Grid>
        )}
        {renderPatientNameFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <SearchInput
              onSearch={(value) => handleSearchPatientName(value)}
              placeholder="Search Patient Name"
              label="Patient Name"
              value={patientName}
              isLoading={isLoading}
            />
          </Grid>
        )}
        {renderSurgeonFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <AutocompleteInput
              id="surgeonName"
              label="Surgeon Name"
              name="surgeonName"
              handleChangeFormData={(e) =>
                setSurgeonName(e.target.value as string)
              }
              options={surgeonList}
              variant="outlined"
              value={surgeonName}
              isLoading={isLoading}
              autoSelect={false}
            />
          </Grid>
        )}
        {renderProcedureTypeFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <AutocompleteInput
              id="procedureType"
              label="Procedure Type"
              name="procedureType"
              handleChangeFormData={(e) =>
                setProcedureType(e.target.value as string)
              }
              options={(procedureList as string[]) || []}
              variant="outlined"
              value={procedureType}
              isLoading={isLoading}
              autoSelect={false}
            />
          </Grid>
        )}
        {renderDepartmentFilter && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <AutocompleteInput
              id="department"
              label="Location"
              name="department"
              handleChangeFormData={(e) =>
                setDepartment(e.target.value as string)
              }
              options={deptList as string[]}
              variant="outlined"
              value={department}
              isLoading={isLoading}
              autoSelect={false}
            />
          </Grid>
        )}
        {renderDeviceDescription && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <SearchInput
              onSearch={(value) => handleSearchDeviceDescription(value)}
              placeholder="Search Device Description"
              isLoading={isLoading}
              label="Device Description"
              value={deviceDescription}
            />
          </Grid>
        )}
        {renderDisposition && (
          <Grid
            item
            className={`input-container ${isTablet ? 'is-tablet' : ''}`}
          >
            <AutocompleteInput
              id="implantStatus"
              label="Disposition"
              name="implantStatus"
              handleChangeFormData={(e) =>
                setImplantStatus(e.target.value as ImplantStatus)
              }
              options={dispositionsList}
              variant="outlined"
              value={implantStatus}
              isLoading={isLoading}
              autoSelect={false}
            />
          </Grid>
        )}
      </Grid>
      <ButtonSet
        isLoadingState={isLoading || false}
        secondaryButtonAction={onReset}
        primaryButtonDisabled={false}
        condenseButtons={condenseButtons}
        handleOnSubmit={() => {
          handleOnSubmit()
        }}
        primaryButtonText="Apply"
        dataTestId="filters-menu"
        secondaryButtonText={'Reset'}
      />
    </Grid>
  )
}
