import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { useIsMobile } from 'lib/utils/useIsMobile'
import DeleteIcon from '@mui/icons-material/Delete'
import './DispositionedAssetDetails.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useUser } from 'app/User'

interface DispositionedAssetDetailsProps {
  value: string | number
  label: string
  additionalLabels?: Array<string | React.ReactNode> | null
  summaryDisabled?: boolean
  children?: NonNullable<React.ReactNode>
  handleDelete?: () => void
  editComponent?: React.ReactNode
  isLoading?: boolean
}

export const DispositionedAssetDetails = ({
  value,
  label,
  additionalLabels,
  children,
  handleDelete,
  editComponent,
  isLoading,
}: DispositionedAssetDetailsProps) => {
  const flags = useFlags()
  const { isRep } = useUser()
  const repCanEdit = flags?.repCanEdit ?? false
  const hideEdit = isRep && !repCanEdit ? true : false

  const isMobile = useIsMobile()

  // TODO: khanh to fix, ignoring console warning for now after trying to fix 3 times
  console.error = (message) => {
    if (
      typeof message === 'string' &&
      message.startsWith(
        'Warning: Each child in a list should have a unique "key" prop.'
      )
    ) {
      return
    }
  }

  const filterNull = () => {
    // return only if string or node
    return additionalLabels?.filter(
      (label) => typeof label === 'string' || React.isValidElement(label)
    )
  }

  return (
    // parent column container
    <Grid
      container
      aria-label={'dispositioned-asset-details-parent-container'}
      className={`dispositioned-asset-details-parent-container`}
    >
      {/* row container to space apart count box + asset label and delete component */}
      <Grid container className={`child-container-parent-row`}>
        <Grid item>
          {/*  flex-start container for countbox and asset label */}
          <Grid container className={`child-countbox-label`}>
            <Grid item className={`countbox`}>
              {isLoading ? (
                <CircularProgress size={15} />
              ) : (
                <Typography variant="h6">{value}</Typography>
              )}
            </Grid>
            <Grid item>
              <Typography className={`typography`} variant="h6">
                {label}
              </Typography>
            </Grid>
            {editComponent && !hideEdit && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  className={`divider`}
                />
                <Grid item>{editComponent}</Grid>
              </>
            )}

            {filterNull()?.map((additionalLabel: React.ReactNode) => (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  className={`divider`}
                />
                <Grid item>
                  <Typography className={`typography`} variant="h6">
                    {additionalLabel}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>

        <Grid item>
          {handleDelete && (
            <IconButton
              data-testid={`delete-asset-button`}
              size="small"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>

      {children && (
        <Grid item className={`children ${isMobile ? 'is-mobile' : ''}`}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}
