import {
  Surgery,
  DerivedSurgeryStatus,
  RepApprovalFormData,
} from 'common/types'
export interface AssetListProps {
  surgery: Surgery
  derivedSurgeryStatus: DerivedSurgeryStatus
}

export interface AssetListLogicProps extends AssetListProps {}

export const initialFormData = {} as RepApprovalFormData

export type TAddendumsData = {
  text: string
  createdBy?: string
  createdAt?: string
}

export type TAddendums = {
  show: boolean
  newAddendum: string | null
  addendumsData: TAddendumsData[]
}
