import { useAddTrayContext } from '../AddTray.context'
import { useTrayStepContext } from '../AddTray.logic'
import { useUser } from 'app/User'

const useTrayIdLogic = () => {
  const { orgAbbreviation, orgName } = useUser()
  const { nextStep } = useTrayStepContext()
  const { trayBarcode, setTrayBarcode, showScanner, setShowScanner } =
    useAddTrayContext()

  const handleTrayBarcodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {
    setTrayBarcode(typeof e === 'string' ? e : e.target.value)
  }

  const handleShowScannerButtonClick = () => {
    setShowScanner((prev) => !prev)
  }

  const handleCortexScan = (code: string) => {
    setTrayBarcode(code)
    setShowScanner(false)
  }

  const handleNext = () => {
    if (trayBarcode.length > 0) {
      nextStep()
    }
  }

  return {
    trayBarcode,
    handleTrayBarcodeChange,
    showScanner,
    handleShowScannerButtonClick,
    handleCortexScan,
    handleNext,
    orgPrefix: orgAbbreviation ?? orgName,
  }
}

export default useTrayIdLogic
