// Components
import Header from 'components/molecules/Header/Header'
import CardItem from '../CardItem/CardItem'
import Select from 'components/molecules/Select/Select'
import {
  AvatarWithAddPhoto,
  InstrumentTrays,
  Notes,
  PositionSelector,
} from './CardViewComponents'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import Warning from 'components/molecules/Warning/Warning'
import Button from 'components/molecules/Button/Button'

// MUI
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  RadioGroup,
  Radio,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

// Logic
import useCardViewLogic from './CardView.logic'

// Types
import { Option } from 'common/types'

// Style
import './CardView.scss'

// Other
import classNames from 'classnames'
import { RepItem } from '../RepItem/RepItem'
import AddSalesRep from './AddSalesRep/AddSalesRep'
import { CompanySearchBar } from 'components/CompanySearchBar'

const CardView = ({ mode }: { mode: 'create' | 'view' }) => {
  const {
    nMRep,
    canSave,
    isMobile,
    isLoading,
    consumables,
    medications,
    repsOptions,
    isLoadingCard,
    salesRepModal,
    isNMRepEnabled,
    preferenceCard,
    equipmentItems,
    isCreatingCard,
    selectedVendor,
    isUpdatingCard,
    surgeonsOptions,
    perfusionistRep,
    instrumentTrays,
    procedureDetails,
    selectedCompanies,
    isLoadingSurgeons,
    surgeonInformation,
    isLoadingAddNewRep,
    groupedRepsByCompany,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    isPerfusionistRepEnabled,
    setConsumables,
    handleClickBack,
    resetMedication,
    setSalesRepModal,
    handleAddCompanies,
    setSelectedVendor,
    handleAddSalesRep,
    handleInputChange,
    setEquipmentItems,
    setInstrumentTrays,
    handleDeleteCompany,
    handlePositionChange,
    handleNotifySalesRep,
    handleAddNewSalesRep,
    createPreferenceCard,
    handleCheckboxChange,
    handleDeleteSalesRep,
    handleMedicationsChange,
    handleEquipmentItemChange,
    handleSurgeonInformationChange,
  } = useCardViewLogic({ mode })

  const isCreateMode = mode === 'create'
  const isViewMode = mode === 'view'

  return (
    <div
      className={classNames('preference-card-view', {
        'is-mobile': isMobile,
      })}
    >
      <Header
        parent="Reports"
        title="Preference card"
        onBackClick={handleClickBack}
        hasBack
        isMobile={isMobile}
        actions={
          isCreateMode
            ? [
                {
                  title: 'Save',
                  onClick: () => createPreferenceCard(),
                  isLoading: isCreatingCard || isUpdatingCard,
                  disabled: !canSave,
                },
              ]
            : []
        }
      />

      <div className="preference-card-header">
        {isViewMode && (
          <CardItem
            isHeader
            surgeon={surgeonInformation?.physicianName?.name ?? ''}
            procedure={surgeonInformation?.procedureName ?? ''}
            isSaving={isCreatingCard || isUpdatingCard}
            isLoading={isLoadingCard}
          />
        )}
      </div>

      <div className="body">
        <div>
          {/* Surgeon information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Surgeon information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="section">
                  {isViewMode ? (
                    <TextField
                      label="Physician Name"
                      placeholder="Physician Name"
                      disabled={isViewMode}
                      value={surgeonInformation?.physicianName?.name}
                      InputLabelProps={{
                        shrink: surgeonInformation?.physicianName
                          ? true
                          : false,
                      }}
                    />
                  ) : (
                    <Select
                      isLoading={isLoadingSurgeons}
                      label="Physician Name"
                      options={surgeonsOptions || []}
                      onSelect={(value) => {
                        handleSurgeonInformationChange('physicianName', value)
                      }}
                      selectedValue={
                        (surgeonInformation?.physicianName?.name as string) ??
                        ''
                      }
                    />
                  )}
                  {isViewMode ? (
                    <TextField
                      label="Procedure Name"
                      placeholder="Procedure Name"
                      disabled={isViewMode}
                      value={surgeonInformation?.procedureName}
                      onChange={(e) => {
                        handleSurgeonInformationChange(
                          'procedureName',
                          e.target.value
                        )
                      }}
                      InputLabelProps={{
                        shrink: surgeonInformation?.procedureName
                          ? true
                          : false,
                      }}
                    />
                  ) : (
                    <AutocompleteInput
                      freeSolo
                      variant="outlined"
                      isLoading={isLoadingProcedureTypes}
                      label="Procedure Name"
                      options={procedureTypesOptions || []}
                      value={surgeonInformation?.procedureName ?? ''}
                      handleChangeFormData={(e) => {
                        const value = e.target.value as Option
                        handleSurgeonInformationChange(
                          'procedureName',
                          value.name
                        )
                      }}
                      onTextInputChange={(e) => {
                        const value = e.target.value as string
                        handleSurgeonInformationChange('procedureName', value)
                      }}
                      sx={{ my: 0 }}
                    />
                  )}

                  <Select
                    label="Dominant Hand"
                    options={[
                      { id: 1, name: 'Left' },
                      { id: 2, name: 'Right' },
                    ]}
                    onSelect={(value) => {
                      handleSurgeonInformationChange('dominantHand', value)
                    }}
                    selectedValue={surgeonInformation?.dominantHand?.name ?? ''}
                  />

                  <TextField
                    label="Glove Size and Type"
                    placeholder="Glove Size and Type"
                    value={surgeonInformation?.gloveSize}
                    onChange={(e) => {
                      handleSurgeonInformationChange(
                        'gloveSize',
                        e.target.value
                      )
                    }}
                    InputLabelProps={{
                      shrink: surgeonInformation?.gloveSize ? true : false,
                    }}
                  />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surgeonInformation?.gloveType === 'Single'}
                          onChange={(e) => {
                            handleSurgeonInformationChange(
                              'gloveType',
                              'Single'
                            )
                          }}
                        />
                      }
                      label="Single"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surgeonInformation?.gloveType === 'Double'}
                          onChange={(e) => {
                            handleSurgeonInformationChange(
                              'gloveType',
                              'Double'
                            )
                          }}
                        />
                      }
                      label="Double"
                    />
                  </div>
                </div>
                <div className="section">
                  <AvatarWithAddPhoto
                    disabled={isLoading}
                    defaultImage={surgeonInformation?.picture}
                    onImageChange={(image) => {
                      if (image)
                        handleSurgeonInformationChange('picture', image)
                    }}
                  />
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={surgeonInformation?.notes}
                onNotesChange={(notes) =>
                  handleSurgeonInformationChange('notes', notes)
                }
              />
            </AccordionDetails>
          </Accordion>

          {/* Procedure information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Procedure information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="section">
                  <PositionSelector
                    onChange={handlePositionChange}
                    selected={preferenceCard.procedureDetails?.selectedPosition}
                  />

                  <div>
                    <div className="item">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              preferenceCard.procedureDetails?.tableRotation ===
                              true
                            }
                            onChange={(e) => {
                              handleInputChange(
                                'tableRotation',
                                e.target.checked
                              )
                            }}
                          />
                        }
                        label="Table Rotation"
                      />
                    </div>
                    {preferenceCard.procedureDetails?.tableRotation && (
                      <div className="table-rotation-options">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography>Left</Typography>
                          <Switch
                            onChange={(e) => {
                              handleInputChange(
                                'tableRotationSide',
                                e.target.checked ? 'Right' : 'Left'
                              )
                            }}
                            checked={
                              preferenceCard.procedureDetails
                                .tableRotationSide === 'Right' ?? false
                            }
                          />
                          <Typography>Right</Typography>
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '45' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '45' : ''
                                  )
                                }}
                              />
                            }
                            label="45°"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '90' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '90' : ''
                                  )
                                }}
                              />
                            }
                            label="90°"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '180' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '180' : ''
                                  )
                                }}
                              />
                            }
                            label="180°"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="section">
                  <TextField
                    label="Skin Prep"
                    placeholder="Skin Prep"
                    value={procedureDetails.skinPrep}
                    onChange={(e) =>
                      handleInputChange('skinPrep', e.target.value)
                    }
                    InputLabelProps={
                      procedureDetails.skinPrep
                        ? { shrink: true }
                        : { shrink: false }
                    }
                  />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.chloralPrep}
                          onChange={(e) =>
                            handleInputChange('chloralPrep', e.target.checked)
                          }
                        />
                      }
                      label="Chloralprep"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.betadine}
                          onChange={(e) =>
                            handleInputChange('betadine', e.target.checked)
                          }
                        />
                      }
                      label="Betadine"
                    />
                  </div>
                  <Divider />

                  <Typography variant="h3">Medication</Typography>

                  {/* Lidocaine */}
                  <Accordion className="accordion">
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                      <div className="row">
                        <Typography>Lidocaine</Typography>
                        {medications?.lidocaine?.concentration && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              resetMedication('lidocaine')
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography variant="caption">Reset</Typography>
                          </Button>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <Typography>Concentration:</Typography>
                      <RadioGroup
                        row
                        value={medications?.lidocaine?.concentration || ''}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'lidocaine',
                            'concentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="0.5%"
                          control={<Radio />}
                          label="0.5%"
                        />
                        <FormControlLabel
                          value="1%"
                          control={<Radio />}
                          label="1%"
                        />
                        <FormControlLabel
                          value="2%"
                          control={<Radio />}
                          label="2%"
                        />
                      </RadioGroup>
                      <TextField
                        label="Volume (cc)"
                        value={medications?.lidocaine?.volume}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'lidocaine',
                            'volume',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Lidocaine with Epinephrine */}
                  <Accordion className="accordion">
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                      <div className="row">
                        <Typography>Lidocaine with Epinephrine</Typography>
                        {medications?.lidocaineWithEpi?.concentration && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              resetMedication('lidocaineWithEpi')
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography variant="caption">Reset</Typography>
                          </Button>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <Typography>Concentration:</Typography>
                      <RadioGroup
                        row
                        value={
                          medications?.lidocaineWithEpi?.concentration || ''
                        }
                        onChange={(e) =>
                          handleMedicationsChange(
                            'lidocaineWithEpi',
                            'concentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="0.5%"
                          control={<Radio />}
                          label="0.5%"
                        />
                        <FormControlLabel
                          value="1%"
                          control={<Radio />}
                          label="1%"
                        />
                        <FormControlLabel
                          value="2%"
                          control={<Radio />}
                          label="2%"
                        />
                      </RadioGroup>
                      <Typography>Epinephrine Concentration:</Typography>
                      <RadioGroup
                        row
                        value={
                          medications?.lidocaineWithEpi?.epiConcentration || ''
                        }
                        onChange={(e) =>
                          handleMedicationsChange(
                            'lidocaineWithEpi',
                            'epiConcentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="1:100,000"
                          control={<Radio />}
                          label="1:100,000"
                        />
                        <FormControlLabel
                          value="1:200,000"
                          control={<Radio />}
                          label="1:200,000"
                        />
                      </RadioGroup>
                      <TextField
                        label="Volume (cc)"
                        value={medications?.lidocaineWithEpi?.volume}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'lidocaineWithEpi',
                            'volume',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Marcaine */}
                  <Accordion className="accordion">
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                      <div className="row">
                        <Typography>Marcaine</Typography>
                        {medications?.marcaine?.concentration && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              resetMedication('marcaine')
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography variant="caption">Reset</Typography>
                          </Button>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <Typography>Concentration:</Typography>
                      <RadioGroup
                        row
                        value={medications?.marcaine?.concentration || ''}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'marcaine',
                            'concentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="0.25%"
                          control={<Radio />}
                          label="0.25%"
                        />
                        <FormControlLabel
                          value="0.5%"
                          control={<Radio />}
                          label="0.5%"
                        />
                      </RadioGroup>
                      <TextField
                        label="Volume (cc)"
                        value={medications?.marcaine?.volume}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'marcaine',
                            'volume',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Marcaine with Epinephrine */}
                  <Accordion className="accordion">
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                      <div className="row">
                        <Typography>Marcaine with Epinephrine</Typography>
                        {medications?.marcaineWithEpi?.concentration && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              resetMedication('marcaineWithEpi')
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography variant="caption">Reset</Typography>
                          </Button>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <Typography>Concentration:</Typography>
                      <RadioGroup
                        row
                        value={
                          medications?.marcaineWithEpi?.concentration || ''
                        }
                        onChange={(e) =>
                          handleMedicationsChange(
                            'marcaineWithEpi',
                            'concentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="0.25%"
                          control={<Radio />}
                          label="0.25%"
                        />
                        <FormControlLabel
                          value="0.5%"
                          control={<Radio />}
                          label="0.5%"
                        />
                      </RadioGroup>

                      <Typography>Epinephrine Concentration:</Typography>
                      <RadioGroup
                        row
                        value={
                          medications?.marcaineWithEpi?.epiConcentration || ''
                        }
                        onChange={(e) =>
                          handleMedicationsChange(
                            'marcaineWithEpi',
                            'epiConcentration',
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="1:100,000"
                          control={<Radio />}
                          label="1:100,000"
                        />
                        <FormControlLabel
                          value="1:200,000"
                          control={<Radio />}
                          label="1:200,000"
                        />
                      </RadioGroup>
                      <TextField
                        label="Volume (cc)"
                        value={medications?.marcaineWithEpi?.volume}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'marcaineWithEpi',
                            'volume',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Other */}
                  <Accordion className="accordion">
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                      <div className="row">
                        <Typography>Other</Typography>
                        {medications?.other && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              resetMedication('other')
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography variant="caption">Reset</Typography>
                          </Button>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <TextField
                        label="Other"
                        value={medications?.other}
                        onChange={(e) =>
                          handleMedicationsChange(
                            'other',
                            'other',
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Divider />

                  <TextField
                    label="Positioning devices"
                    placeholder="Positioning devices"
                    value={procedureDetails.positioningDevices}
                    onChange={(e) =>
                      handleInputChange('positioningDevices', e.target.value)
                    }
                    InputLabelProps={
                      procedureDetails.positioningDevices
                        ? { shrink: true }
                        : { shrink: false }
                    }
                  />
                  <Divider />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.DVTProhylaxis === true}
                          onChange={(e) =>
                            handleInputChange('DVTProhylaxis', e.target.checked)
                          }
                        />
                      }
                      label="DVT Prohylaxis"
                    />
                    {procedureDetails.DVTProhylaxis && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.stockings}
                              onChange={(e) =>
                                handleInputChange('stockings', e.target.checked)
                              }
                            />
                          }
                          label="Stockings"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.scd}
                              onChange={(e) =>
                                handleInputChange('scd', e.target.checked)
                              }
                            />
                          }
                          label="SCD"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.boots}
                              onChange={(e) =>
                                handleInputChange('boots', e.target.checked)
                              }
                            />
                          }
                          label="Boots"
                        />
                      </>
                    )}
                  </div>
                  {procedureDetails.DVTProhylaxis && (
                    <>
                      <div className="checkbox-group">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.anticoagSide === 'Left'}
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Left' : undefined
                                )
                              }
                            />
                          }
                          label="Left"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                procedureDetails.anticoagSide === 'Right'
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Right' : undefined
                                )
                              }
                            />
                          }
                          label="Right"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                procedureDetails.anticoagSide === 'Bilateral'
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Bilateral' : undefined
                                )
                              }
                            />
                          }
                          label="Bilateral"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={procedureDetails.notes}
                onNotesChange={(notes) => handleInputChange('notes', notes)}
              />
            </AccordionDetails>
          </Accordion>

          {/* Equipment information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Equipment information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container col half-width equipments">
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Monopolar as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Monopolar',
                              e.target.checked
                            )
                            if (!e.target.checked) {
                              handleEquipmentItemChange(0, 'Cut(Monopolar)', '')
                              handleEquipmentItemChange(
                                0,
                                'Coag(Monopolar)',
                                ''
                              )
                            }
                          }}
                        />
                      }
                      label="Monopolar"
                    />
                  </div>
                  {(equipmentItems.options[0]?.Monopolar as boolean) && (
                    <>
                      <div className="text-box item">
                        <Typography variant="h3">Cut</Typography>
                        <TextField
                          size="small"
                          label="Settings"
                          placeholder="Settings"
                          value={
                            equipmentItems.options[0]?.['Cut(Monopolar)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Cut(Monopolar)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        <Typography variant="h3">Coag</Typography>
                        <TextField
                          size="small"
                          label="Settings"
                          placeholder="Settings"
                          value={
                            equipmentItems.options[0]?.['Coag(Monopolar)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Coag(Monopolar)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Bipolar as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Bipolar',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Bipolar"
                    />
                  </div>
                  {(equipmentItems.options[0]?.Bipolar as boolean) && (
                    <div className="text-box item">
                      <Typography variant="h3">Coag</Typography>
                      <TextField
                        size="small"
                        label="Settings"
                        placeholder="Settings"
                        value={
                          equipmentItems.options[0]?.['Coag(Bipolar)'] ?? ''
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Coag(Bipolar)',
                            e.target.value
                          )
                        }}
                      />
                    </div>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Spine table'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Spine table',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Spine table"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Hand table'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Hand table',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Hand table"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Mayfield as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Mayfield',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Mayfield"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Horseshoe as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Horseshoe',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Horseshoe"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Gell Donut'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Gell Donut',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Gell Donut"
                    />
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'GI Cart'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'GI Cart',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="GI Cart"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Arthroscopy Tower'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Arthroscopy Tower',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Arthroscopy Tower"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Laparoscopy Tower'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Laparoscopy Tower',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Laparoscopy Tower"
                    />
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Robot as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Robot',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Robot"
                  />
                  {(equipmentItems.options[0]?.Robot as boolean) && (
                    <TextField
                      size="small"
                      label="Settings"
                      placeholder="Settings"
                      value={
                        equipmentItems.options[0]?.['Robot Settings'] ?? ''
                      }
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Robot Settings',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Robot Settings']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Drill as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Drill',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Drill"
                  />
                  {(equipmentItems.options[0]?.Drill as boolean) && (
                    <TextField
                      size="small"
                      label="Drill notes"
                      placeholder="Drill notes"
                      value={equipmentItems.options[0]?.['Drill notes'] ?? ''}
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Drill notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Drill notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Sonopet as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Sonopet',
                              e.target.checked
                            )
                            if (!e.target.checked) {
                              handleEquipmentItemChange(0, 'Power(Sonopet)', '')
                              handleEquipmentItemChange(
                                0,
                                'Suction(Sonopet)',
                                ''
                              )
                              handleEquipmentItemChange(
                                0,
                                'Irrigation(Sonopet)',
                                ''
                              )
                            }
                          }}
                        />
                      }
                      label="Sonopet"
                    />
                  </div>
                  {(equipmentItems.options[0]?.Sonopet as boolean) && (
                    <>
                      <div className="text-box item">
                        <TextField
                          size="small"
                          label="Power"
                          placeholder="Power"
                          value={
                            equipmentItems.options[0]?.['Power(Sonopet)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Power(Sonopet)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        <TextField
                          size="small"
                          label="Suction"
                          placeholder="Suction"
                          value={
                            equipmentItems.options[0]?.['Suction(Sonopet)'] ??
                            ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Suction(Sonopet)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        <TextField
                          size="small"
                          label="Irrigation"
                          placeholder="Irrigation"
                          value={
                            equipmentItems.options[0]?.[
                              'Irrigation(Sonopet)'
                            ] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Irrigation(Sonopet)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.CUSA as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'CUSA',
                              e.target.checked
                            )
                            if (!e.target.checked) {
                              handleEquipmentItemChange(0, 'Power(CUSA)', '')
                              handleEquipmentItemChange(0, 'Suction(CUSA)', '')
                              handleEquipmentItemChange(
                                0,
                                'Irrigation(CUSA)',
                                ''
                              )
                            }
                          }}
                        />
                      }
                      label="CUSA"
                    />
                  </div>
                  {(equipmentItems.options[0]?.CUSA as boolean) && (
                    <>
                      <div className="text-box item">
                        {/* <Typography variant="h3">Power</Typography> */}
                        <TextField
                          size="small"
                          label="Power"
                          placeholder="Power"
                          value={
                            equipmentItems.options[0]?.['Power(CUSA)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Power(CUSA)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        {/* <Typography variant="h3">Suction</Typography> */}
                        <TextField
                          size="small"
                          label="Suction"
                          placeholder="Suction"
                          value={
                            equipmentItems.options[0]?.['Suction(CUSA)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Suction(CUSA)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        {/* <Typography variant="h3">Irrigation</Typography> */}
                        <TextField
                          size="small"
                          label="Irrigation"
                          placeholder="Irrigation"
                          value={
                            equipmentItems.options[0]?.['Irrigation(CUSA)'] ??
                            ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Irrigation(CUSA)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Laser as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Laser',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Laser"
                  />
                  {(equipmentItems.options[0]?.Laser as boolean) && (
                    <TextField
                      size="small"
                      label="Laser notes"
                      placeholder="Laser notes"
                      value={equipmentItems.options[0]?.['Laser notes'] ?? ''}
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Laser notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Laser notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.[
                            'Cell Saver'
                          ] as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Cell Saver',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Cell Saver"
                  />
                  {(equipmentItems.options[0]?.['Cell Saver'] as boolean) && (
                    <TextField
                      size="small"
                      label="Cell Saver notes"
                      placeholder="Cell Saver notes"
                      value={
                        equipmentItems.options[0]?.['Cell Saver notes'] ?? ''
                      }
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Cell Saver notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Cell Saver notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.[
                            'C Arm/ Flouro'
                          ] as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'C Arm/ Flouro',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="C Arm/ Flouro"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Microscope as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Microscope',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Microscope"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]
                            ?.Neuromonitoring as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Neuromonitoring',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Neuromonitoring"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Stealth as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Stealth',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Stealth"
                  />
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={equipmentItems.notes}
                onNotesChange={(notes) => {
                  setEquipmentItems((prev) => ({
                    ...prev,
                    notes,
                  }))
                }}
              />
            </AccordionDetails>
          </Accordion>

          {/* Instrument Trays */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Instrument Trays</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container col">
                <InstrumentTrays
                  selectedInstruments={instrumentTrays?.instruments}
                  options={[
                    'Basic',
                    'Burn',
                    'Basic Urology',
                    'Craniotomy',
                    'Special Plastic',
                    'Ortho Basic',
                    'GYN',
                    'Midface',
                    'Basic General',
                    'Spine',
                    'Cardiac',
                    'Mandible',
                    'Laparotomy',
                    'Ortho Fx',
                    'ENT',
                    'Bookwalter',
                  ]}
                  onChange={(selectedOptions) => {
                    setInstrumentTrays((prev) => ({
                      ...prev,
                      instruments: selectedOptions,
                    }))
                  }}
                />
                <Divider />
                <Notes
                  title="Other Notes"
                  initialNotes={instrumentTrays?.notes}
                  onNotesChange={(notes) => {
                    setInstrumentTrays((prev) => ({
                      ...prev,
                      notes,
                    }))
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Implants */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Implants</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="col">
                  <Typography variant="h2">Add Companies</Typography>
                  <div className="mt-1">
                    <CompanySearchBar
                      handleChangeFormData={(e) =>
                        handleAddCompanies('implants', e)
                      }
                      variant="outlined"
                      required={false}
                      placeholder="Search companies"
                    />
                  </div>

                  <div
                    className={classNames('implant-products-container', {
                      disabled: selectedCompanies.implants?.length === 0,
                    })}
                  >
                    {selectedCompanies.implants?.length > 0 ? (
                      <div className="selected-products">
                        {selectedCompanies.implants?.map((item) => (
                          <Product
                            item={item}
                            key={item.id}
                            onDelete={(item) =>
                              handleDeleteCompany('implants', item)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <>No companies added yet.</>
                    )}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Link to Sales Rep */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Link to Sales Reps</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {[...selectedCompanies?.biologic, ...selectedCompanies?.implants]
                .length === 0 && (
                <div className="mt-2">
                  <Warning
                    type="info"
                    text="Please add companies for implants or biologics to show the vendors."
                  />
                </div>
              )}

              <div className="mt-2">
                <Typography variant="h3">Implants and Biologics</Typography>
              </div>
              <AutocompleteInput
                disabled={
                  !selectedCompanies?.biologic.length &&
                  !selectedCompanies?.implants.length
                }
                options={
                  [
                    ...selectedCompanies?.biologic,
                    ...selectedCompanies?.implants,
                  ] || []
                }
                handleChangeFormData={(e) =>
                  setSelectedVendor(e.target.value as Option)
                }
                variant="outlined"
                placeholder="Select Vendor"
                label="Select Vendor"
                value={selectedVendor}
              />
              <div
                className={classNames('row', {
                  wrap: isMobile,
                })}
              >
                <AutocompleteInput
                  disabled={
                    !selectedCompanies?.biologic.length &&
                    !selectedCompanies?.implants.length
                  }
                  handleChangeFormData={handleAddSalesRep}
                  options={repsOptions}
                  variant="outlined"
                  placeholder="Search Sales Reps"
                  showEmail
                  label="Search Sales Reps"
                  sx={{
                    mt: 0,
                  }}
                />
                <Button
                  disabled={!selectedVendor?.id}
                  className="add-button"
                  variant="contained"
                  fullWidth={isMobile}
                  size="small"
                  onClick={() =>
                    setSalesRepModal({
                      isOpen: true,
                      type: 'rep',
                      company: selectedVendor,
                    })
                  }
                >
                  Add Sales Rep
                </Button>
              </div>

              <div className="selected-products">
                {Object.values(groupedRepsByCompany).map(
                  ({ companyName, reps }) => (
                    <div key={companyName} className="company-group">
                      <div className="header">
                        <Typography variant="h3">{companyName}</Typography>
                        <Typography variant="caption">
                          Notify Preferred Rep
                        </Typography>
                      </div>
                      {reps.map((item) => (
                        <RepItem
                          key={item?.email}
                          rep={item}
                          notifyEmail={item.sendEmail}
                          notifySms={item.sendSms}
                          companyName={companyName}
                          handleSendSurgeryToRep={handleNotifySalesRep}
                          isLoading={false}
                          selectedType="EMAIL"
                          handleDelete={handleDeleteSalesRep}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
              <div className="new-rep-container">
                {isNMRepEnabled && (
                  <>
                    <Divider />
                    <div className="notify-rep">
                      {nMRep?.isSetup ? (
                        <div className="rep-container">
                          <Typography variant="h3">
                            Neuromonitoring Rep
                          </Typography>
                          <RepItem
                            key={nMRep?.email}
                            rep={nMRep}
                            notifyEmail={nMRep.sendEmail}
                            notifySms={nMRep.sendSms}
                            handleSendSurgeryToRep={handleNotifySalesRep}
                            isLoading={false}
                            selectedType="EMAIL"
                            handleDelete={handleDeleteSalesRep}
                          />
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            setSalesRepModal({
                              isOpen: true,
                              type: 'Neuromonitoring',
                            })
                          }
                        >
                          Set Up Neuromonitoring Rep
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {isPerfusionistRepEnabled && (
                  <>
                    <Divider />
                    <div className="notify-rep">
                      {perfusionistRep?.isSetup ? (
                        <div className="rep-container">
                          <Typography variant="h3">Perfusionist Rep</Typography>
                          <RepItem
                            key={perfusionistRep?.email}
                            rep={perfusionistRep}
                            notifyEmail={perfusionistRep.sendEmail}
                            notifySms={perfusionistRep.sendSms}
                            handleSendSurgeryToRep={handleNotifySalesRep}
                            isLoading={false}
                            selectedType="EMAIL"
                            handleDelete={handleDeleteSalesRep}
                          />
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            setSalesRepModal({
                              isOpen: true,
                              type: 'Perfusionist',
                            })
                          }
                        >
                          Set Up Perfusionist Rep
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Biologics */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Biologics</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="products-container">
                <Typography variant="h2">Add Companies</Typography>
                <div className="mt-1">
                  <CompanySearchBar
                    handleChangeFormData={(e) =>
                      handleAddCompanies('biologic', e)
                    }
                    variant="outlined"
                    required={false}
                    placeholder="Search companies"
                  />
                </div>

                <div
                  className={classNames('implant-products-container', {
                    disabled: selectedCompanies.biologic?.length === 0,
                  })}
                >
                  {selectedCompanies.biologic?.length > 0 ? (
                    <div className="selected-products">
                      {selectedCompanies.biologic?.map((item) => (
                        <Product
                          item={item}
                          key={item.id}
                          onDelete={(item) =>
                            handleDeleteCompany('biologic', item)
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <>No companies added yet.</>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Consumables */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Consumables</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="row">
                  {/* 1 */}
                  <div className="col">
                    <div className="section">
                      <Notes
                        initialNotes={consumables.pack}
                        title="Pack"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            pack: notes,
                          }))
                        }}
                      />
                    </div>

                    <div className="col">
                      <div className="section">
                        <Notes
                          initialNotes={consumables.blades}
                          title="Blades"
                          onNotesChange={(notes) => {
                            setConsumables((prev) => ({
                              ...prev,
                              blades: notes,
                            }))
                          }}
                        />
                      </div>
                      <div className="row center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 10
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(10, e.target.checked)
                              }
                            />
                          }
                          label="10"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 11
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(11, e.target.checked)
                              }
                            />
                          }
                          label="11"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 15
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(15, e.target.checked)
                              }
                            />
                          }
                          label="15"
                        />
                      </div>
                    </div>
                    <div className="section">
                      <Notes
                        initialNotes={consumables.burrs}
                        title="Burrs"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            burrs: notes,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div className="col">
                  <div className="section">
                    <Notes
                      initialNotes={consumables.drains}
                      title="Drains"
                      onNotesChange={(notes) => {
                        setConsumables((prev) => ({
                          ...prev,
                          drains: notes,
                        }))
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="section">
                      <Notes
                        initialNotes={consumables.sutures}
                        title="Sutures"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            sutures: notes,
                          }))
                        }}
                      />
                    </div>
                    <div className="row center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.skinStapler ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                skinStapler: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Skin Stapler"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.steriStrips ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                steriStrips: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Steri Strips"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.dermabond ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                dermabond: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Dermabond"
                      />
                    </div>
                    <div className="section">
                      <Notes
                        initialNotes={consumables.dressings}
                        title="Dressings"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            dressings: notes,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={consumables.consumablesNotes}
                onNotesChange={(notes) => {
                  setConsumables((prev) => ({
                    ...prev,
                    consumablesNotes: notes,
                  }))
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <AddSalesRep
        key={salesRepModal.modalId}
        isLoading={isLoadingAddNewRep}
        isOpen={salesRepModal.isOpen}
        type={salesRepModal.type}
        onClose={() => setSalesRepModal({ isOpen: false })}
        onAdd={handleAddNewSalesRep}
        company={salesRepModal.company}
      />
    </div>
  )
}

const Product = ({
  item,
  hasSwitch = false,
  value,
  onDelete,
  onChange,
}: {
  item: Option
  hasSwitch?: boolean
  value?: boolean
  onDelete: (item: Option) => void
  onChange?: (bool: boolean) => void
}) => (
  <div className="product">
    <div className="name">{item.name}</div>
    <div className="actions">
      {hasSwitch && (
        <div className="invite-switch">
          <Typography variant="caption">Notify</Typography>
          <Switch
            checked={value}
            onChange={(e) => onChange?.(e.target.checked)}
          />
        </div>
      )}
      <IconButton onClick={() => onDelete(item)}>
        <DeleteIcon />
      </IconButton>
    </div>
  </div>
)

export default CardView
