import { Typography } from '@mui/material'
import { ThumbUpAlt } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { ConfirmChangesModalProps } from '../../TrayVisualization.types'
import './ConfirmChangesModal.scss'

const ConfirmChangesModal = ({
  open,
  onClose,
  handleConfirm,
  handleSaveChangesCancellation,
  modalText,
}: ConfirmChangesModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="tray-visualization-confirm-changes_modal">
        <div className="header-box">
          <ThumbUpAlt />

          <Typography variant="h3">
            {modalText || 'Do you want to save your changes?'}
          </Typography>
        </div>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            mode="cancel"
            onClick={handleSaveChangesCancellation}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            disabled={false}
            loading={false}
            onClick={handleConfirm}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmChangesModal
