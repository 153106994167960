import { TextField, Typography } from '@mui/material'
import Modal from '../Modal/Modal'
import { ConfirmationNumber } from '@mui/icons-material'
import './ApproveRejectTray.scss'
import LoadingButton from '../LoadingButton/LoadingButton'
import useApproveRejectTrayLogic from './ApproveRejectTray.logic'

const ApproveRejectTray = ({
  open,
  onClose,
  isApproving,
  isRejecting,
  handleApproval,
  handleRejection,
}: {
  open: boolean
  onClose: () => void
  isApproving: boolean
  isRejecting: boolean
  handleApproval: () => void
  handleRejection: (rejectionReason?: string) => void
}) => {
  const {
    hasSelectedRejection,
    handleRejectionClick,
    cancelRejection,
    rejectionReason,
    handleRejectionReasonChange,
  } = useApproveRejectTrayLogic({
    onReject: handleRejection,
  })

  return (
    <Modal open={open} onClose={onClose}>
      <div className="approve-reject-tray-molecule_container">
        <div className="header">
          <ConfirmationNumber />

          <Typography variant="h3">Approve Or Reject Tray</Typography>
        </div>

        {hasSelectedRejection ? (
          <>
            <Typography variant="body1">
              Please provide a reason for rejecting this tray:
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Enter rejection reason"
              value={rejectionReason}
              onChange={handleRejectionReasonChange}
            />

            <div className="confirmation-buttons_container">
              <LoadingButton onClick={cancelRejection} variant="outlined">
                Cancel
              </LoadingButton>

              <LoadingButton
                loading={isRejecting}
                onClick={() => handleRejection(rejectionReason)}
                mode="delete"
              >
                Confirm Rejection
              </LoadingButton>
            </div>
          </>
        ) : (
          <>
            <Typography variant="body1">
              Please confirm whether you want to approve or reject this tray.
            </Typography>

            <div className="confirmation-buttons_container">
              <LoadingButton
                loading={isRejecting}
                disabled={isApproving}
                mode="delete"
                onClick={handleRejectionClick}
              >
                Reject
              </LoadingButton>

              <LoadingButton
                loading={isApproving}
                disabled={isRejecting}
                onClick={handleApproval}
              >
                Approve
              </LoadingButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ApproveRejectTray
