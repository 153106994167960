import { SetStateAction } from 'react'

export interface InventorySheetFile {
  id: string
  src: string
  isUploading?: boolean
  isUploaded?: boolean
  mimeType: MimeType
}

export interface InventorySheetsContextProps {
  inventorySheetFiles: InventorySheetFile[]
  setInventorySheetFiles: React.Dispatch<SetStateAction<InventorySheetFile[]>>
  addFileToInventorySheets: (file: string, mimeType: MimeType) => void
  handleDeleteImg: (imgId: string) => void
  isThereAnImageUploading: boolean
  uploadFile: (
    id: string,
    base64Image: string,
    mimeType: MimeType,
    isAnInventorySheet?: boolean
  ) => Promise<string | undefined>
  resetInventorySheets: () => void
}

export type MimeType = keyof typeof mimeToExtensionMap

export const mimeToExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'application/pdf': 'pdf',
  'image/tiff': 'tiff',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-excel': 'xls',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
} as const
