import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AssetData, Scan, ScanState } from 'common/types'
import { useParams } from 'react-router-dom'
import { DispositionData } from 'common/disposition'
import { useEditDocumentedAssets } from 'lib/apollo/hooks'
import {
  EditDocumentedListContextProps,
  EditDocumentedListProviderProps,
  EditDocumentedScanInput,
} from './EditDocumentedList.types'
import { enqueueSnackbar } from 'notistack'

export const EditDocumentedListContext =
  createContext<EditDocumentedListContextProps>({
    isSavingEditedAsset: () => false,
    scanStateMap: {},
    setScanStateMap: () => {},
    foundScanRef: { current: undefined },
    scans: [],
    setScans: () => {},
    submitDisposition: async () => false,
    isSelectModalOpen: false,
    setIsSelectModalOpen: () => {},
    isEditModalOpen: false,
    setIsEditModalOpen: () => {},
    isLoading: false,
    assetGroupId: '',
    setAssetGroupId: () => {},
    handleReset: () => {},
    scanIsMatching: () => false,
    consumableAssetGroup: undefined,
    setConsumableAssetGroup: () => {},
  })

export function EditDocumentedListProvider({
  children,
}: EditDocumentedListProviderProps) {
  // Hooks
  const { surgeryId } = useParams()

  const [editDocumentedAssets, editDocumentedAssetsMutation] =
    useEditDocumentedAssets(surgeryId || '')

  // States
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
  const [scans, setScans] = useState<Scan[]>([])
  const [assetGroupId, setAssetGroupId] = useState<AssetData['_id']>('')
  const [consumableAssetGroup, setConsumableAssetGroup] = useState<AssetData>()
  const [scanStateMap, setScanStateMap] = useState<
    Record<Scan['_id'], ScanState>
  >({})

  const foundScanRef = useRef<Scan | undefined>() // used to store the scan that is selected

  // Lifecycle methods
  useEffect(() => {
    foundScanRef.current = scans?.find(
      (scan) => scanStateMap[scan._id]?.isSelected
    )!
  }, [scanStateMap, scans])

  // Handlers
  const handleReset = () => {
    setScanStateMap({})
    setScans([])
    foundScanRef.current = undefined
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitDisposition = async (
    data: DispositionData,
    scanObject: EditDocumentedScanInput
  ): Promise<boolean> => {
    try {
      const result = await editDocumentedAssets({
        variables: {
          surgeryId: surgeryId,
          disposition: data,
          scans: [scanObject],
        },
      })

      if (result.data?.editDocumentedAssets?.success) {
        enqueueSnackbar('Product Edited Successfully', {
          variant: 'success',
        })
        handleReset()
        return true
      } else {
        enqueueSnackbar('Edit Product Failed. Please try again.', {
          variant: 'error',
        })
        return false
      }
    } catch (error: any) {
      enqueueSnackbar('Edit Product Failed. Please try again.', {
        variant: 'error',
      })
      return false
    }
  }

  const value = useMemo(() => {
    const scanIsMatching = () => {
      return scans.map((scan) => scan._id).includes(foundScanRef.current?._id!)
    }

    const isSavingEditedAsset = (agId: AssetData['_id']) => {
      return editDocumentedAssetsMutation.loading && agId === assetGroupId
    }

    return {
      scanStateMap,
      setScanStateMap,
      foundScanRef,
      scans,
      setScans,
      submitDisposition,
      isSavingEditedAsset,
      isSelectModalOpen,
      setIsSelectModalOpen,
      isEditModalOpen,
      setIsEditModalOpen,
      isLoading: editDocumentedAssetsMutation.loading,
      assetGroupId,
      setAssetGroupId,
      handleReset,
      scanIsMatching,
      consumableAssetGroup,
      setConsumableAssetGroup,
    }
  }, [
    scanStateMap,
    scans,
    submitDisposition,
    isSelectModalOpen,
    isEditModalOpen,
    editDocumentedAssetsMutation.loading,
    assetGroupId,
    consumableAssetGroup,
  ])

  return (
    <EditDocumentedListContext.Provider value={value}>
      {children}
    </EditDocumentedListContext.Provider>
  )
}

export function useEditDocumentedListContext() {
  const context = React.useContext(EditDocumentedListContext)
  if (context === undefined) {
    throw new Error(
      'useEditDocumentedListContext must be used within a EditDocumentedListProvider'
    )
  }
  return context
}
