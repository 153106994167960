const fileToBase64 = (file: File): Promise<string | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result ? reader.result.toString().split(',')[1] : null)
    }
    reader.onerror = () => resolve(null)
    reader.readAsDataURL(file)
  })
}

export const fileToBase64WithThumbnail = (
  file: File
): Promise<{ base64: string | null; thumbnail: string | null }> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result as string
      resolve({
        base64: result ? result.split(',')[1] : null, // Extract base64 content
        thumbnail: result || null, // Use full Data URL for thumbnail
      })
    }
    reader.onerror = () => resolve({ base64: null, thumbnail: null })
    reader.readAsDataURL(file)
  })
}

export default fileToBase64
