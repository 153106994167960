import classNames from 'classnames'

import LoadingButton from '../LoadingButton/LoadingButton'

import { SurgeryBoxProps } from './SurgeryBox.types'
import './SurgeryBox.scss'

const SurgeryBox = ({
  surgery,
  onClick,
  isSelected,
  caseInformation,
  isNurse,
  disableSelect,
}: SurgeryBoxProps) => {
  return (
    <div
      className={classNames('surgery-box-molecule_wrapper', {
        selected: isSelected,
      })}
    >
      <div className="details">
        {surgery &&
          caseInformation({
            surgery: surgery,
            isNurse: isNurse || false,
          })}
      </div>

      <LoadingButton
        disabled={surgery.status.name === 'SUBMITTED' || disableSelect}
        loading={false}
        mode={isSelected && !disableSelect ? 'delete' : 'default'}
        onClick={onClick}
      >
        {surgery.status.name === 'SUBMITTED'
          ? 'Case Submitted'
          : isSelected
          ? disableSelect
            ? 'Selected'
            : 'Remove Selection'
          : 'Select Surgery'}
      </LoadingButton>
    </div>
  )
}

export default SurgeryBox
