import { useAuth0 } from 'app/Auth'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { LaborCostResponse } from '../types'

export const useGetLaborCost = () => {
  const { getAccessToken } = useAuth0()
  const tokenPromise = getAccessToken()
  const LABOR_COST_URL = process.env.REACT_APP_REPORTS_URL

  return useQuery<LaborCostResponse[], AxiosError>('laborCosts', async () => {
    const token = await tokenPromise
    try {
      const response = await axios.get(`${LABOR_COST_URL}api/v1/labor_cost`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  })
}
