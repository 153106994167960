import { useState } from 'react'
import { TAddRepModalProps, TabValue } from './AddRepModal.types'

export const useAddRepModalLogic = (props: TAddRepModalProps) => {
  const { onClose } = props
  const [value, setValue] = useState<TabValue>(TabValue.InviteRep)
  const [resetForm, setResetForm] = useState<boolean>(false)

  const handleChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    setValue(newValue)
    setResetForm(true)
  }

  const handleClose = () => {
    setValue(TabValue.InviteRep)
    setResetForm(true)
    onClose()
  }

  return {
    value,
    handleChange,
    handleClose,
    resetForm,
    setResetForm,
  }
}
