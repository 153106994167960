import { FC } from 'react'

// Components
import { Typography, IconButton, CircularProgress } from '@mui/material'
import { Mail } from '@mui/icons-material'
import SmsIcon from '@mui/icons-material/Sms'

// Logic
import useRepItemLogic from './RepItem.logic'

// Types
import { RepItemProps } from './RepItem.types'

// Style
import './RepItem.scss'

// Other
import classNames from 'classnames'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { formatUSPhoneNumberForDisplay } from 'common/utils'

export const RepItem: FC<RepItemProps> = (props) => {
  const isMobile = useIsMobile()
  const { rep, hasSentEmail, hasSentTextMessage } = props
  const { isLoading, selectedType, handleSendSurgeryToRep } =
    useRepItemLogic(props)

  return rep.firstName ? (
    <div
      key={rep?.email}
      className={classNames('rep-item-container', {
        'is-mobile': isMobile,
      })}
    >
      <div className="details-container">
        <div className="title">
          <Typography variant="h4">{`${rep.firstName} ${rep.lastName}`}</Typography>
        </div>
        <div className="details">
          <div className="detail">
            {rep.email ? (
              <>
                <div
                  className={classNames('text', {
                    'is-email-sent': hasSentEmail,
                  })}
                >
                  <Typography variant={isMobile ? 'subtitle2' : 'body2'}>
                    {rep.email}
                  </Typography>
                  {hasSentEmail ? <span>✅</span> : null}
                </div>
                <div className="action">
                  {isLoading && selectedType === 'EMAIL' ? (
                    <IconButton disableRipple>
                      <CircularProgress size={19} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleSendSurgeryToRep('EMAIL')}>
                      <Mail color="primary" fontSize={'small'} />
                    </IconButton>
                  )}
                </div>
              </>
            ) : null}
          </div>
          <div className="detail">
            {rep.phoneNumber &&
              formatUSPhoneNumberForDisplay(rep.phoneNumber) && (
                <>
                  <div
                    className={classNames('text', {
                      'is-text-sent': hasSentTextMessage,
                    })}
                  >
                    <Typography variant={isMobile ? 'subtitle2' : 'body2'}>
                      {formatUSPhoneNumberForDisplay(rep.phoneNumber)}
                    </Typography>
                    {hasSentTextMessage ? <span>✅</span> : null}
                  </div>
                  <div className="action">
                    {isLoading && selectedType === 'SMS' ? (
                      <IconButton disableRipple>
                        <CircularProgress size={19} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleSendSurgeryToRep('SMS')}>
                        <SmsIcon
                          sx={{ mt: 0.3 }}
                          color="primary"
                          fontSize={'small'}
                        />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
