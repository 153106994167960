import { FC } from 'react'
import { Tab, Tabs } from '@mui/material'
import {
  CloseButton,
  HeaderContainer,
  ModalContainer,
  CloseIcon,
  TabPanel,
} from './AddRepModal.styles'
import { TAddRepModalProps, TabValue } from './AddRepModal.types'
import RepSignupForm from './RepSignupForm'
import InviteRep from './InviteRep'
import { useAddRepModalLogic } from './AddRepModal.logic'

const AddRepModal: FC<TAddRepModalProps> = (props) => {
  const { surgeryId, isOpen } = props
  const { value, handleChange, handleClose, resetForm, setResetForm } =
    useAddRepModalLogic(props)

  return (
    <ModalContainer $isOpen={isOpen}>
      <HeaderContainer>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <Tabs value={value} onChange={handleChange}>
          <Tab value={TabValue.InviteRep} label="Invite Rep" />
          <Tab value={TabValue.SignUpRep} label="Signup Rep" />
        </Tabs>
      </HeaderContainer>
      <TabPanel $isHidden={value !== TabValue.SignUpRep}>
        <RepSignupForm
          surgeryId={surgeryId}
          onClose={handleClose}
          resetForm={resetForm}
          setResetForm={setResetForm}
        />
      </TabPanel>
      <TabPanel $isHidden={value !== TabValue.InviteRep}>
        <InviteRep surgeryId={surgeryId} />
      </TabPanel>
    </ModalContainer>
  )
}

export default AddRepModal
