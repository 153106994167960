export interface GenerateTrayIdOptions {
  prefix?: string
  length?: number
}

export function generateTrayId({
  prefix,
  length = 6,
}: GenerateTrayIdOptions = {}) {
  const timestamp = Date.now().toString(36) // Convert timestamp to base-36 for compactness

  // Generate random characters using the crypto API
  const array = new Uint8Array(length)
  crypto.getRandomValues(array)
  const randomPart = Array.from(array, (byte) =>
    ('0' + (byte & 0xff).toString(36)).slice(-2)
  ).join('')

  const prefixPart = prefix ? `${prefix}-` : ''
  return `${prefixPart}${timestamp}${randomPart}`
}
