import { CircularProgress, Switch, Button } from '@mui/material'
import QRCode from 'react-qr-code'
import {
  ErrorMessage,
  Info,
  MainContainer,
  QRCodeContainer,
  SwitchContainer,
} from './InviteRep.styles'
import { TInviteRepProps } from './InviteRep.types'
import { useInviteRepLogic } from './InviteRep.logic'
import { useFlags } from 'launchdarkly-react-client-sdk'

const InviteRep: React.FC<TInviteRepProps> = (props) => {
  const { error, canAddAsset, loading, navigationUrl, handleSwitchChange } =
    useInviteRepLogic(props)
  const { showCopyLinkButtonForInviteRep } = useFlags()

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(navigationUrl)
      alert('Link copied to clipboard!')
    } catch (err) {
      console.error('Failed to copy link: ', err)
      alert('Failed to copy link. Please try again.')
    }
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <MainContainer>
      <SwitchContainer>
        Allow sales rep to add assets?
        <Switch checked={canAddAsset} onChange={handleSwitchChange} />
      </SwitchContainer>
      <QRCodeContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <QRCode fgColor="var(--primary)" value={navigationUrl} />
        )}
      </QRCodeContainer>
      <Info>
        A sales rep can capture this code to approve the list of assets.
      </Info>
      {showCopyLinkButtonForInviteRep && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopyLink}
          disabled={!navigationUrl || loading}
        >
          Copy Link
        </Button>
      )}
    </MainContainer>
  )
}

export default InviteRep
