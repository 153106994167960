import { CustomModal } from 'components/molecules/Modal/Modal'
import dayjs from 'lib/dayjs'
import { IconButton, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ImplantReportStatus } from 'common/types'
import { useViewImplantReportModalLogic } from './ViewImplantReportModal.logic'
import '../ImplantReportSender.scss'
import { ViewImplantReportModalProps } from '../ImplantReportSender.types'

export const ViewImplantReportModal = ({
  implantReportStatus,
  formatEnumValue,
  viewReportsModalOpen,
  setViewReportsModalOpen,
}: ViewImplantReportModalProps) => {
  const {
    isMobile,
    currentReport,
    currentReportState,
    currentReportIndex,
    handleAttchementClick,
    handleNextReport,
    getAttachmentLink,
    handlePreviousReport,
  } = useViewImplantReportModalLogic(implantReportStatus)

  return (
    <CustomModal
      open={viewReportsModalOpen}
      closeButtonOnly
      header="Sent Report(s)"
      isLoadingState={false}
      secondaryButtonAction={() => setViewReportsModalOpen(false)}
      subheader={`${
        currentReport.type && formatEnumValue(currentReport.type)
      } Report sent to Dr. ${currentReport.referringPhysicianLastName} - ${
        currentReport.referringPhysicianEmail
      } at ${dayjs(currentReport.createdAt).format('hh:mm A M-D-YY')}`}
      subSubHeader={
        <div>
          {currentReport?.attachments &&
            currentReport.attachments.length > 0 && (
              <div className="attachement-container-reports">
                <Typography
                  variant="h6"
                  className={`attachment-title ${isMobile ? 'isMobile' : ''}`}
                >
                  Attachments:
                </Typography>
                <div className="attachment-container">
                  {currentReportState?.attachments &&
                    currentReportState.attachments.map(
                      ({ filename, bucketKey, pdfThumbnail }, index) => {
                        const isPDF = filename
                          .toLocaleLowerCase()
                          .endsWith('.pdf')
                        const attachmentLink = getAttachmentLink(bucketKey)

                        return (
                          <div className="attachment-box" key={index}>
                            <img
                              onClick={handleAttchementClick(bucketKey)}
                              className="attachment-image"
                              src={isPDF ? pdfThumbnail : attachmentLink}
                              alt=""
                            />
                          </div>
                        )
                      }
                    )}
                </div>
              </div>
            )}
          <div className="flex-end">
            <IconButton
              onClick={handlePreviousReport}
              disabled={
                (implantReportStatus as ImplantReportStatus[]).length <= 1 ||
                currentReportIndex === 0
              }
              color={'primary'}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              onClick={handleNextReport}
              disabled={
                (implantReportStatus as ImplantReportStatus[]).length <= 1 ||
                currentReportIndex ===
                  (implantReportStatus as ImplantReportStatus[]).length - 1
              }
              color={'primary'}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </div>
      }
    >
      <div
        className="view-reports-modal"
        dangerouslySetInnerHTML={{
          __html: currentReport.implantReport as string,
        }}
      />
    </CustomModal>
  )
}
