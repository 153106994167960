import { useMemo, useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Box } from '@mui/system'
import ProtectedStatusRoute from 'app/ProtectedStatusRoute'
import ProtectedUserRoute from 'app/ProtectedUserRoute'
import ErrorDisplay from 'components/ErrorDisplay'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import NoProcedure from 'components/NoProcedure'
import ProcedureStatusBar from 'components/ProcedureStatusBar'
import AddRepModal from 'components/AddRepModal'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import {
  useBatchAddAssetsToSurgery,
  useGetSurgeryQuery,
  useLazyCompanySearchQuery,
} from 'lib/apollo/hooks'
import { deriveSurgeryStatus } from 'lib/utils/data'
import { AssetFormDataProvider } from 'lib/context/AssetFormData'
import AddAsset from 'views/assets/AddAsset'
import AssetList from 'views/AssetList/AssetList'
import DeleteAssets from 'views/DeleteAssets/DeleteAssets'
import styles from './procedure.module.css'
import { Surgery } from 'common/types'
import PermissionDenied from 'components/PermissionDenied'
import { InstrumentTrays } from 'views/InstrumentTrays/InstrumentTrays'
import { SingleScanProvider } from 'components/SingleScanProvider'
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
} from '@mui/material'
import MainIconLanding from 'views/MainIconLanding/MainIconLanding'
import TrayIconLanding from 'views/IconLandingPages/TrayIconLanding'
import { House, List, LowPriority } from '@mui/icons-material'
import { ImplantSitesProvider } from 'lib/context/ImplantSitesContext'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { BatchScanning } from 'views/BatchScan/BatchScanning'
import TrayProducts from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProducts'
import CaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera'
import AssignedDigitalTrays from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays'
import TrayVisualization from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/TrayVisualization'
import _ from 'lodash'
import InventoryProvider from 'lib/apollo/inventory-config'
import { CaptureTrayContextProvider } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import { EditDocumentedListProvider } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import PreferenceCard from 'views/PreferenceCard/PreferenceCard'
import { RfSpongeCountProvider } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext'
import { useUser } from 'app/User'
import { getGroupedAssetData } from 'lib/utils/grouped-asset-data'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { closeSnackbar } from 'notistack'
import { SPDCortexScannerProvider } from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner.context'
import Notifications from 'views/Notifications/Notifications'

function Procedure() {
  const location = useLocation()
  const navigate = useNavigate()
  const { surgeryId } = useParams()
  const {
    setAssetType,
    isBatchMode,
    isSalesRepQrOpen,
    mainIconFlowState,
    onMainIconLanding,
    onTrayIconLanding,
    setIsBatchMode,
    setIsSalesRepQrOpen,
    setMainIconFlowState,
    setOnMainIconLanding,
    setOnTrayIconLanding,
  } = useAssetType()

  const [userIsPermitted, setUserIsPermitted] = useState<boolean>(true)

  const { undocumentedScanCount, isLoadingUndocumentedList } =
    useUndocumentedAssetsContext()

  // Add Sales Rep QR
  const handleCloseQrModal = () => {
    setIsSalesRepQrOpen(false)
  }

  const resetToProcedureLanding = () => {
    setOnMainIconLanding(true)
    setOnTrayIconLanding(false)
    setIsBatchMode(false)
    setMainIconFlowState({
      isBatchModeEnabled: true,
      isMultipackHardware: false,
      multipackHardwareSelectedResult: undefined,
    })
    navigate('')
    setAssetType(null)
  }

  const { isRep, user } = useUser()
  const repBidCompanyId = isRep ? user?.bidCompanyId || null : null
  const repCompanyIds = useMemo(() => {
    return isRep
      ? [
          user?.bidCompanyId as number,
          ...(user?.parentCompanyId ? [user.parentCompanyId] : []),
          ...((user?.siblingCompaniesIds as number[]) ?? []),
          ...((user?.subsidiariesCompaniesIds as number[]) ?? []),
        ]
      : []
  }, [
    isRep,
    user?.bidCompanyId,
    user?.parentCompanyId,
    user?.siblingCompaniesIds,
    user?.subsidiariesCompaniesIds,
  ])

  const [getRepCompanies, { data: repCompanies }] = useLazyCompanySearchQuery()

  // let repCompanyNames: string[] | null = null

  useEffect(() => {
    if (repBidCompanyId) {
      getRepCompanies({
        variables: {
          companyIds: repCompanyIds,
        },
      })
    }
  }, [getRepCompanies, repBidCompanyId, repCompanyIds])

  const repCompanyNames =
    repCompanies?.companySearch.map((company) => company.name) || []

  const surgeryQuery = useGetSurgeryQuery(
    surgeryId,
    {
      disablePolling: false,
    },
    repCompanyNames
  )

  const [batchAddAssetsToSurgery, batchAddAssetsToSurgeryMutation] =
    useBatchAddAssetsToSurgery(surgeryId ?? '')

  const [isLoadingDocumentedList, setIsLoadingDocumentedList] = useState(false)

  // monitor when batchAddAssetsToSurgeryMutation is loading to render spinner in bottom nav
  useEffect(() => {
    if (batchAddAssetsToSurgeryMutation.loading) {
      setIsLoadingDocumentedList(true)
    }
  }, [batchAddAssetsToSurgeryMutation.loading])

  useEffect(() => {
    if (isLoadingDocumentedList && batchAddAssetsToSurgeryMutation.data) {
      setIsLoadingDocumentedList(false)
    }
  }, [isLoadingDocumentedList, batchAddAssetsToSurgeryMutation.data])

  // @ts-ignore
  const scanMode = location.state?.mode
  const navigationValue = location.pathname.replace(
    `/surgery/${surgeryId}/`,
    ''
  )

  // clean up url pathname on each page load / refresh
  useEffect(() => {
    navigate(`/surgeries/${surgeryId}`, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scanMode) {
      setIsBatchMode(scanMode === 'batch')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanMode, isBatchMode])

  const surgery = surgeryQuery.data?.getSurgery as Surgery

  const groupedAssetData = getGroupedAssetData(surgery?.assetGroups ?? [])

  const totalDocumentedAssets =
    groupedAssetData.biologic.nurseScans.length +
    groupedAssetData.biologic.repScans.length +
    groupedAssetData.consumable.repScans.length +
    groupedAssetData.consumable.nurseScans.length +
    groupedAssetData.explanted.length +
    groupedAssetData.hardware.approved.length +
    groupedAssetData.hardware.nurseScans.length +
    groupedAssetData.hardware.repScans.length +
    groupedAssetData.other.nurseScans.length +
    groupedAssetData.other.repScans.length

  // TODO: uniq is short term fix for duplicate asset groups
  const derivedSurgeryStatus = useMemo(
    () =>
      deriveSurgeryStatus(surgery?.status?.name, _.uniq(surgery?.assetGroups)),
    [surgery]
  )

  const isProcedureSubmitted = derivedSurgeryStatus.name === 'SUBMITTED'

  useEffect(() => {
    if (
      scanMode &&
      !isProcedureSubmitted &&
      mainIconFlowState.isBatchModeEnabled
    ) {
      setIsBatchMode(scanMode === 'batch')
    }
    if (isProcedureSubmitted) {
      setOnMainIconLanding(false)
      navigate(`/surgeries/${surgeryId}/list`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scanMode,
    isBatchMode,
    isProcedureSubmitted,
    navigate,
    surgeryId,
    mainIconFlowState.isBatchModeEnabled,
  ])

  const [userCanScan, setUserCanScan] = useState<boolean>(true)

  useEffect(() => {
    if (location.pathname.includes('result')) {
      setUserCanScan(false)
    } else {
      setUserCanScan(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (isRep && surgery?.authorizedReps) {
      const authorizedRep = surgery.authorizedReps.find(
        (rep: { id: string }) => rep.id === user?.id
      )
      if (authorizedRep) {
        const repAccessLevel = authorizedRep?.accessLevel
        setUserIsPermitted(repAccessLevel === 1)
        closeSnackbar()
        setOnMainIconLanding(false)
        setOnTrayIconLanding(false)
        if (repAccessLevel === 1) {
          if (derivedSurgeryStatus.name === 'PRE_APPROVAL') {
            navigate(`/surgeries/${surgeryId}/list`)
          }
        } else {
          navigate(`/surgeries/${surgeryId}/list`)
        }
      } else {
        navigate(`/surgeries`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedSurgeryStatus, surgery, navigate, surgeryId, user])

  const userCanAccess = true

  const navigateToUndocumentedList = () => {
    closeSnackbar()
    setOnMainIconLanding(false)
    setOnTrayIconLanding(false)
    navigate('asset/batch/undocumented')
  }

  const navigateToDocumentedList = () => {
    closeSnackbar()
    setOnMainIconLanding(false)
    setOnTrayIconLanding(false)
    navigate(`/surgeries/${surgeryId}/list`)
  }

  if (surgeryQuery.loading) {
    return <LoadingIndicator text="Loading surgery details" />
  }

  if (surgeryQuery.error) {
    if (
      surgeryQuery.error.message.includes('Invalid surgeryId') ||
      surgeryQuery.error.message.includes('No Surgery found with id')
    ) {
      return <NoProcedure />
    }
  }

  if (!userCanAccess) {
    return <PermissionDenied />
  }

  return (
    <ImplantSitesProvider>
      <AssetFormDataProvider>
        {!surgeryQuery.loading && <ProcedureStatusBar surgery={surgery} />}
        <RfSpongeCountProvider surgery={surgery}>
          <CaptureTrayContextProvider>
            {onMainIconLanding && !isProcedureSubmitted && <MainIconLanding />}

            {onTrayIconLanding && (
              <TrayIconLanding
                mainIconFlowState={mainIconFlowState}
                setMainIconFlowState={setMainIconFlowState}
                setOnTrayIconLanding={setOnTrayIconLanding}
              />
            )}

            {/* REP QR INVITE MODAL */}
            <AddRepModal
              isOpen={isSalesRepQrOpen}
              onClose={handleCloseQrModal}
              surgeryId={surgery?._id}
            />

            {!onMainIconLanding && !onTrayIconLanding && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                flex={1}
              >
                <Box
                  maxWidth="768px"
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  position="relative"
                >
                  <Routes>
                    <Route
                      path="preference-card/*"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={[
                            'SCANNING',
                            'APPROVED',
                            'PRE_APPROVAL',
                          ]}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute
                            roles={['MGAT_NURSEADMIN', 'MGAT_NURSE']}
                          >
                            <PreferenceCard />
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="notifications/*"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={[
                            'SCANNING',
                            'APPROVED',
                            'PRE_APPROVAL',
                          ]}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
                            <Notifications surgery={surgery} />
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="asset/batch/*"
                      element={
                        <ProtectedUserRoute isPermitted={userIsPermitted}>
                          <BatchScanning
                            setIsBatchMode={setIsBatchMode}
                            surgery={surgery}
                            batchAddAssetsToSurgery={batchAddAssetsToSurgery}
                            batchAddAssetsToSurgeryMutation={
                              batchAddAssetsToSurgeryMutation
                            }
                            setMainIconFlowState={setMainIconFlowState}
                          />
                        </ProtectedUserRoute>
                      }
                    />
                    <Route
                      path="asset/*"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={[
                            'SCANNING',
                            'APPROVED',
                            'PRE_APPROVAL',
                          ]}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute isPermitted={true}>
                            <SingleScanProvider canScan={userCanScan}>
                              <AddAsset
                                assetGroups={surgery.assetGroups}
                                userCanScan={userCanScan}
                                setUserCanScan={setUserCanScan}
                                surgery={surgery}
                                mainIconFlowState={mainIconFlowState}
                                isBatchMode={isBatchMode}
                              />
                            </SingleScanProvider>
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="list"
                      element={
                        surgeryQuery.error ? (
                          <ErrorDisplay pathForward={surgeryQuery.refetch} />
                        ) : (
                          <EditDocumentedListProvider>
                            <AssetList
                              surgery={surgery}
                              derivedSurgeryStatus={derivedSurgeryStatus}
                            />
                          </EditDocumentedListProvider>
                        )
                      }
                    />
                    <Route
                      path="instrument-trays"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <SingleScanProvider canScan={userCanScan}>
                              <InstrumentTrays surgery={surgery} />
                            </SingleScanProvider>
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="assigned-digital-trays"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <SPDCortexScannerProvider>
                              <InventoryProvider>
                                <AssignedDigitalTrays surgery={surgery} />
                              </InventoryProvider>
                            </SPDCortexScannerProvider>
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="capture-tray/:trayID"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <CaptureCamera />
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="tray-products"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <TrayProducts />
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="tray-visualization"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <InventoryProvider>
                              <TrayVisualization />
                            </InventoryProvider>
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route
                      path="delete"
                      element={
                        <ProtectedStatusRoute
                          permittedStatus={['SCANNING', 'APPROVED']}
                          status={derivedSurgeryStatus.name}
                        >
                          <ProtectedUserRoute roles={['MGAT_NURSE']}>
                            <DeleteAssets surgery={surgery} />
                          </ProtectedUserRoute>
                        </ProtectedStatusRoute>
                      }
                    />
                    <Route path="/" element={<MainIconLanding />} />
                    <Route path="*" element={<ErrorFallback />} />
                  </Routes>
                </Box>
              </Box>
            )}
            <BottomNavigation
              id="asset-list-footer"
              className={styles.bottomNavigation}
              showLabels
              value={navigationValue}
              sx={{
                height: '70px',
              }}
            >
              <BottomNavigationAction
                data-testid="home-button"
                onClick={resetToProcedureLanding}
                label="Home"
                icon={
                  <House
                    sx={{
                      fontSize: '1.8rem',
                      color:
                        !navigationValue.includes('list') &&
                        !navigationValue.includes('undocumented')
                          ? 'var(--primary)'
                          : 'inherit',
                    }}
                  />
                }
                sx={{
                  '.MuiBottomNavigationAction-label': {
                    fontSize: '13px !important',
                    fontWeight: '500',
                    color:
                      !navigationValue.includes('list') &&
                      !navigationValue.includes('undocumented')
                        ? 'var(--primary)'
                        : 'inherit',
                  },
                  maxWidth: `${450 / 2}px`,
                  opacity:
                    !userIsPermitted ||
                    isProcedureSubmitted ||
                    (isRep && derivedSurgeryStatus.name === 'PRE_APPROVAL')
                      ? 0.5
                      : 1,
                  '& .MuiBottomNavigationAction-root': {
                    color: 'gray !important',
                  },
                  ...(!navigationValue.includes('list') &&
                    !navigationValue.includes('undocumented') && {
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '4px',
                        backgroundColor: 'blue',
                      },
                    }),
                }}
                disabled={
                  !userIsPermitted ||
                  isProcedureSubmitted ||
                  (isRep && derivedSurgeryStatus.name === 'PRE_APPROVAL')
                }
              />

              <BottomNavigationAction
                onClick={navigateToUndocumentedList}
                disabled={
                  !userIsPermitted ||
                  isProcedureSubmitted ||
                  (isRep && derivedSurgeryStatus.name === 'PRE_APPROVAL')
                }
                data-testid="undocumented-button"
                value="asset/batch/undocumented"
                to="asset/batch/undocumented"
                component={Link}
                label="Undocumented"
                icon={
                  isLoadingUndocumentedList ? (
                    <CircularProgress />
                  ) : (
                    <Badge
                      // badgeContent={undocumentedScanCount}
                      badgeContent={undocumentedScanCount}
                      color={undocumentedScanCount > 0 ? 'error' : 'default'}
                      sx={{
                        '& .MuiBadge-badge': {
                          transform: 'translate(13px, -5px)',
                          color: 'white',
                        },
                      }}
                    >
                      <LowPriority
                        sx={{
                          fontSize: '1.8rem',
                          color: navigationValue.includes('undocumented')
                            ? 'var(--primary)'
                            : 'inherit',
                        }}
                      />
                    </Badge>
                  )
                }
                sx={{
                  '.MuiBottomNavigationAction-label': {
                    fontSize: '13px !important',
                    fontWeight: '500',
                    color: navigationValue.includes('undocumented')
                      ? 'var(--primary)'
                      : 'inherit',
                  },
                  maxWidth: `${450 / 2}px`,
                  '> span': {
                    fontSize: '11px !important',
                  },
                  opacity:
                    !userIsPermitted ||
                    isProcedureSubmitted ||
                    (isRep && derivedSurgeryStatus.name === 'PRE_APPROVAL')
                      ? 0.5
                      : 1,
                  ...(navigationValue.includes('undocumented') && {
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '4px',
                      backgroundColor: 'blue',
                    },
                  }),
                }}
              />
              <BottomNavigationAction
                data-testid="documented-button"
                onClick={navigateToDocumentedList}
                label="Documented"
                icon={
                  isLoadingDocumentedList ? (
                    <CircularProgress />
                  ) : (
                    <Badge
                      badgeContent={totalDocumentedAssets}
                      color={totalDocumentedAssets > 0 ? 'primary' : 'default'}
                      sx={{
                        '& .MuiBadge-badge': {
                          transform: 'translate(10px, -5px)',
                          color: 'white',
                        },
                      }}
                    >
                      <List
                        sx={{
                          fontSize: '1.8rem',
                          color: navigationValue.includes('list')
                            ? 'var(--primary)'
                            : 'inherit',
                        }}
                      />
                    </Badge>
                  )
                }
                sx={{
                  maxWidth: `${450 / 2}px`,
                  '.MuiBottomNavigationAction-label': {
                    color: navigationValue.includes('list')
                      ? 'var(--primary)'
                      : 'inherit',
                    fontSize: '13px !important',
                    fontWeight: '500',
                  },
                  ...(navigationValue.includes('list') && {
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '4px',
                      backgroundColor: 'blue',
                    },
                  }),
                }}
              />
            </BottomNavigation>
          </CaptureTrayContextProvider>
        </RfSpongeCountProvider>
      </AssetFormDataProvider>
    </ImplantSitesProvider>
  )
}
export default Procedure
