import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetTrayItemByBarcode, useUpdateTrayItem } from 'lib/apollo/hooks'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import PDFViewer from 'components/organisms/PDFViewer/PDFViewer'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { InventorySheetFile } from '../AddTray/InventorySheets/InventorySheets.types'

const useTrayFilesLogic = () => {
  const { trayId } = useParams()
  const { loading, error, trayItem, getTrayItem, refetch } =
    useGetTrayItemByBarcode()
  const { editTrayItem } = useUpdateTrayItem()
  const [trayDetails, setTrayDetails] = useState<
    TrayItemProductDetails | undefined
  >(undefined)
  const [inventorySheetFiles, setInventorySheetFiles] = useState<
    InventorySheetFile[]
  >([])

  useEffect(() => {
    if (trayId) {
      getTrayItem({ variables: { barcode: trayId } })
    }
  }, [trayId, getTrayItem])

  useEffect(() => {
    if (trayItem) {
      const details = extractTrayJSONData(trayItem)
      setTrayDetails(details)
      setInventorySheetFiles(details?.inventorySheetFiles || [])
    }
  }, [trayItem])

  const renderFilePreview = (file: InventorySheetFile): JSX.Element => {
    switch (file.mimeType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <img src={file.src} alt="preview" width="100%" />
      case 'application/pdf':
        return <PDFViewer fileUrl={file.src} />
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Excel File
          </a>
        )
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Word Document
          </a>
        )
      default:
        return (
          <a href={file.src} target="_blank" rel="noopener noreferrer">
            Download File
          </a>
        )
    }
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file && trayItem) {
      try {
        const base64 = await convertFileToBase64(file)
        const newFile: InventorySheetFile = {
          id: Date.now().toString(),
          src: base64,
          mimeType: file.type as InventorySheetFile['mimeType'],
        }

        const updatedInventorySheets = [...inventorySheetFiles, newFile]
        setInventorySheetFiles(updatedInventorySheets)

        const updatedTrayDetails = {
          ...trayDetails,
          inventorySheets: updatedInventorySheets,
        }

        const newProductDetails = JSON.stringify(updatedTrayDetails)

        const { subItems, ...newTrayItem } = trayItem
        await editTrayItem({
          ...newTrayItem,
          productDetails: newProductDetails,
        })

        refetch()
      } catch (error) {
        console.error('Error uploading file:', error)
      }
    }
  }

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  const canAttachFiles = trayDetails?.status === 'rejected'

  return {
    loading,
    error,
    trayDetails,
    inventorySheetFiles,
    renderFilePreview,
    handleFileUpload,
    canAttachFiles,
  }
}

export default useTrayFilesLogic
