import { MoreHoriz } from '@mui/icons-material'
import { MenuItem, Select, Typography } from '@mui/material'
import Modal from 'components/molecules/Modal/Modal'
import './EditTrayStatusModal.scss'
import useEditTrayStatusModalLogic from './EditTrayStatusModal.logic'
import { trayStatusDisplayMap } from '../EditTrayModal/EditTrayModal.types'
import { LoadingButton } from 'components/mui'
import { EditTrayStatusModalProps } from './EditTrayStatusModal.types'

const EditTrayStatusModal = ({
  open,
  onClose,
  onConfirm,
  trayCategory,
}: EditTrayStatusModalProps) => {
  const { selectedStatus, handleSelectedStatusChange, trayStatuses } =
    useEditTrayStatusModalLogic({ trayCategory })

  return (
    <Modal open={open} onClose={onClose}>
      <div className="edit-tray-status-modal_organism">
        <div className="header-box">
          <MoreHoriz />

          <Typography variant="h3">Modify Tray Status</Typography>
        </div>

        <Select
          fullWidth
          variant="outlined"
          value={selectedStatus}
          onChange={handleSelectedStatusChange}
          displayEmpty
          renderValue={(selected) =>
            !selected ? (
              <Typography variant="body1" color="gray">
                Select Tray Status
              </Typography>
            ) : selectedStatus ? (
              trayStatusDisplayMap[selectedStatus]
            ) : (
              'Unknown Status'
            )
          }
        >
          {trayStatuses.map((trayStatus) => (
            <MenuItem value={trayStatus}>
              {trayStatus ? trayStatusDisplayMap[trayStatus] : 'Unknown Status'}
            </MenuItem>
          ))}
        </Select>

        <LoadingButton
          disabled={!selectedStatus}
          onClick={() => onConfirm(selectedStatus)}
        >
          Confirm Status Selection
        </LoadingButton>
      </div>
    </Modal>
  )
}

export default EditTrayStatusModal
