import React, { useEffect, useRef, useState } from 'react'
import { useAuth0 } from 'app/Auth'
import { Box, CircularProgress } from '@mui/material'
import Header from 'components/molecules/Header/Header'

const Analytics: React.FC = () => {
  const analyticsUrl =
    process.env.REACT_APP_ANALYTICS_URL || 'https://analytics-stg.medgeo.com'
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { getAccessToken } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  const [iframeKey, setIframeKey] = useState(0)

  useEffect(() => {
    const sendApiTokenToIframe = async () => {
      try {
        const token = await getAccessToken()
        if (iframeRef.current && iframeRef.current.contentWindow) {
          if (!iframeRef.current.src) {
            iframeRef.current.src = analyticsUrl
          }
          const sendToken = () => {
            iframeRef.current?.contentWindow?.postMessage(
              { type: 'SET_TOKEN', token },
              analyticsUrl
            )
          }
          sendToken()
          setTimeout(sendToken, 1000)
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error getting access token:', error)
        setIsLoading(false)
      }
    }

    sendApiTokenToIframe()
  }, [analyticsUrl, getAccessToken, iframeKey])

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Header parent="Analytics" title="Surgery Cost Report" />
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <iframe
        key={iframeKey}
        ref={iframeRef}
        title="Analytics Dashboard"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden',
        }}
        sandbox="allow-scripts allow-same-origin allow-downloads allow-popups"
      />
    </Box>
  )
}

export default Analytics
