import {
  TextField,
  Autocomplete,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import { FC } from 'react'
import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { GridSearchIcon } from '@mui/x-data-grid'
import { Option } from 'common/types'

type ISelect = {
  options: Option[]
  label: string
  onSelect: (value: Option) => void
  isLoading?: boolean
  selectedValue?: string | null
  onSearch?: (search: string) => void
  onClear?: () => void
  hasSearchIcon?: boolean
  helperText?: string
  fullWidth?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  error?: boolean
  shrink?: boolean
  placeholder?: string
  disabled?: boolean
}

const Select: FC<ISelect> = ({
  options,
  isLoading,
  label = 'Select',
  selectedValue,
  hasSearchIcon,
  onSelect,
  onSearch,
  onClear,
  helperText,
  variant = 'outlined',
  fullWidth = false,
  error = false,
  shrink = true,
  placeholder = 'Select',
  disabled = false,
}) => {
  return (
    <Autocomplete
      sx={fullWidth ? { width: '100%' } : {}}
      disablePortal={false}
      id="combo-box-demo"
      loading={isLoading}
      value={selectedValue}
      options={options.map((option) => option.name)}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          disabled={disabled}
          label={label}
          helperText={helperText}
          placeholder={placeholder}
          InputLabelProps={{
            shrink,
          }}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: hasSearchIcon ? (
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            ) : null,
          }}
        />
      )}
      onChange={(
        event: React.SyntheticEvent,
        value: string | null,
        reason,
        details
      ) => {
        if (value !== null) {
          const selectedObject = options.find((option) => option.name === value)
          onSelect(selectedObject as Option)
        }
      }}
      onInputChange={(
        event: React.ChangeEvent<any>,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        if (reason === 'clear') {
          if (onClear) onClear()
        } else {
          onSearch && onSearch(value)
        }
      }}
      renderOption={(props, option: any) => (
        <li {...props} style={{ fontSize: '15px' }}>
          {option}
        </li>
      )}
    />
  )
}

export default Select
