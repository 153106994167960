import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { AssetData } from 'common/types'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import DemoMenu from 'components/DemoMenu/DemoMenu'
import StatusIndicator from 'components/StatusIndicator'
import StoredAsset from 'components/organisms/StoredAsset/StoredAsset'
import { SentimentVeryDissatisfied } from '@mui/icons-material'
import { Typography, Modal, Button, Box, Grid, Divider } from '@mui/material'
import styles from './assetList.module.css'
import { GroupedAssetData } from 'lib/utils/grouped-asset-data'
import RecordSender from 'components/RecordSender/RecordSender'
import { AssetListHeader } from './components/AssetListHeader'
import {
  AssetTypeOptions,
  TotalAssetCountPanel,
} from 'components/organisms/TotalAssetCountPanel/TotalAssetCountPanel'
import SubmitToEMR from './forms/submitToEmr/SubmitToEMR'
import ProductRegistration from 'components/ProductRegistration/ProductRegistration'
import { AssetListProps } from './AssetList.types'
import { useAssetListLogic } from './AssetList.logic'
import { ImplantReportSender } from 'components/organisms/ImplantReportSender/ImplantReportSender'
import AddRepModal from 'components/AddRepModal'
import SendForApproval from './forms/sendForApproval/SendForApproval'
import ToastProvider from 'common/ToastProvider'

function AssetList({ surgery, derivedSurgeryStatus }: AssetListProps) {
  const {
    setAddendums,
    isNurseAdmin,
    isRep,
    setSurgeryStatusMutation,
    setSurgeryStatusSubmittedMutation,
    setAssetStatusMutation,
    setAssetStatusSecondaryMutation,
    demoMenuEvent,
    isGroupedAssetsEmpty,
    groupedAssetData,
    isQrOpen,
    setIsQrOpen,
    showGenericAlertDialog,
    alertDialogDetails,
    showDemoMenu,
    setShowDemoMenu,
    heading,
    isNurseUser,
    surgeryRepCount,
    procedureStatus,
    workflow,
    hasImplantableHardware,
    totalHardwareCount,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
    timeSubmitted,
    enableSendToRep,
    handleSetAssetStatus,
    handleSetProcedureStatus,
    handleCloseModal,
    handleSubmitRecord,
    renderRecordApprovalForm,
    CancelApprovalButton,
    enableImplantRecordSender,
    enableSendProductRegistration,
    enableSendImplantReport,
    renderCloseCase,
    dtmHardwareAssetData,
    flags,
    loadingRepCompanies,
    repCompanyNames,
    addendumModal,
    isNurse,
    isMobile,
  } = useAssetListLogic({ surgery, derivedSurgeryStatus })

  return (
    <ToastProvider>
      {addendumModal()}
      <Helmet>
        <title>Visit # {surgery.visit?.visitNumber} Documented List</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        px={1.5}
        pt={1.5}
        mb="70px"
        sx={{ overflowX: 'hidden' }}
      >
        <Box className={styles.header} display="flex" alignItems="center">
          <AssetListHeader
            demoMenuEvent={demoMenuEvent}
            heading={heading}
            procedureStatus={procedureStatus}
            isNurseUser={isNurseUser}
            surgeryRepCount={surgeryRepCount}
            setIsQrOpen={setIsQrOpen}
            surgery={surgery}
          />
        </Box>

        {isRep && workflow === 'rep' && procedureStatus === 'PRE_APPROVAL' && (
          <Typography my={2}>
            Review the record below. If something is incorrect, speak with the
            nurse responsible for product documentation in this procedure. If
            the record is accurate, you may approve it.
          </Typography>
        )}

        {procedureStatus === 'SUBMITTED' && (
          <StatusIndicator
            procedureStatus={procedureStatus}
            userName={surgery.status.user}
            scrubTechnician={surgery.status.scrubTechnician}
            timeSubmitted={timeSubmitted}
          />
        )}

        {isNurseAdmin && procedureStatus === 'SUBMITTED' && (
          <Button
            variant="contained"
            onClick={() => setAddendums((prev) => ({ ...prev, show: true }))}
            sx={{ width: isMobile ? '100%' : '150px' }}
          >
            {surgery?.addendums?.length > 0 ? 'View Addendum' : 'Add Addendum'}
          </Button>
        )}
        {isNurse &&
          !isNurseAdmin &&
          surgery?.addendums?.length > 0 &&
          procedureStatus === 'SUBMITTED' && (
            <Button
              variant="contained"
              onClick={() => setAddendums((prev) => ({ ...prev, show: true }))}
              sx={{ width: isMobile ? '100%' : '150px' }}
            >
              View Addendum
            </Button>
          )}

        {enableImplantRecordSender && <RecordSender surgery={surgery} />}

        {enableSendProductRegistration && (
          <ProductRegistration surgery={surgery} surgeryId={surgery._id} />
        )}

        {enableSendImplantReport && (
          <ImplantReportSender
            surgeryId={surgery._id}
            assetGroups={surgery.assetGroups}
            implantReportStatus={surgery.implantReportStatus || []}
            surgeryDate={surgery.procedures[0].dateTime}
          />
        )}

        {/* IMPLANTABLE HARDWARE  */}
        {isRep &&
          !hasImplantableHardware &&
          procedureStatus === 'SUBMITTED' && (
            <Typography>
              No implantable hardware products were used during this procedure.
            </Typography>
          )}

        <Box mt={1.5}>
          {groupedAssetData.hardware.nurseScans.length > 0 ||
          groupedAssetData.hardware.repScans.length > 0 ||
          groupedAssetData.hardware.approved.length > 0 ? (
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                my: 1.75,
                pb: 1,
                borderBottom: '2px solid #eee',
              }}
            >
              <Typography
                variant="h2"
                sx={{ mr: 1 }}
                data-testid="asset-type-header"
              >
                Implantable Hardware/Associated Product
              </Typography>
              <Typography variant="h3" data-testid="asset-type-header-count">
                {`Implanted Total: ${totalHardwareCount}`}
              </Typography>
            </Grid>
          ) : null}
          {isNurseUser &&
            procedureStatus === 'PRE_APPROVAL' &&
            workflow === 'rep' && (
              <StatusIndicator
                procedureStatus={procedureStatus}
                userName={'Status test 2'} // TODO, no such thing as approval rep, since it's mixed
                timeSubmitted={timeSubmitted}
              />
            )}
          {procedureStatus === 'APPROVED' && (
            <StatusIndicator
              procedureStatus={procedureStatus}
              hasImplantableHardware={hasImplantableHardware}
            />
          )}
          <Box my={3.5}>
            {groupedAssetData.hardware.nurseScans.length > 0 &&
            groupedAssetData.hardware.repScans.length > 0 ? (
              <Typography
                variant="h3"
                sx={{
                  my: 1.75,
                  textTransform: 'capitalize',
                  color: 'text.secondary',
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                Added by nurse
              </Typography>
            ) : null}
            {dtmHardwareAssetData && dtmHardwareAssetData?.length > 0 && (
              <>
                <Typography variant="h3" my={1.5}>
                  DTM Hardware
                </Typography>

                {dtmHardwareAssetData.map((asset: AssetData) => {
                  return (
                    <StoredAsset
                      key={asset._id}
                      isProcedureSubmitted={procedureStatus === 'SUBMITTED'}
                      surgeryId={surgery._id}
                      assetData={asset}
                      groupedAssetData={groupedAssetData}
                    />
                  )
                })}

                <Divider sx={{ width: '100%', mb: 2 }} />
              </>
            )}

            {groupedAssetData.hardware.nurseScans
              .filter(
                (nurseScan) =>
                  !dtmHardwareAssetData?.some(
                    (dtmScan) => nurseScan._id === dtmScan._id
                  )
              )
              .sort((a, b) => {
                if (a.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                  return 1
                }
                if (b.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                  return -1
                }
                return 0
              })
              .map((asset: AssetData) => {
                return (
                  <StoredAsset
                    key={asset._id}
                    isProcedureSubmitted={procedureStatus === 'SUBMITTED'}
                    surgeryId={surgery._id}
                    assetData={asset}
                    groupedAssetData={groupedAssetData}
                  />
                )
              })}
          </Box>

          {groupedAssetData.hardware.repScans.length > 0 ? (
            <Box my={3.5}>
              <Typography
                variant="h3"
                sx={{
                  my: 1.75,
                  textTransform: 'capitalize',
                  color: 'text.secondary',
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                Added by rep
              </Typography>

              {groupedAssetData.hardware.repScans
                .sort((a, b) => {
                  if (a.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                    return 1
                  }
                  if (b.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                    return -1
                  }
                  return 0
                })
                .map((asset: AssetData) => {
                  return (
                    <StoredAsset
                      key={asset._id}
                      isProcedureSubmitted={procedureStatus === 'SUBMITTED'}
                      surgeryId={surgery._id}
                      assetData={asset}
                      groupedAssetData={groupedAssetData}
                    />
                  )
                })}
            </Box>
          ) : null}
          {isNurseUser && (
            <Box mb={2}>
              {/* SEND TO REP BUTTONS  */}
              {enableSendToRep &&
                flags.documentedListSendForRepApprovalButton && (
                  <SendForApproval
                    surgery={surgery}
                    handleSetAssetStatus={handleSetAssetStatus}
                    setAssetStatusMutation={setAssetStatusMutation}
                    setAssetStatusSecondaryMutation={
                      setAssetStatusSecondaryMutation
                    }
                    setIsQrOpen={setIsQrOpen}
                  />
                )}

              {procedureStatus === 'PRE_APPROVAL' && (
                <>
                  {/* APPROVAL INSTRUCTIONS */}
                  {workflow === 'no-rep' && (
                    <>
                      <Typography mt={3}>
                        Review the record above. If something is incorrect,
                        cancel the approval process and correct it. If the
                        record is accurate, you may approve it without an
                        industry rep present.
                      </Typography>
                      {renderRecordApprovalForm()}
                    </>
                  )}
                  {/* NO-REP APPROVAL FORM */}
                  <CancelApprovalButton />
                </>
              )}
            </Box>
          )}
          {/* IMPLANTABLE HARDWARE APPROVAL */}
          <Box sx={{ width: 0.9, mx: 'auto' }}>
            {!isNurseUser &&
              workflow === 'rep' &&
              procedureStatus === 'PRE_APPROVAL' &&
              renderRecordApprovalForm()}
          </Box>
          <Box my={3.5}>
            {groupedAssetData.hardware.approved.length > 0 &&
            (groupedAssetData.hardware.nurseScans.length > 0 ||
              groupedAssetData.hardware.repScans.length > 0) ? (
              <Typography
                variant="h3"
                sx={{
                  my: 1.75,
                  textTransform: 'capitalize',
                  color: 'text.secondary',
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                Approved
              </Typography>
            ) : null}

            {groupedAssetData.hardware.approved
              .sort((a, b) => {
                if (a.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                  return 1
                }
                if (b.scans[0].implantStatus === 'ASSOCIATED_ASSET') {
                  return -1
                }
                return 0
              })
              .map((asset: AssetData) => {
                return (
                  <StoredAsset
                    key={asset._id}
                    isProcedureSubmitted={procedureStatus === 'SUBMITTED'}
                    surgeryId={surgery._id}
                    assetData={asset}
                    groupedAssetData={groupedAssetData}
                  />
                )
              })}
          </Box>
        </Box>

        {/* MAPPING BIOLOGICS, OTHER, AND CONSUMABLES*/}
        {Object.entries(AssetTypeOptions).map(([key, value]) => {
          const lowerKey = key.toLowerCase() as keyof GroupedAssetData
          let totalLabel = 'Implanted'

          const renderTotalCount = () => {
            if (key === 'BIOLOGIC') {
              return totalImplantableBiologicCount
            }
            if (key === 'OTHER') {
              return totalImplantableOtherCount
            }
            if (key === 'CONSUMABLE') {
              totalLabel = ''
              return totalConsumableCount
            }
          }

          if (key !== 'HARDWARE' && key !== 'EXPLANTED') {
            return (
              <>
                <TotalAssetCountPanel
                  key={key}
                  groupedAssetData={groupedAssetData}
                  procedureStatus={procedureStatus === 'SUBMITTED'}
                  surgeryId={surgery._id}
                  label={value}
                  assetTypeOption={lowerKey}
                  scanType={'nurseScans'}
                  totalCount={renderTotalCount() || 0}
                  totalLabel={totalLabel}
                />
              </>
            )
          } else {
            return null
          }
        })}

        {/* EXPLANTS */}
        {isNurseUser && groupedAssetData.explanted.length > 0 ? (
          <Box>
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                my: 1.75,
                pb: 1,
                borderBottom: '2px solid #eee',
              }}
            >
              <Typography variant="h2" sx={{ mr: 1 }}>
                Explanted
              </Typography>
              <Typography variant="h3" data-testid="explanted-total-count">
                {`Explanted Total: ${totalExplantedCount}`}
              </Typography>
            </Grid>
            <Box my={3.5}>
              {groupedAssetData.explanted.map((asset: AssetData) => {
                return (
                  <StoredAsset
                    key={asset._id}
                    isProcedureSubmitted={procedureStatus === 'SUBMITTED'}
                    surgeryId={surgery._id}
                    assetData={asset}
                    groupedAssetData={groupedAssetData}
                    isExplanted
                  />
                )
              })}
            </Box>
          </Box>
        ) : null}

        {/* POST-APPROVAL ACTIONS */}
        {!isGroupedAssetsEmpty && (
          <Box
            sx={{
              display: 'flex',
              my: '1rem',
            }}
          />
        )}

        <Box sx={{ mb: 2 }}>
          {isNurseUser && !isGroupedAssetsEmpty && (
            <>
              {procedureStatus === 'SUBMITTED' ? (
                <Button
                  variant="outlined"
                  fullWidth
                  to="/home"
                  component={Link}
                >
                  Return to Procedure List
                </Button>
              ) : (
                (procedureStatus === 'APPROVED' || !hasImplantableHardware) && (
                  <SubmitToEMR
                    surgery={surgery}
                    isGroupedAssetsEmpty={isGroupedAssetsEmpty}
                    dtmHardwareAssetData={dtmHardwareAssetData}
                  />
                )
              )}
            </>
          )}
        </Box>

        {(isGroupedAssetsEmpty ||
          (isRep &&
            !loadingRepCompanies &&
            groupedAssetData.hardware.nurseScans.filter(
              (scan) =>
                scan.scans.filter((anotherScan) =>
                  repCompanyNames?.includes(anotherScan.companyName)
                ).length > 0
            ).length === 0 &&
            groupedAssetData.hardware.approved.filter(
              (scan) =>
                scan.scans.filter((anotherScan) =>
                  repCompanyNames?.includes(anotherScan.companyName)
                ).length > 0
            ).length === 0 &&
            groupedAssetData.other.nurseScans.filter(
              (scan) =>
                scan.scans.filter((anotherScan) =>
                  repCompanyNames?.includes(anotherScan.companyName)
                ).length > 0
            ).length === 0)) && (
          <div className={styles.emptyAssetList}>
            <div className={styles.emptyText}>
              {procedureStatus !== 'SUBMITTED' ? (
                <>
                  <SentimentVeryDissatisfied fontSize="medium" sx={{ my: 1 }} />
                  <Typography variant="h3" sx={{ my: 1 }}>
                    Product list empty!
                  </Typography>
                  <Typography variant="h6" sx={{ my: 1 }}>
                    {isNurseUser
                      ? 'You haven’t added any products yet'
                      : 'No products have been added yet'}
                  </Typography>
                </>
              ) : (
                <Typography variant="h3" sx={{ my: 1 }}>
                  Case has been closed
                </Typography>
              )}
            </div>

            {renderCloseCase && (
              <div className={styles.closeButton}>
                {procedureStatus === 'SUBMITTED' ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    to="/home"
                    component={Link}
                  >
                    Return to Procedure List
                  </Button>
                ) : (
                  <SubmitToEMR
                    surgery={surgery}
                    isGroupedAssetsEmpty={isGroupedAssetsEmpty}
                    dtmHardwareAssetData={dtmHardwareAssetData}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Box>

      {/* REP QR INVITE MODAL */}
      <AddRepModal
        isOpen={isQrOpen}
        onClose={handleCloseModal}
        surgeryId={surgery._id}
      />

      <AlertDialog isOpen={showGenericAlertDialog} {...alertDialogDetails} />

      {/* Set procedure status mutation error */}
      <AlertDialog
        isOpen={setSurgeryStatusMutation.error !== undefined}
        description="An error was encountered while trying to change record status."
        positionAboveBottomNavigation
        primaryButtonText="Close"
        primaryButtonAction={() => {
          setSurgeryStatusMutation.reset()
        }}
        isPrimaryDisabled={false}
        isPrimaryLoading={false}
      />

      {/* Set procedure status mutation error */}
      <AlertDialog
        isOpen={
          setAssetStatusMutation.error !== undefined &&
          setAssetStatusSecondaryMutation.error !== undefined
        }
        description="An error was encountered while trying to change product status."
        positionAboveBottomNavigation
        primaryButtonText="Close"
        primaryButtonAction={() => {
          setAssetStatusMutation.reset()
          setAssetStatusSecondaryMutation.reset()
        }}
        isPrimaryDisabled={false}
        isPrimaryLoading={false}
      />

      {/* Set procedure status submitted mutation error */}
      <AlertDialog
        isOpen={setSurgeryStatusSubmittedMutation.error !== undefined}
        description="An error was encountered while trying to submit record approval."
        positionAboveBottomNavigation
        primaryButtonAction={handleSubmitRecord}
        secondaryButtonAction={setSurgeryStatusSubmittedMutation.reset}
        isPrimaryDisabled={false}
        isPrimaryLoading={setSurgeryStatusSubmittedMutation.loading}
      />

      {/* Demo Menu Modal   */}
      <Modal
        open={showDemoMenu}
        BackdropProps={{ sx: { backgroundColor: 'white' } }}
        onClose={() => setShowDemoMenu(false)}
      >
        <>
          <DemoMenu
            surgeryId={surgery._id}
            procedureStatus={surgery?.status?.name}
            handleSetProcedureStatus={handleSetProcedureStatus}
            isLoading={setSurgeryStatusMutation.loading}
            handleClose={() => setShowDemoMenu(false)}
          />
        </>
      </Modal>
    </ToastProvider>
  )
}

export default AssetList
