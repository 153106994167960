import { FC } from 'react'
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import styles from './RecordSender.module.css'
import RepsList from './RepsList/RepsList'
import { TRecordSenderProps } from './RecordSender.types'
import { useRecordSenderLogic } from './RecordSender.logic'

const RecordSender: FC<TRecordSenderProps> = (props) => {
  const { surgery } = props

  const logic = useRecordSenderLogic(props)

  return (
    <Box className={styles.root}>
      <Typography variant="h3">Share Implant Record</Typography>
      <Typography variant="body2" color="text.secondary" marginTop={0.5}>
        Find product representitives by selecting a company
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel id="company-select-label">Company</InputLabel>
        <Select
          label="Company"
          id="company-select"
          labelId="company-select-label"
          inputProps={{
            name: 'company',
            id: 'company',
          }}
          sx={{ background: 'white' }}
          value={logic.currentCompany?.name || ''}
          onChange={logic.handleSelectChange}
        >
          {logic.loading ? (
            <MenuItem>Loading Companies...</MenuItem>
          ) : (
            logic.repsByCompany?.map((company) => (
              <MenuItem key={company.bidCompanyId} value={company.name}>
                {company.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      {logic.currentCompany ? (
        <RepsList
          selectedCompany={logic.currentCompany}
          surgery={surgery}
          sentToSalesRep={logic.sentToSalesRep}
        />
      ) : null}
    </Box>
  )
}

export default RecordSender
