// Router
import { useNavigate } from 'react-router'

// Service
import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'

// Context
import { useReceiving } from './receiveContext'

export const useLogic = () => {
  const navigate = useNavigate()
  const { modal, setModal } = useReceiving()
  const { data, loading: isLoading } = useGetLocationsTree()
  const rawLocations = data?.getLocationsTree.hospital.locations
  const locations =
    rawLocations
      ?.filter((item) => item.parentLocationId)
      .map((item) => ({ name: item.name, id: item.id })) || []

  return {
    processModalState: modal,
    setModal,
    navigate,
    locations,
    rawLocations,
    isLoading,
  }
}
