import * as React from 'react'
import { Box, Typography, Modal, Button } from '@mui/material'
import { BetterIdAsset, BetterIdResult } from 'common/types'
import { MultiDeviceCountCard } from './MultiDeviceCountCard'
interface BatchSetCountModalProps {
  isOpen: boolean
  scan: BetterIdResult | BetterIdAsset
  onClose: () => void
  onSave: (count: number) => void
  onCountChange?: (count: number) => void
}

export function BatchSetCountModal({
  isOpen,
  scan,
  onClose,
  onSave,
  onCountChange,
}: BatchSetCountModalProps) {
  const [count, setCount] = React.useState(1)
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 768,
          width: '95%',
          maxHeight: '95%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Box>
          <Box
            mt={4}
            px={{
              xs: 2,
              sm: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h2"
              mb={1}
            >
              Set Used Count
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The product you captured has a device count of {scan?.deviceCount}
              . Please set the amount of individual items used
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              mt: 4,
              px: {
                xs: 2,
                sm: 4,
              },
              overflow: 'auto',
              maxHeight: 400,
            }}
          >
            <MultiDeviceCountCard
              scan={scan}
              count={count}
              onChange={(e) => {
                setCount(e)
                onCountChange?.(e)
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
          gap={1}
          sx={{
            backgroundColor: 'grey.100',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            p: 2,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => onSave(count)}
            disableElevation
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
