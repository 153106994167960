import { FC, useEffect } from 'react'
import { TImplantableOtherRecordSenderProps } from './ImplantableOtherRecordSender.types'
import { MainContainer } from './ImplantableOtherRecordSender.styles'
import { Typography } from '@mui/material'
import { useGetRepsQuery } from 'lib/apollo/hooks'
import Rep from './Rep'

const ImplantableOtherRecordSender: FC<TImplantableOtherRecordSenderProps> = (
  props
) => {
  const { bidCompanyId, manufacturerName, surgeryId, assetDetails } = props
  const { data, loading, error, refetch } = useGetRepsQuery({
    bidCompanyId: bidCompanyId,
  })
  const reps = data?.getReps?.data || []
  const isRepsData = !loading && !error && reps.length > 0

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <MainContainer $isRepDataContainer={isRepsData}>
      {loading && (
        <Typography variant="body2" color="text.secondary">
          Loading reps...
        </Typography>
      )}
      {!loading && error && (
        <Typography variant="body2" color="error" fontWeight="medium">
          Error loading reps for {manufacturerName}
        </Typography>
      )}
      {!loading && !error && reps && reps.length === 0 && (
        <Typography variant="body2" color="error" fontWeight="medium">
          No Reps found for {manufacturerName}
        </Typography>
      )}
      {isRepsData &&
        reps.map((rep, index) => {
          return (
            <Rep
              key={index}
              rep={rep}
              surgeryId={surgeryId}
              assetDetails={assetDetails}
            />
          )
        })}
    </MainContainer>
  )
}

export default ImplantableOtherRecordSender
