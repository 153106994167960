import React from 'react'
import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'
import ZimmerStandardClosureTopsTrayMap from '../ZimmerStandardClosureTopsTrayMap/ZimmerStandardClosureTopsTrayMap'
import { TrayMapSelectorProps } from './TrayMapSelector.types'
import GrayScale from '../GrayScale/GrayScale'

const TrayMapSelector: React.FC<TrayMapSelectorProps> = ({
  trayType,
  mapContent,
  isInOR = false,
  loadWithUDIs = true,
  onClickActions,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  handlePlateUsage,
  getPlateCount,
  setSelectedPlate,
  setModalOpen,
  setSelectedScrew,
  hideButton = false,
}) => {
  switch (trayType) {
    case 'zimmer curved rods':
      return (
        <GrayScale
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
          hideButton={hideButton}
          trayType="zimmer curved rods"
          setSelectedScrew={setSelectedScrew}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          getPlateCount={getPlateCount}
          mapContent={mapContent}
        />
      )
    case 'stryker screw caddy':
      return (
        <GrayScale
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
          hideButton={hideButton}
          trayType="stryker screw caddy"
          setSelectedScrew={setSelectedScrew}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          getPlateCount={getPlateCount}
          mapContent={mapContent}
        />
      )
    case 'stryker upper face':
      return (
        <GrayScale
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
          hideButton={hideButton}
          trayType="stryker upper face"
          setSelectedScrew={setSelectedScrew}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          getPlateCount={getPlateCount}
          mapContent={mapContent}
        />
      )
    case 'stryker middle face':
      return (
        <GrayScale
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
          hideButton={hideButton}
          trayType="stryker middle face"
          setSelectedScrew={setSelectedScrew}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          getPlateCount={getPlateCount}
        />
      )
    case 'zimmer adjustable transverse connector':
      return (
        <GrayScale
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
          hideButton={hideButton}
          trayType="zimmer adjustable transverse connector"
          setSelectedScrew={setSelectedScrew}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          getPlateCount={getPlateCount}
          mapContent={mapContent}
        />
      )
    case 'zimmer standard closure tops':
      return (
        <ZimmerStandardClosureTopsTrayMap
          isInOR={isInOR}
          setSelectedPlate={setSelectedPlate}
          setModalOpen={setModalOpen}
        />
      )
    default:
      return (
        <TrayMap
          trayType={trayType}
          mapContent={mapContent}
          loadWithUDIs={loadWithUDIs}
          onClickActions={onClickActions}
          isScrewEnabled={isScrewEnabled}
          isScrewImplanted={isScrewImplanted}
          isScrewWasted={isScrewWasted}
          handlePlateUsage={handlePlateUsage}
          getPlateCount={getPlateCount}
        />
      )
  }
}

export default TrayMapSelector
