import { SelectChangeEvent, Typography } from '@mui/material'
import dayjs from 'lib/dayjs'
import { useCallback, useEffect } from 'react'
import { useUser } from 'app/User'
import { useAddTrayContext } from 'views/TrayManagementLayout/AddTray/AddTray.context'
import { useCreateTrayItemMutation } from 'lib/apollo/hooks'
import { SubTray } from 'views/TrayManagementLayout/AddTray/SubTrays/SubTrays.types'

const useAddTrayDetailsLogic = () => {
  const { isRep, isSPD } = useUser()
  const {
    trayDescription,
    setTrayDescription,
    selectedLocationId,
    setSelectedLocationId,
    trayVendor,
    setTrayVendor,
    trayWeight,
    setTrayWeight,
    subTrays,
    setSelectedTrayCategory,
  } = useAddTrayContext()
  const { loading: isCreatingTrayItem } = useCreateTrayItemMutation()

  useEffect(() => {
    if (isRep) {
      setSelectedTrayCategory('consigned')
    }
  }, [isRep, setSelectedTrayCategory])

  const implantTrays: SubTray[] = subTrays.filter(
    (subTray) => subTray.trayType !== 'instrument'
  )
  const hasImplantTrays: boolean = implantTrays.length > 0
  const firstImplantTray: SubTray | undefined = hasImplantTrays
    ? implantTrays[0]
    : undefined

  /**
   * Handles changes to the tray description.
   * @param e - The event object for the input change.
   */
  const handleTrayDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrayDescription(e.target.value)
  }

  /**
   * Handles changes to the selected location.
   * @param e - The event object for the select change.
   */
  const handleSelectedLocationChange = (e: SelectChangeEvent<number>) => {
    setSelectedLocationId(e.target.value as number)
  }

  /**
   * Handles changes to the tray vendor.
   * @param e - The event object for the select change.
   */
  const handleTrayVendorChange = (e: SelectChangeEvent<string>) => {
    setTrayVendor(e.target.value)
  }

  /**
   * Handles changes to the tray weight.
   * @param e - The event object for the input change.
   */
  const handleTrayWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = !Number.isNaN(e.target.valueAsNumber)
      ? e.target.valueAsNumber
      : undefined
    if (typeof value === 'number' && value >= 0) {
      setTrayWeight(value)
    }
  }

  /**
   * Renders the drop-off date and time.
   */
  const renderDropOffDateTime = useCallback(() => {
    if (isRep) {
      return (
        <Typography className="drop-off_date">
          Drop off time: <strong>{dayjs(new Date()).format('LLLL')}</strong>
        </Typography>
      )
    }
    return null
  }, [isRep])

  const getTrayCategoryOptions = () => {
    if (isSPD) return ['facility', 'consigned']
    return isRep ? ['consigned', 'loaner'] : ['facility']
  }

  return {
    trayDescription,
    handleTrayDescriptionChange,
    selectedLocationId,
    handleSelectedLocationChange,
    trayVendor,
    handleTrayVendorChange,
    trayWeight,
    handleTrayWeightChange,
    renderDropOffDateTime,
    isCreatingTrayItem,
    hasImplantTrays,
    firstImplantTray,
    getTrayCategoryOptions,
  }
}

export default useAddTrayDetailsLogic
