import { useEffect, useState } from 'react'

import {
  useAssignTrayAsNurseFromOR,
  useSurgeryItemsQuery,
} from 'lib/apollo/hooks'
import { useAssignedDigitalTrays } from './AssignedDigitalTrays.context'

import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useParams } from 'react-router-dom'
import { ItemResponse } from 'common/types'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { v4 as uuid } from 'uuid'
import { AssignedDigitalTraysLogicProps } from './AssignedDigitalTrays.types'

const useAssignedDigitalTraysLogic = ({
  surgery,
}: AssignedDigitalTraysLogicProps) => {
  const { surgeryId } = useParams()
  const { assignTrayAsNurseFromOR } = useAssignTrayAsNurseFromOR()
  const { trays, setTrays } = useAssignedDigitalTrays()
  const { loading, error, surgeryItems } = useSurgeryItemsQuery(
    surgeryId as string
  )
  const [selectTrayOpen, setSelectTrayOpen] = useState(false)
  const [isAssigningTray, setIsAssigningTray] = useState(false)
  const [modalKey, setModalKey] = useState(uuid())
  const filteredTrays = trays.filter((surgeryTray) => {
    const trayProductDetails = extractTrayJSONData(surgeryTray)

    // If tray is consigned or loaned, it needs to be approved before showing it to the user
    if (trayProductDetails?.trayCategory !== 'facility') {
      return (
        trayProductDetails?.status !== 'rejected' &&
        trayProductDetails?.status !== undefined
      )
    }

    return true
  })

  useEffect(() => {
    if (!loading && !error) {
      setTrays(surgeryItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, surgeryItems])

  const handleAssignDigitalTray = async (item: ItemResponse) => {
    if (!surgeryId) {
      return
    }

    try {
      setIsAssigningTray(true)
      const previousProductDetails = extractTrayJSONData(item)

      if (!previousProductDetails) {
        throw new Error('Failed to get the tray JSON data.')
      }

      const updatedProductDetails: TrayItemProductDetails = {
        ...previousProductDetails,
        status: 'assigned',
        surgeryId,
        surgeryDetails: surgery,
      }

      const input: ItemResponse = {
        ...item,
        productDetails: JSON.stringify(updatedProductDetails),
      }

      const res = await assignTrayAsNurseFromOR(input)

      if (res.assignTrayAsNurseFromOR) {
        setSelectTrayOpen(false)
        setTrays((prevTrays) => [...prevTrays, item])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsAssigningTray(false)
      setModalKey(uuid())
    }
  }

  return {
    error,
    loading,
    modalKey,
    surgeryId,
    filteredTrays,
    selectTrayOpen,
    isAssigningTray,
    setSelectTrayOpen,
    handleAssignDigitalTray,
  }
}

export default useAssignedDigitalTraysLogic
