import Box, { BoxProps } from '@mui/material/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  DatePickerProps,
  DatePicker as MUIDatePicker,
} from '@mui/x-date-pickers/DatePicker'
import dayjs from 'lib/dayjs'
import { FC, useState, useMemo, useEffect } from 'react'

interface IDatePicker extends DatePickerProps<dayjs.Dayjs> {
  date?: Date
  label: string
  disabled?: boolean
  renderDatePicker?: boolean
  onDateSelection?: (date: Date) => void
  required?: boolean
  errorMessage?: string
}

const CustomDatePicker: FC<IDatePicker> = ({
  date,
  label,
  disabled,
  renderDatePicker = true,
  onDateSelection,
  required = false,
  minDate,
  errorMessage,
  ...props
}) => {
  const [isOpen, toggleIsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date)
  const [isFilled, setIsFilled] = useState(false)

  useEffect(() => {
    setIsFilled(selectedDate !== undefined)
  }, [selectedDate])

  const handleDateTextFieldClick = () => {
    toggleIsOpen(true)
  }

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setSelectedDate(date?.toDate() as Date)
    onDateSelection && onDateSelection(date?.toDate() as Date)
    toggleIsOpen(false)
  }

  const handleDialogClose = () => {
    toggleIsOpen(false)
  }

  useMemo(() => {
    setSelectedDate(date)
  }, [date])

  return (
    <Box
      data-testid="custom-date-picker"
      aria-label="date-picker"
      display="flex"
      justifyContent={'flex-start'}
      alignItems={'center'}
      flexDirection={'column'}
      {...(props as BoxProps)}
    >
      <TextField
        label={label}
        type="text"
        disabled={disabled}
        value={selectedDate ? dayjs(selectedDate).format('MM-DD-YYYY') : ''}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          cursor: !renderDatePicker ? 'default' : 'pointer',
          float: 'inherit',
          '& .MuiOutlinedInput-root, & .MuiInputBase-root, & .MuiInputBase-formControl':
            {
              pointerEvents: 'none',
            },
        }}
        onClick={handleDateTextFieldClick}
      />
      {renderDatePicker && (
        <Dialog open={isOpen} onClose={handleDialogClose}>
          <div>
            <MUIDatePicker
              value={dayjs(selectedDate)}
              open
              onChange={(e: any) => handleDateChange(e)}
              onClose={handleDialogClose}
              minDate={minDate}
              label={label}
              sx={{ marginTop: '5px' }}
            />
          </div>
        </Dialog>
      )}
      {required && !isFilled && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          Required
        </Typography>
      )}
      {errorMessage && (
        <Typography fontSize={12} color="red" ml={0.5}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

export default CustomDatePicker
