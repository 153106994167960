import useTrayManagementFiltersLogic from 'components/organisms/TrayManagementFilters/TrayManagementFilters.logic'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'

import { SubFilter } from 'components/organisms/TrayManagementFilters/TrayManagementFilters.types'
import { TrayManagementFilterProps } from './TrayManagementFilter.types'

const useTrayManagementFilterLogic = ({
  filter,
}: TrayManagementFilterProps) => {
  const { selectedFilters, selectedCategory } = useTrayConfigurationContext()
  const { handleFilterClick, handleSubFilterClick } =
    useTrayManagementFiltersLogic()

  const isOpen = selectedCategory === filter.filterValue

  /**
   * Check if a subfilter is active
   * @param subFilterValue - The value of the subfilter
   * @returns boolean indicating if the subfilter is active
   */
  const isSubfilterActive = (
    subFilterValue: SubFilter['filterValue']
  ): boolean => {
    return selectedFilters.has(subFilterValue)
  }

  /**
   * Handle filter click event
   */
  const handleClick = (): void => {
    handleFilterClick(filter)
  }

  return {
    isOpen,
    handleClick,
    isSubfilterActive,
    handleSubFilterClick,
  }
}

export default useTrayManagementFilterLogic
