import {
  isAndroid,
  isIOS,
  isIPad13,
  isMobile,
  isTablet,
} from 'react-device-detect'

const getIsMobileOrTablet = () => {
  return isMobile || isTablet || isIPad13 || isAndroid || isIOS
}

export default getIsMobileOrTablet
